var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    [
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "FormField",
            { attrs: { label: "Add social media icons" } },
            [
              _c("v-select", {
                attrs: {
                  value: _vm.selectedSocialNetworks,
                  items: _vm.options,
                  "item-text": "label",
                  "item-value": "value",
                  multiple: "",
                  placeholder: "Select from the list",
                  "menu-props": "offsetY",
                  "append-icon": "keyboard_arrow_down",
                },
                on: { input: _vm.onSocialMediaSelect },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (data) {
                      return [
                        _c(
                          "DraggableChip",
                          {
                            attrs: { id: data.index, value: _vm.value },
                            on: { input: (val) => _vm.$emit("input", val) },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.label) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                {
                  staticClass: "side-form__item tile-constructor__hint",
                  staticStyle: { "margin-top": "0" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://help.spreadsimple.com/en/article/adding-social-media-icons-kzg8iq/",
                      },
                    },
                    [_vm._v("Help: How to add social media icons")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.value, function ({ value: url, key }, i) {
        return _c(
          "div",
          { key: i, staticClass: "settings-form__settings-wrap" },
          [
            _c(
              "FormField",
              {
                attrs: { label: `Your ${_vm.getSocialMediaTitle(key)} link:` },
              },
              [
                _c("input", {
                  staticClass: "input",
                  attrs: { type: "text" },
                  domProps: { value: url },
                  on: {
                    input: function ($event) {
                      return _vm.onLinkInput($event, key)
                    },
                  },
                }),
                _vm.getUrlValidationStatus(i)
                  ? _c("p", { staticClass: "form-field__error" }, [
                      _vm._v(
                        "\n        Field is required and must be a URL\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }