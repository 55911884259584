<template>
  <div>
    <div class="content-form__switch-field">
      <div class="content-form__switcher-wrap">
        <div class="content-form__switcher-label">
          Related items
          <UiHelper
            class="va-bottom ml-2"
            popoverFeature="related-items"
            showCloseBtn
            :videoData="{
              src: '/video/helper-popup/related-items.mp4',
              label: 'Learn how to set up and configure related items',
              btns: [
                {
                  label: 'Learn more',
                  to: 'https://help.spreadsimple.com/en/article/related-items-xqg93j/',
                  externalResource: true,
                  color: 'secondary-blue',
                  event: {
                    type: 'goToHelp'
                  }
                },
                {
                  label: 'Watch video',
                  to: { name: 'help-video', query: { show: 'related-items' }},
                  event: {
                    type: 'goToVideo'
                  }
                }
              ]
            }"
          />
        </div>
        <label
          :class="{ 'selected': relatedItemsState.enabled }"
          class="switcher"
        >
          <input v-model="relatedItemsState.enabled" class="switcher__input" type="checkbox">
          <span class="switcher__indicator"></span>
        </label>
      </div>
    </div>
    <div v-if="relatedItemsState.enabled" class="content-form__highlighted">
      <FormField label="Related items title" class="tile-constructor__field side-form__item aside-input">
        <v-text-field
          v-model.trim="relatedItemsState.sectionTitle"
        />
        <div class="tile-constructor__hint" style=" margin-bottom: 10px">
          <a target="_blank" href="https://help.spreadsimple.com/en/article/related-items-xqg93j">Help: How to manage related items</a>
        </div>
      </FormField>
      <div class="tile-constructor__field">
        <CustomOptionsPicker
          v-model="cardStyle"
          label="Select card style"
          :options="styleOptions"
        />
      </div>
      <div v-if="cardStyle !== 'table'" class="tile-constructor__field">
        <CustomOptionsPicker
          v-model="relatedItemsState.itemsSize"
          label="Select card size"
          :options="getCardSizeOptions(relatedItemsState.itemsStyle)"
        />
      </div>
      <div v-if="cardStyle === 'table'" class="content-form__switch-field">
        <div class="content-form__switcher-wrap">
          <div class="content-form__switcher-label">Scrollable table rows</div>
          <label
            :class="{ 'selected': relatedItemsState.isTableScrollable }"
            class="switcher"
          >
            <input v-model="relatedItemsState.isTableScrollable" class="switcher__input" type="checkbox">
            <span class="switcher__indicator"></span>
          </label>
        </div>
      </div>
      <FormField label="Max items to display" class="side-form__item aside-input">
        <v-text-field
          v-model="relatedItemsState.maxCount"
          type="number"
          :min="1"
          :max="100"
          required
        >
        </v-text-field>
      </FormField>
      <FormField
        label="Related items selection method"
        class="side-form__item aside-input">
        <v-select
          :value="relatedItemsState.selectionMethod"
          :items="selectionMethodOptions"
          attach
          item-text="label"
          item-value="value"
          placeholder="Choose a selection method"
          menu-props="offsetY"
          append-icon="keyboard_arrow_down"
          @input="val => relatedItemsState.selectionMethod = val"
        >
        </v-select>
      </FormField>
      <div
        v-if="relatedItemsState.selectionMethod === 'category'"
        class="tile-constructor__field side-form__item aside-select"
      >
        <h6 class="aside-field-label">Specify category column</h6>
        <v-select
          v-model="relatedItemsState.categoryColumn.id"
          :items="cols"
          item-text="label"
          item-value="id"
          attach
          placeholder="Choose a category column"
          menu-props="offsetY"
          append-icon="keyboard_arrow_down"
        />
      </div>
      <div
        v-if="relatedItemsState.selectionMethod === 'sku'"
        class="tile-constructor__field side-form__item aside-select"
      >
        <h6 class="aside-field-label">Specify related SKU column</h6>
        <v-select
          v-model="relatedItemsState.skuColumn.id"
          :items="cols"
          item-text="label"
          item-value="id"
          attach
          placeholder="related SKU column"
          menu-props="offsetY"
          append-icon="keyboard_arrow_down"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/form-field'
import UiHelper from '@/components/ui-helper'
import CustomOptionsPicker from '@/components/custom-options-picker'

export default {
  name: 'RelatedItemsConstructor',
  components: { CustomOptionsPicker, FormField, UiHelper },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    cols: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      relatedItemsState: null,
      styleOptions: [
        { value: 'tile', label: 'Tile view', icon: 'icon-tiles-view2' },
        { value: 'list', label: 'List view', icon: 'icon-list-view2' },
        { value: 'horizontal', label: 'Horizontal view', icon: 'icon-horizontal-view' },
        { value: 'table', label: 'Table view', icon: 'icon-table-view-style' }
      ],
      selectionMethodOptions: [
        { value: 'random', label: 'Random Items' },
        { value: 'category', label: 'By Category' },
        { value: 'sku', label: 'Selected SKU' }
      ]
    }
  },
  computed: {
    cardStyle: {
      get () {
        return this.relatedItemsState.itemsStyle
      },
      set (val) {
        const sizes = this.getCardSizeOptions(val)
        if (!sizes.map(s => s.value).includes(this.relatedItemsState.itemsSize)) {
          this.relatedItemsState.itemsSize = sizes[0].value
        }
        this.relatedItemsState.itemsStyle = val
      }
    }
  },
  watch: {
    relatedItemsState: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getCardSizeOptions (style) {
      if (style === 'tile') {
        return [{ value: 'small', label: 'Small', icon: 'icon-card-small' }, { value: 'large', label: 'Large', icon: 'icon-card-large' }]
      } else if (style === 'list') {
        return [{ value: 'small', label: 'Small', icon: 'icon-list-view-small' }, { value: 'large', label: 'Large', icon: 'icon-list-view2' }]
      } else {
        return [{ value: 'small', label: 'Small', icon: 'icon-horizontal-view' }]
      }
    }
  },
  created () {
    this.relatedItemsState = JSON.parse(JSON.stringify(this.value))
  }
}
</script>
