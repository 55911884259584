import { get } from '@/modules/utils/'
import planManager from '@devstark/spreadsimple-plan-manager'
const dealsConfig = planManager.deals
export default {
  currentLanguage (state) {
    return get(state.userData, 'preferredLanguage', 'en')
  },
  startWebsocketConnection (state) {
    if (state.workspaces) {
      return state.workspaces.length > 1 || state.workspaces[0].users.length > 1
    }
    return false
  },
  currentWorkspace (state) {
    return state.workspaces ? state.workspaces.find(ws => ws.id === state.currentWorkspaceId) : null
  },
  workspaceAvailableLicenses (state, getters) {
    if (!getters.currentWorkspace) return null
    const paidLicenses = Object.entries(getters.currentWorkspace.ownerLicenses.paidLicenses).reduce((res, e) => {
      res[e[0]] = e[1].available
      return res
    }, {})
    const totalPaidLicenses = Object.values(getters.currentWorkspace.ownerLicenses.paidLicenses).reduce((res, item) => {
      res = res + item.total
      return res
    }, 0)
    return Object.assign({
      free: getters.currentWorkspace.ownerLicenses.free.total - getters.currentWorkspace.ownerLicenses.free.applied + totalPaidLicenses
    }, paidLicenses)
  },
  licenseTypeToApplyOnCreate (state, getters) {
    if (!getters.workspaceAvailableLicenses) return null
    const order = [
      'bulkLarge',
      'bulkMega',
      'bulkSmall',
      'business',
      'starter',
      'connect',
      'appsumo',
      'pro',
      'free'
    ]
    return order.find(i => {
      return getters.workspaceAvailableLicenses[i] && getters.workspaceAvailableLicenses[i] > 0
    })
  },
  userActiveDealsByType (state) {
    if (!state.userData) return null
    return state.userData.dealOptions.dealBasket.reduce((res, deal) => {
      const type = dealsConfig.find(p => p.name === deal.name).type
      const dealObject = {
        ...deal,
        label: dealsConfig.find(p => p.name === deal.name).label,
        period: dealsConfig.find(p => p.name === deal.name).period,
        type: dealsConfig.find(p => p.name === deal.name).type,
        promo: deal.name.includes('promo'),
        isPaddleSubscription: !!deal.updateBillingUrl && deal.dealStatus !== 'CANCELLED'
      }
      if (!res[type]) {
        res[type] = [dealObject]
        return res
      }
      res[type].push(dealObject)
      return res
    }, {})
  },
  amountOfAvailableActiveSpreadViews (state, getters) {
    if (getters.currentWorkspace !== null) {
      const paidLicenses = Object.values(getters.currentWorkspace.ownerLicenses.paidLicenses)
      const totalPaidLicenses = paidLicenses.reduce((res, item) => {
        res = res + item.total
        return res
      }, 0)
      return getters.currentWorkspace.ownerLicenses.free.total + totalPaidLicenses
    } else {
      return 3 // the default value of available websites for the free plan
    }
  },
  legacyTrialSubscription (state) {
    if (!state.userData) return null
    return state.userData.deal === 'trial'
  },
  canSubscribeToPaidPlan (state, getters) {
    if (!getters.userActiveDealsByType) return null
    return !Object.values(getters.userActiveDealsByType).some(i => {
      return i.some(d => d.isPaddleSubscription)
    })
  },

  // ONBORDING WIZARD

  onboardingWizardUncompletedSteps (state) {
    if (state.userData) {
      if (state.userData.wizards && state.userData.wizards.length) {
        const wizard = state.userData.wizards.find(w => w.name === 'onboarding')
        return wizard.steps.filter(s => !s.completed)
      } else {
        return []
      }
    }
  },

  // MANAGE PAGE VALIDATION

  spreadViewerOptionsInvalid (state) {
    const options = Object.values(state.spreadViewerOptionsValidation)
    return options.some(option => option)
  },

  // SHOW/HIDE FREE LIMITS BANNER
  showFreeLimitsBanner (state) {
    if (!state.userData) return false
    const pricingChangeDate = new Date('2024-06-27').getTime()
    const createdAt = new Date(state.userData.createdAt).getTime()
    const onlyFreeSub = state.userData.dealOptions.dealBasket.length === 1 && state.userData.dealOptions.dealBasket[0].name.includes('free')
    const isOldFree = pricingChangeDate > createdAt && onlyFreeSub
    return isOldFree || false
  }
}
