var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.openDropdownMenu.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-dropdown",
        {
          ref: "spreadviewDropdown",
          staticClass: "spreadview__dropdown for-overlay",
          attrs: { placement: "bottom-start", trigger: "click" },
          on: { "visible-change": _vm.showOverlay },
        },
        [
          _vm.item.isArchived
            ? _c(
                "el-dropdown-menu",
                {
                  staticClass: "spreadview__dropdown-menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _vm.item.options.spreadView.isPublished
                    ? _c("el-dropdown-item", [
                        _c(
                          "a",
                          {
                            staticClass: "spreadview__dropdown-item",
                            attrs: {
                              href: _vm.item.spreadsheetUrl,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("global.editSheet")))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.item.options.spreadView.isPublished
                    ? _c("el-dropdown-item", [
                        _c(
                          "a",
                          {
                            staticClass: "spreadview__dropdown-item",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("open-link-modal")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("global.viewLink")))]
                        ),
                      ])
                    : _vm._e(),
                  _c("el-dropdown-item", [
                    _vm.item.options.spreadView.isPublished
                      ? _c(
                          "button",
                          {
                            staticClass: "spreadview__dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.$emit(
                                  "change-view-published-status",
                                  { id: _vm.item.id, published: false }
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s("Unpublish"))]
                        )
                      : _vm._e(),
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "button",
                      {
                        staticClass: "spreadview__dropdown-item",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("change-view-archived-status", {
                              id: _vm.item.id,
                              archived: false,
                            })
                          },
                        },
                      },
                      [_vm._v(_vm._s("Unarchive"))]
                    ),
                  ]),
                  _vm.canDelete
                    ? _c("el-dropdown-item", { staticClass: "is-danger" }, [
                        _c(
                          "button",
                          {
                            staticClass: "spreadview__dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("open-delete-modal")
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "el-dropdown-menu",
                {
                  staticClass: "spreadview__dropdown-menu",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "a",
                      {
                        staticClass: "spreadview__dropdown-item",
                        attrs: {
                          href: _vm.item.spreadsheetUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("global.editSheet")))]
                    ),
                  ]),
                  _vm.item.options.spreadView.isPublished
                    ? _c("el-dropdown-item", [
                        _c(
                          "a",
                          {
                            staticClass: "spreadview__dropdown-item",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("open-link-modal")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("global.viewLink")))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.item.options.spreadView.isPublished
                    ? _c("el-dropdown-item", [
                        _c(
                          "a",
                          {
                            staticClass: "spreadview__dropdown-item",
                            attrs: { href: "javascript:;" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("duplicate")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("global.duplicate")))]
                        ),
                      ])
                    : _vm._e(),
                  _c("el-dropdown-item", [
                    _c(
                      "button",
                      {
                        staticClass: "spreadview__dropdown-item",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("change-view-published-status", {
                              id: _vm.item.id,
                              published:
                                !_vm.item.options.spreadView.isPublished,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.item.options.spreadView.isPublished
                              ? "Unpublish"
                              : "Publish"
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm.canDelete
                    ? _c("el-dropdown-item", { staticClass: "is-danger" }, [
                        _c(
                          "button",
                          {
                            staticClass: "spreadview__dropdown-item",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("open-delete-modal")
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
          _c("button", {
            staticClass: "spreadview__dropdown-trigger icon-dots",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }