var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-cta" }, [
    !_vm.canApplyTrial
      ? _c(
          "div",
          { staticClass: "page-cta__container" },
          [
            _c("h2", { staticClass: "page-cta__title" }, [
              _vm._v("Looks like you already have some pro licenses!"),
            ]),
            _c("p", [
              _vm._v(
                "Or your trial hasn't finished yet. Either way, you already have access to our Pro feautures, just make sure that your website is Pro in your workspace."
              ),
            ]),
            _c("p", [
              _vm._v(
                "Need more licenses? You can always get some in your Billing Settings."
              ),
            ]),
            _c(
              "UiBtn",
              {
                staticClass: "page-cta__btn",
                attrs: { uppercase: "", wide: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "account-billing" })
                  },
                },
              },
              [_vm._v("Go to Billing Settings")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.success
      ? _c(
          "div",
          { staticClass: "page-cta__container" },
          [
            _c("h2", { staticClass: "page-cta__title" }, [
              _vm._v("🎉 Yay! You are on the fast trial now!"),
            ]),
            _vm._m(0),
            _c("p", [
              _vm._v(
                "During this period you have three Pro licenses. Upgrade your websites and test the Pro features."
              ),
            ]),
            _vm._m(1),
            _c(
              "UiBtn",
              {
                staticClass: "page-cta__btn",
                attrs: { uppercase: "", wide: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "spread-views" })
                  },
                },
              },
              [_vm._v("Start testing")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.failure
      ? _c(
          "div",
          { staticClass: "page-cta__container" },
          [
            _c("h2", { staticClass: "page-cta__title" }, [
              _vm._v("Oops, your fast trial is not available now"),
            ]),
            _vm._m(2),
            _c("p", [
              _vm._v(
                "Either way, you can start a fast trial once a month, so come back later"
              ),
            ]),
            _c(
              "UiBtn",
              {
                staticClass: "page-cta__btn",
                attrs: { uppercase: "", wide: "" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "spread-views" })
                  },
                },
              },
              [_vm._v("OK")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Your trial will end "),
      _c("b", [_vm._v("in 3 days")]),
      _vm._v(". "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("You can also check the list of our recent updates "),
      _c(
        "a",
        {
          attrs: {
            href: "https://features.spreadsimple.com/spreadsimple/updates",
          },
        },
        [_vm._v("here")]
      ),
      _vm._v("."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Looks like it’s been "),
      _c("b", [_vm._v("less than 30 days")]),
      _vm._v(
        " since your last trial ended or you deleted this account just recently. "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }