<template>
  <div class="page-video">
    <div class="page-video__container">
      <video
        autoplay
        loop
        muted
        controls
        class="page-video__video"
      >
        <source :src="`/video/helper-popup/${$route.query.show}.mp4`" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageVideo'
}
</script>
