var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fs-editor" }, [
    _c("div", { staticClass: "fs-editor__head" }, [
      _vm._m(0),
      _c("div", { staticClass: "fs-editor__head-right-side" }, [
        _c("h6", { staticClass: "fs-editor__head-title text-reset" }, [
          _vm._v("Preview"),
        ]),
        _c("div", { staticClass: "fs-editor__head-close-btn-wrap" }, [
          _c("button", {
            staticClass: "fs-editor__head-close-btn icon-close2 button-reset",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("onclose")
              },
            },
          }),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "fs-editor__body sv-content-block" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fs-editor__head-left-side" }, [
      _c("h6", { staticClass: "fs-editor__head-title text-reset" }, [
        _vm._v("Markdown"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }