<template>
  <form
    class="settings-form side-form"
    @submit.prevent="submitForm"
  >

    <p v-if="validationStatus !== ''" class="aside-message side-form__item">
      {{ validationStatus }}
    </p>
    <CollapsableBlock
      :expanded="accordionsState.general"
      class="side-form__section"
      title="General"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.general = !accordionsState.general"
    >
      <div class="settings-form__settings-wrap">
        <div class="content-form__switch-field">
          <FormField label="Website language">
            <v-select
              v-model="options.locale"
              :items="locales"
              item-text="label"
              item-value="value"
              attach
              placeholder="Choose language"
              menu-props="offsetY"
              append-icon="keyboard_arrow_down"
              >
            </v-select>
          </FormField>
        </div>
      </div>
    </CollapsableBlock>

    <CollapsableBlock
    :locked="!isAllowedFeature('updateDefaultDomain') || !isAllowedFeature('updateCustomDomain')"
      :expanded="accordionsState.domain"
      class="side-form__section"
      title="DOMAIN SETTINGS"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.domain = !accordionsState.domain"
    >
      <DomainSettingsForm v-model="options.redirectToCustomDomain"/>
    </CollapsableBlock>

    <CollapsableBlock
      :locked="!isAllowedFeature('widget')"
      :expanded="accordionsState.widget"
      class="side-form__section"
      title="WIDGET SETTINGS"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.widget = !accordionsState.widget"
    >
      <div class="settings-form__settings-wrap">
      <ProOptionWrapper :featureName="'widget'">
          <div class="uploader__btn" @click="widgetBuilderModal = true">
            <transition name="fade" mode="out-in">
              <div key="btn">
                Open Widget Builder
              </div>
            </transition>
          </div>
      </ProOptionWrapper>
      </div>
      <div class="settings-form__settings-wrap">
        <ProOptionWrapper :featureName="'widget'">
          <div class="content-form__switch-field">
            <div class="content-form__switcher-wrap">
              <div class="content-form__switcher-label">Allow only widget mode</div>
              <label
                :class="{ 'selected':  options.widget.onlyWidgetMode}"
                class="switcher"
              >
                <input
                  v-model="options.widget.onlyWidgetMode"
                  class="switcher__input"
                  type="checkbox"
                  @change="$trackFeature('settings', 'onlyWidgetMode', options.widget.onlyWidgetMode)"
                >
                <span class="switcher__indicator"></span>
              </label>
            </div>
          </div>
          <div class="content-form__switch-field">
            <div class="content-form__switcher-wrap">
              <div class="content-form__switcher-label">Disallow widget mode</div>
              <label
                :class="{ 'selected':  options.widget.widgetModeDisallowed}"
                class="switcher"
              >
                <input
                  v-model="options.widget.widgetModeDisallowed"
                  class="switcher__input"
                  type="checkbox"
                  @change="$trackFeature('settings', 'widgetModeDisallowed', options.widget.widgetModeDisallowed)"
                >
                <span class="switcher__indicator"></span>
              </label>
            </div>
          </div>
        </ProOptionWrapper>
      </div>
      <div class="tile-constructor__hint" style="margin-bottom: 0px; margin-top: -20px;">
        <a target="_blank" href="https://help.spreadsimple.com/en/article/embedding-spreadsimple-as-a-widget-into-other-platforms-xorqqu/">Help: Embedding as a widget</a>
      </div>
    </CollapsableBlock>

    <CollapsableBlock
      :locked="!isAllowedFeature('scripts')"
      :expanded="accordionsState.scripts"
      class="side-form__section"
      title="Inject custom code"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.scripts = !accordionsState.scripts"
    >
      <p class="page-form__hint">
        You can add valid HTML tags, styles or scripts. This code will be injected to all pages.
      </p>
      <p class="page-form__hint">
        <br>
        Surround the CSS with <code v-pre>&lt;style&gt;&lt;/style&gt;</code> tags
        and the JavaScript with <code v-pre>&lt;script&gt;&lt;/script&gt;</code> tags.
      </p>
      <br>
      <div class="settings-form__settings-wrap">
        <FormField label="<head> section">
          <ProOptionWrapper :featureName="'scripts'">
            <div class="uploader__btn" @click="$refs.scriptInHeadModal.open()">
              <transition name="fade" mode="out-in">
                <div key="btn">
                  Open Editor
                </div>
              </transition>
            </div>
          </ProOptionWrapper>
        </FormField>
      </div>
      <div class="settings-form__settings-wrap">
        <FormField label="<body> – start">
          <ProOptionWrapper :featureName="'scripts'">
            <div class="uploader__btn" @click="$refs.scriptInBodyStartModal.open()">
              <transition name="fade" mode="out-in">
                <div key="btn">
                  Open Editor
                </div>
              </transition>
            </div>
          </ProOptionWrapper>
        </FormField>
      </div>
      <div class="settings-form__settings-wrap">
        <FormField label="<body> – end">
          <ProOptionWrapper :featureName="'scripts'">
            <div class="uploader__btn" @click="$refs.scriptInBodyFinishModal.open()">
              <transition name="fade" mode="out-in">
                <div key="btn">
                  Open Editor
                </div>
              </transition>
            </div>
          </ProOptionWrapper>
        </FormField>
      </div>
    </CollapsableBlock>

    <CollapsableBlock
      :locked="!isAllowedFeature('seo')"
      :expanded="accordionsState.seo"
      class="side-form__section"
      title="SEO"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.seo = !accordionsState.seo"
    >
      <div class="page-form__uploaders">
        <div class="page-form__uploader">
          <div class="page-form__uploader-label">Favicon</div>
          <div class="page-form__hint">Recommended size 32x32 pixels, .png or .ico image format</div>
          <ProOptionWrapper :featureName="'seo'">
            <UploadImage
              v-model="options.seo.favIcon"
              buttonText="Upload favicon"
              :unsplashEnabled="false"
            />
          </ProOptionWrapper>
        </div>
        <div class="page-form__uploader">
          <div class="page-form__uploader-label">Preview (og:image)</div>
          <div class="page-form__hint">Recommended size 1200x630 pixels</div>
          <ProOptionWrapper :featureName="'seo'">
            <UploadImage
              v-model="options.seo.ogImage"
              buttonText="Upload image"
            />
          </ProOptionWrapper>
        </div>
      </div>

      <div class="settings-form__settings-wrap">
        <p class="settings-form__settings-wrap__label">Meta title</p>
        <div class="page-form__hint">Maximum length 160 characters</div>
        <ProOptionWrapper :featureName="'seo'">
          <textarea
            v-model.trim="$v.options.seo.metaTitle.$model"
            class="settings-form__textarea"
          ></textarea>
        </ProOptionWrapper>
        <p
          v-if="!$v.options.seo.metaTitle.maxLength"
          class="form-field__error"
        >
          Maximum length of the value should be less then 160 characters
        </p>
      </div>

      <div class="settings-form__settings-wrap">
        <p class="settings-form__settings-wrap__label">Meta description</p>
        <div class="page-form__hint">Maximum length 160 characters</div>
        <ProOptionWrapper :featureName="'seo'">
          <textarea
            v-model.trim="$v.options.seo.metaDescription.$model"
            class="settings-form__textarea"
          ></textarea>
        </ProOptionWrapper>
        <p
          v-if="!$v.options.seo.metaDescription.maxLength"
          class="form-field__error"
        >
          Maximum length of the value should be less then 160 characters
        </p>
      </div>

      <div class="settings-form__settings-wrap">
        <ProOptionWrapper :featureName="'seo'">
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Disallow search engines to see this website</div>
            <label
              :class="{ 'selected':  notindexing}"
              class="switcher"
            >
              <input
                v-model="notindexing"
                class="switcher__input"
                type="checkbox"
                @change="$trackFeature('settings', 'notindexing', notindexing)"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </ProOptionWrapper>
      </div>
    </CollapsableBlock>

    <CollapsableBlock
      :locked="!isAllowedFeature('sideWidePasswords')"
      :expanded="accordionsState.siteWidePasswords"
      class="side-form__section"
      title="SITE-WIDE PASSWORDS"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.siteWidePasswords = !accordionsState.siteWidePasswords"
    >
      <div class="settings-form__settings-wrap">
        <ProOptionWrapper :featureName="'sideWidePasswords'">
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Password protected</div>
            <label
              :class="{ 'selected':  passwordProtection.enabled}"
              class="switcher"
            >
              <input
                v-model="passwordProtection.enabled"
                class="switcher__input"
                type="checkbox"
                @change="$trackFeature('settings', 'passwordProtection', passwordProtection.enabled)"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
          <div class="tile-constructor__hint"
          style="margin-top: 0px; margin-bottom: 10px">
            <a target="_blank" href="https://help.spreadsimple.com/en/article/site-wide-password-protection-12i1d72/">Help: How to set up password protection</a>
          </div>
        </ProOptionWrapper>
      </div>

      <div class="settings-form__settings-wrap">
        <ProOptionWrapper :featureName="'sideWidePasswords'">
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Protect only Sheets content (cards)</div>
            <label
              :class="{ 'selected':  passwordProtection.cardsOnly}"
              class="switcher"
            >
              <input
                v-model="passwordProtection.cardsOnly"
                class="switcher__input"
                type="checkbox"
                @change="$trackFeature('settings', 'passwordProtectionCardsOnly', passwordProtection.cardsOnly)"
              >
              <span class="switcher__indicator"></span>
            </label>
          </div>
        </ProOptionWrapper>
      </div>

      <div class="settings-form__settings-wrap">
        <p class="settings-form__settings-wrap__label">Passwords</p>
        <div class="page-form__hint">Each password should be added from the new line</div>
        <ProOptionWrapper :featureName="'sideWidePasswords'">
          <textarea
            v-model.trim="passwords"
            class="settings-form__textarea"
            @focus="passwordsFocused = true"
            @click="passwordsFocused = true"
            @blur="passwordsFocused = false"
          ></textarea>
        </ProOptionWrapper>
        <p
          v-if="!$v.pwdProtection.passwords.maxLength"
          class="form-field__error"
        >
          Passwords limit has been reached. You can add up to 10000 passwords.
        </p>
      </div>

      <div class="settings-form__settings-wrap">
        <p class="settings-form__settings-wrap__label">Welcome text</p>
        <div class="page-form__hint"></div>
        <ProOptionWrapper :featureName="'sideWidePasswords'">
          <MarkdownComponent
            v-model="passwordProtection.message"
            :preview="false"
            leftToolbar="bold italic strikethrough link"
          />
        </ProOptionWrapper>
      </div>
    </CollapsableBlock>

    <CollapsableBlock
      :locked="!isAllowedFeature('redirectRules')"
      :expanded="accordionsState.redirectRules"
      class="side-form__section"
      title="REDIRECT RULES"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.redirectRules = !accordionsState.redirectRules"
    >
      <RedirectRulesForm v-model="options.redirectRules"/>
    </CollapsableBlock>

    <CollapsableBlock
      :expanded="accordionsState.socialMedia"
      class="side-form__section"
      title="SOCIAL MEDIA"
      :externalControl="true"
      @toggle-collapsable-block="accordionsState.socialMedia = !accordionsState.socialMedia"
    >
      <SocialNetworksForm
        v-model="options.socialNetworks"
        :vuelidate-object="$v.options.socialNetworks"
      />
    </CollapsableBlock>
    <UiBtn
      size="l"
      attr-type="submit"
      :loading="loading"
      :disabled="loading || spreadViewerOptionsInvalid"
      class="aside-save"
    >
      SAVE
    </UiBtn>
    <CodeInjectModal ref="scriptInHeadModal" v-model="options.scripts.scriptInHead"/>
    <CodeInjectModal ref="scriptInBodyStartModal" v-model="options.scripts.scriptInBodyStart"/>
    <CodeInjectModal ref="scriptInBodyFinishModal" v-model="options.scripts.scriptInBodyFinish"/>
    <Modal
      v-model="widgetBuilderModal"
      :closeOnClickOutside="false"
      modalName="Widget Builder"
      :hasCloseButton="true"
    >
      <WidgetCode :domain="domain"/>
    </Modal>
  </form>

</template>
<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import UploadImage from '@/components/upload-image/'
import CollapsableBlock from '@/components/collapsable-block'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import WidgetCode from '@/components/widget-code'
import FormField from '@/components/form-field'
import UiBtn from '@/components/ui-btn'
import { maxLength, required, url } from 'vuelidate/lib/validators'
import MarkdownComponent from '@/components/markdown-component'
import Modal from '@/components/modal'
import RedirectRulesForm from '@/components/redirect-rules-form'
import { mapState, mapActions, mapGetters } from 'vuex'
import SocialNetworksForm from '@/components/social-networks-form'
import DomainSettingsForm from '@/components/domain-settings-form'
import CodeInjectModal from '@/components/code-inject-modal/CodeInjectModal.vue'
import { allowFeature } from '@/modules/features-checker'
Vue.use(Vuelidate)
export default {
  name: 'SpreadViewerSettings',
  components: {
    CodeInjectModal,
    SocialNetworksForm,
    WidgetCode,
    ProOptionWrapper,
    FormField,
    UiBtn,
    CollapsableBlock,
    UploadImage,
    MarkdownComponent,
    Modal,
    RedirectRulesForm,
    DomainSettingsForm
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    domain: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean
    },
    pwdProtection: {
      type: Object,
      required: true
    },
    locales: {
      type: Array,
      required: true
    },
    seoIndexing: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      options: null,
      notindexing: false,
      enablePassword: false,
      enablePasswordOnlyForContent: false,
      validationStatus: '',
      passwordProtection: {},
      passwordsFocused: false,
      widgetBuilderModal: false,
      accordionsState: {
        general: false,
        domain: false,
        widget: false,
        scripts: false,
        seo: false,
        siteWidePasswords: false,
        redirectRules: false,
        socialMedia: false
      }
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    passwords: {
      get () {
        if (!this.passwordProtection.passwords) return ''
        const lines = this.passwordsFocused
          ? this.passwordProtection.passwords
          : this.passwordProtection.passwords.map(line => String(line).replace(/./g, '•'))
        return lines.join('\n')
      },
      set (val) {
        this.passwordProtection.passwords = val.length ? val.split('\n').map(i => i.trim()) : []
      }
    }
  },
  validations: {
    options: {
      seo: {
        metaTitle: {
          maxLength: maxLength(160)
        },
        metaDescription: {
          maxLength: maxLength(160)
        }
      },
      socialNetworks: {
        $each: {
          value: { required, url }
        }
      }
    },
    pwdProtection: {
      passwords: {
        maxLength: maxLength(10000)
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.options = val
      }
    },
    options: {
      deep: true,
      handler (val) {
        if (this.$v.$invalid) {
          this.validationStatus = this.$t('pageManageSheet.settings.optionsSubmitError')
        }
      }
    },
    'options.widget.widgetModeDisallowed': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.options.widget.onlyWidgetMode = false
        }
      }
    },
    'options.widget.onlyWidgetMode': {
      handler (newVal, oldVal) {
        if (newVal) {
          this.options.widget.widgetModeDisallowed = false
        }
      }
    }
  },
  methods: {
    ...mapActions('app', ['setSpreadViewerOptionsValidationState']),
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      this.validationStatus = ''
      this.$emit('update-pwd-protection', this.passwordProtection)
      this.$emit('update-indexing', !this.notindexing)
      this.$emit('save', this.options)
    },
    isAllowedFeature (name) {
      return allowFeature(name, {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    },
    openDomainSettingsAccordion () {
      this.accordionsState = {
        general: false,
        domain: false,
        widget: false,
        scripts: false,
        seo: false,
        siteWidePasswords: false,
        redirectRules: false,
        socialMedia: false
      }
      this.$nextTick(() => {
        this.accordionsState.domain = true
      })
    }
  },
  created () {
    this.options = this.value
    this.notindexing = !this.seoIndexing
    this.passwordProtection = this.pwdProtection
    this.$root.$on('openDomainSettings', this.openDomainSettingsAccordion)
  },
  mounted () {
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'settingsOptionsInvalid',
        isInvalid: val
      })
    })
  },
  beforeDestroy () {
    this.$root.$off('openDomainSettings')
  }
}
</script>
