<template>
  <form
    class="page-form side-form"
    @submit.prevent="submitForm"
  >
    <p v-if="validationStatus !== ''" class="aside-message">
      {{ validationStatus }}
    </p>
    <ProOptionWrapper :featureName="'userAccess'">
      <AccessManagementSpreadForm
        :accessRules="accessRules"
        :v="$v.accessRules.spreadsheetUrl"
        :accessRulesError="accessRulesError"
        :loading="processing"
        :refreshingSheetData="refreshingSheetData"
        @create-users-spreadsheet="checkGoogleDrivePermissions"
        @refresh-users-spreadsheet="refreshUsersSpreadSheet"
        @connect-users-spreadsheet="getSpreadsheetScheme"
        @update-spread-sheet-url="updateSpreadSheetUrl"
      />
    </ProOptionWrapper>
    <template v-if="websiteAccess.scheme.length">
      <div class="side-form__section">
        <ProOptionWrapper :featureName="'userAccess'">
          <AccessModeManagementSwitcher
            title="Enable access rules"
            :isEnabled="accessRules.enabled"
            @toggle-access-mode="accessRules.enabled = !accessRules.enabled"
          />
        </ProOptionWrapper>
      </div>
      <CollapsableBlock :expanded="true" class="side-form__section" title="ACCESS SETTINGS">
        <!-- <div v-show="allowPastLink || websiteAccess.created" class="side-form__item">
          <div class="content-form__highlighted">
            <div class="access-manager__mb-10">
              <p>🚨 Don`t forget to grant permission for our service account to access your Google Sheet:</p>
              <p class="sorting-constructor__link access-manager__link" @click="copyEmailToClipboard">{{ serviceAccount }}</p>
              <br>
              <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/">
                Help: How to set up website access rules?
              </a>
            </div>
          </div>
        </div> -->

        <FormField label="User email column" class="side-form__item">
          <ProOptionWrapper :featureName="'userAccess'">
          <v-select
            v-model="accessRules.tableFieldsMapping.email.id"
            :disabled="processing || !websiteAccess.scheme.length"
            :items="websiteAccess.scheme"
            item-text="label"
            item-value="id"
            attach
            :placeholder="!websiteAccess.scheme.length ? 'Connect users sheet first' : 'Select sheet column for user email'"
            menu-props="offsetY"
            append-icon="keyboard_arrow_down"
            >
          </v-select>
            <p
              v-if="$v.accessRules.tableFieldsMapping.accessGroups.id.$dirty && !$v.accessRules.tableFieldsMapping.email.id"
              class="form-field__error"
            >
              {{ $t('global.fieldIsRequired') }}
            </p>
          </ProOptionWrapper>
        </FormField>
        <FormField label="Access group column" class="side-form__item">
          <ProOptionWrapper :featureName="'userAccess'">
          <v-select
            v-model="accessRules.tableFieldsMapping.accessGroups.id"
            :disabled="processing || !websiteAccess.scheme.length"
            :items="websiteAccess.scheme"
            item-text="label"
            item-value="id"
            attach
            :placeholder="!websiteAccess.scheme.length ? 'Connect users sheet first' : 'Select sheet column for access group'"
            menu-props="offsetY"
            append-icon="keyboard_arrow_down"
            >
          </v-select>
            <p
              v-if="$v.accessRules.tableFieldsMapping.accessGroups.id.$dirty && !$v.accessRules.tableFieldsMapping.accessGroups.id.required"
              class="form-field__error"
            >
              {{ $t('global.fieldIsRequired') }}
            </p>
          </ProOptionWrapper>
        </FormField>
      </CollapsableBlock>
      <CollapsableBlock :expanded="false" class="side-form__section" title="LOGIN PAGE SETTINGS">
        <div class="side-form__item">
          <div class="side-form__item-label access-manager__mb-8">Login page welcome text</div>
          <ProOptionWrapper :featureName="'userAccess'">
            <MarkdownComponent
              v-model="options.loginPage.markdown"
              :preview="false"
              leftToolbar="bold italic strikethrough link"
              height="200px"
            />
          </ProOptionWrapper>
        </div>
      </CollapsableBlock>
      <CollapsableBlock :expanded="false" class="side-form__section" title="E-MAIL SETTINGS">
        <div class="side-form__item">
          <p class="side-form__item-label placement-top">E-mail sender name:</p>
            <ProOptionWrapper :featureName="'userAccess'">
            <input
              v-model.trim="options.emailOptions.websiteAccess.fromName"
              type="text"
              class="input"
            />
            </ProOptionWrapper>
            <p
              v-if="$v.options.emailOptions.websiteAccess.fromName.$invalid"
              class="side-form__item-msg"
            >{{ !$v.options.emailOptions.websiteAccess.fromName.required ? 'This field id required' : 'Length of the value should be less then 50 characters' }}</p>
        </div>
        <div class="side-form__item">
          <p class="side-form__item-label placement-top">E-mail subject:</p>
            <ProOptionWrapper :featureName="'userAccess'">
            <input
              v-model.trim="options.emailOptions.websiteAccess.subject"
              type="text"
              class="input"
            />
            </ProOptionWrapper>
            <p
              v-if="$v.options.emailOptions.websiteAccess.subject.$invalid"
              class="side-form__item-msg"
            >{{ !$v.options.emailOptions.websiteAccess.fromName.required ? 'This field id required' : 'Length of the value should be less then 50 characters' }}</p>
        </div>

        <div class="side-form__item">
          <p class="side-form__item-label placement-top">E-mail body:</p>
            <ProOptionWrapper :featureName="'userAccess'">
              <MarkdownComponent
                v-model.trim="options.emailOptions.websiteAccess.body"
                height="200px"
                :preview="false"
                leftToolbar="bold italic strikethrough link"
              />
            </ProOptionWrapper>
            <p
              v-if="$v.options.emailOptions.websiteAccess.body.$invalid"
              class="side-form__item-msg"
            >{{ !$v.options.emailOptions.websiteAccess.body.required ? 'This field id required' : 'Length of the value should be less then 5000 characters' }}</p>
        </div>

      </CollapsableBlock>
      <div v-if="websiteAccess.created" class="access-manager__wrapper--center">
        <ProOptionWrapper :featureName="'userAccess'">
          <button class="profile-tab__danger-btn" @click.prevent="modalDeleteOpened = true">Reset access settigns</button>
        </ProOptionWrapper>
        <Modal
          v-model="modalDeleteOpened"
          modalName="Are you sure?"
        >
          <div class="delete-modal">
            <div class="delete-modal__body">
              <p class="delete-modal__paragraph">This action will delete access rules that you have customized</p>
              <form class="delete-modal__confirm-form" @submit.prevent="deleteAccessRules">
                <div class="delete-modal__field">
                  <button :disabled="loading" :class="{'disabled': loading}" type="submit" class="delete-modal__button">Delete</button>
                  <button type="button" class="delete-modal__button negative" @click="modalDeleteOpened = false">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </template>
    <UiBtn
      size="l"
      attr-type="submit"
      :loading="processing"
      :disabled="!websiteAccess ? (!accessRules.enabled || processing || $v.accessRules.$invalid) : processing || spreadViewerOptionsInvalid"
      class="aside-save"
    >
      {{ !websiteAccess.created ? 'SET UP ACCESS SETTINGS' : 'SAVE' }}
    </UiBtn>
    <Modal
        v-model="googleDrivePermissionsModal"
        :hasCloseButton="false"
        max-width="700px"
        top="auto"
        style="--modalPaddingTop: 0;"
        @input="closeGoogleDrivePermissionsModal"
      >
        <div class="ui-flex ui-flex--items-start gap-20 p-28">
          <img
            src="/img/lock-2.svg"
            alt="lock icon"
            class="ui-flex--none"
          >
          <div>
            <h4 class="heading--size-s text--weight-700">Allow SpreadSimple to create and read the created Google Sheets</h4>
            <p class="mt-12 text--weight-500">The app will not be able to access or read any other files stored on your Google Drive. Your other data will remain safe.</p>
          </div>
          <div>
            <UiBtn
              label="Allow..."
              uppercase
              full-width
              @click="createUsersSpreadsheet(true)"
            />
            <UiBtn
              label="Dismiss"
              uppercase
              full-width
              class="mt-12"
              type="secondary-text-blue"
              @click="closeGoogleDrivePermissionsModal"
            />
          </div>
        </div>
      </Modal>
  </form>
</template>

<script>
import ProOptionWrapper from '@/components/pro-option-wrapper'
import AccessModeManagementSwitcher from './AccessManagementModeSwitcher.vue'
import AccessManagementSpreadForm from './AccessManagementSpreadForm.vue'
import FormField from '@/components/form-field'
import MarkdownComponent from '@/components/markdown-component'
import UiBtn from '@/components/ui-btn'
import Modal from '@/components/modal'
import CollapsableBlock from '@/components/collapsable-block'
import { mapActions, mapState, mapGetters } from 'vuex'
import api from '@/services/api/'
import { required, maxLength } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import { validateSheetUrl } from 'google-sheets-data'
import Vue from 'vue'
import { useProviderAsObject } from '@/modules/utils/authProviders.js'
import { ensureGoogleScope, getGoogleDrivePermissions } from '@/modules/google-utils'
Vue.use(Vuelidate)

export default {
  name: 'SpreadViewerAccessOptions',
  components: {
    AccessModeManagementSwitcher,
    AccessManagementSpreadForm,
    FormField,
    MarkdownComponent,
    UiBtn,
    Modal,
    CollapsableBlock,
    ProOptionWrapper
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    domain: {
      type: String,
      default: ''
    },
    msg: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      accessRules: null,
      options: null,
      validationStatus: '',
      currentEditingGroup: null,
      accessRulesError: null,
      loading: false,
      refreshingSheetData: false,
      modalDeleteOpened: false,
      serviceAccount: process.env.VUE_APP_SS_SERVICE_ACCOUNT,
      allowPastLink: false,
      googleDrivePermissionsModal: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'websiteAccess',
      'currentSpreadViewId'
    ]),
    ...mapGetters('app', [
      'spreadViewerOptionsInvalid'
    ]),
    authProvidersList () {
      if (!this.websiteAccess.authProviders) return []
      return useProviderAsObject(this.websiteAccess.authProviders)
    },
    processing () {
      return this.isLoading || this.loading
    }
  },
  validations: {
    accessRules: {
      spreadsheetUrl: {
        required,
        isSpreadsheetUrl: validateSheetUrl
      },
      authProvider: {
        required
      },
      tableFieldsMapping: {
        email: {
          id: {
            required
          }
        },
        accessGroups: {
          id: {
            required
          }
        }
      }
    },
    options: {
      emailOptions: {
        websiteAccess: {
          fromName: {
            required,
            senderNameMaxLength: maxLength(50)
          },
          subject: {
            required,
            subjectMaxLength: maxLength(100)
          },
          body: {
            required,
            bodyMaxLength: maxLength(500)
          }
        }
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.options = val
      }
    }
  },
  methods: {
    ...mapActions('app', [
      'updateWebsiteAccessRules',
      'setDefaultWebsiteAccessState',
      'setSpreadViewerOptionsValidationState'
    ]),
    async submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      if (!this.websiteAccess.created) {
        await this.createAccessRules()
        this.createNavigationPages()
      } else {
        await this.updateAccessRules()
      }
      this.options.privateData.privateRow.enabled = this.accessRules.enabled
      this.$emit('save', this.options)
      this.validationStatus = ''
    },

    async checkGoogleDrivePermissions () {
      this.loading = true
      try {
        const scopeGranted = await ensureGoogleScope('https://www.googleapis.com/auth/drive.file')
        if (!scopeGranted) {
          this.googleDrivePermissionsModal = true
        } else {
          await this.createUsersSpreadsheet(false)
        }
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },

    closeGoogleDrivePermissionsModal () {
      this.googleDrivePermissionsModal = false
    },

    async createUsersSpreadsheet (getPermissions = false) {
      try {
        this.loading = true
        this.googleDrivePermissionsModal = false
        if (getPermissions) {
          const permissions = await getGoogleDrivePermissions(this.userData.email, 'https://www.googleapis.com/auth/drive.file')
          if (!permissions) {
            this.loading = false
            return
          }
        }
        const { editableConfigs } = await api.createWebsiteAccessRules(this.currentSpreadViewId, null, true)
        this.accessRules = editableConfigs
        editableConfigs.created = true
        this.updateWebsiteAccessRules(editableConfigs)
        const { authProviders, scheme } = await api.getAccessSheetScheme(this.accessRules.spreadsheetUrl)
        this.updateWebsiteAccessRules({ authProviders, scheme })
        if (authProviders.length === 1) {
          this.accessRules.authProvider = authProviders[0]
        }
        if (!this.options.emailOptions.websiteAccess.fromName) {
          this.options.emailOptions.websiteAccess.fromName = this.options.spreadView.name || 'SpreadSimple'
        }
        await this.getWebsiteAccessGroups()
        this.$toasted.show('Access Rules were created successfully', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
        this.createNavigationPages()
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    async getSpreadsheetScheme () {
      try {
        this.loading = true
        const { authProviders, scheme } = await api.getAccessSheetScheme(this.accessRules.spreadsheetUrl)
        this.updateWebsiteAccessRules({ authProviders, scheme })
        if (authProviders.length === 1) {
          this.accessRules.authProvider = authProviders[0]
        }
        if (!this.options.emailOptions.websiteAccess.fromName) {
          this.options.emailOptions.websiteAccess.fromName = this.options.spreadView.name || 'SpreadSimple'
        }
        this.$toasted.show('Successfully got spreadsheet scheme', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
      } catch (err) {
        if (err.message.includes('500')) {
          this.accessRulesError = 'Internal Server Error: maybe you did not share your spreadsheet with our service account'
        } else {
          this.accessRulesError = err.message
        }
        console.warn(err)
      } finally {
        this.loading = false
        setTimeout(() => {
          this.accessRulesError = null
        }, 3000)
      }
    },
    async createAccessRules () {
      try {
        this.loading = true
        const { editableConfigs } = await api.createWebsiteAccessRules(this.currentSpreadViewId, this.accessRules)
        this.accessRules = editableConfigs
        editableConfigs.created = true
        this.updateWebsiteAccessRules(editableConfigs)
        await this.getWebsiteAccessGroups()
        this.$toasted.show('Access Rules were created successfully', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    async getWebsiteAccessGroups () {
      try {
        this.loading = true
        const { availableAccessGroups } = await api.getWebsiteAccessGroups(this.currentSpreadViewId)
        this.updateWebsiteAccessRules({ availableAccessGroups })
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    async updateAccessRules () {
      try {
        this.loading = true
        await api.saveWebsiteAccessRules(this.currentSpreadViewId, {
          authProvider: this.accessRules.authProvider,
          enabled: this.accessRules.enabled,
          spreadsheetUrl: this.accessRules.spreadsheetUrl,
          tableFieldsMapping: this.accessRules.tableFieldsMapping
        })
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
    async refreshUsersSpreadSheet () {
      try {
        this.loading = true
        this.refreshingSheetData = true
        const { authProviders, scheme } = await api.getAccessSheetScheme(this.accessRules.spreadsheetUrl)
        this.updateWebsiteAccessRules({ authProviders, scheme })
        await api.refreshWebsiteAccessUsers(this.currentSpreadViewId, this.accessRules)
        await this.getWebsiteAccessGroups()
        this.$toasted.show('Users spreadsheet was updated successfully', {
          theme: 'toasted-primary',
          position: 'top-center',
          type: 'success',
          duration: 1500
        })
      } catch (err) {
        console.warn(err.message)
      } finally {
        this.loading = false
        this.refreshingSheetData = false
      }
    },
    updateSpreadSheetUrl (val) {
      this.accessRules.spreadsheetUrl = val
      this.$v.accessRules.spreadsheetUrl.$touch()
    },
    async deleteAccessRules () {
      try {
        this.modalDeleteOpened = false
        this.loading = true
        this.options.privateData.privateRow.id = null
        this.options.privateData.privateRow.enabled = false
        this.accessRules = this.setDefaultAccessRules()
        await api.deleteWebsiteAccessRules(this.currentSpreadViewId)
        this.setDefaultWebsiteAccessState()
        this.$v.$reset()
        this.validationStatus = ''
        this.$emit('save', this.options)
      } catch (err) {
        console.warn(err.message)
      } finally {
        this.loading = false
      }
    },
    copyEmailToClipboard () {
      window.navigator.clipboard.writeText(this.serviceAccount)
        .then(() => {
          this.$toasted.show('E-mail copied to clipboard!', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(err => {
          console.log(err.message)
        })
    },
    createNavigationPages () {
      if (!this.options.routes.some(r => r.navUrl.includes('login'))) {
        this.options.routes.push({
          type: 'link',
          navTitle: 'Login',
          navUrl: '/login',
          navAlias: '',
          navTarget: '_self',
          navStyle: 'link',
          navHide: false,
          metaTitle: '',
          metaDescription: '',
          accessRules: {
            allowedGroups: []
          }
        })
      }
      if (!this.options.routes.some(r => r.navUrl.includes('logout'))) {
        this.options.routes.push({
          type: 'link',
          navTitle: 'Logout',
          navUrl: '/logout',
          navAlias: '',
          navTarget: '_self',
          navStyle: 'link',
          navHide: false,
          metaTitle: '',
          metaDescription: '',
          accessRules: {
            allowedGroups: []
          }
        })
      }
      this.$emit('save', this.options)
    },
    setDefaultAccessRules () {
      return {
        enabled: false,
        spreadsheetUrl: '',
        authProvider: '',
        tableFieldsMapping: {
          email: {
            id: null
          },
          accessGroups: {
            id: null
          }
        }
      }
    }
  },
  async created () {
    this.accessRules = this.setDefaultAccessRules()
    this.options = JSON.parse(JSON.stringify(this.value))
    if (this.websiteAccess.created) {
      this.accessRules = this.websiteAccess
      if (!this.accessRules.scheme.length) {
        await this.getSpreadsheetScheme()
      }
      await this.getWebsiteAccessGroups()
    }
    if (!this.options.emailOptions.websiteAccess.fromName) {
      this.options.emailOptions.websiteAccess.fromName = this.options.spreadView.name || 'SpreadSimple'
    }
  },
  mounted () {
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'accessOptionsInvalid',
        isInvalid: val
      })
    })
  }
}

</script>
