var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-option" }, [
    _c("h6", { staticClass: "aside-field-label" }, [_vm._v(_vm._s(_vm.label))]),
    _c(
      "div",
      { staticClass: "custom-option__control align-end" },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "label",
          {
            key: index,
            staticClass: "cover-layout",
            class: [{ active: option.value === _vm.value }, option.value],
          },
          [
            _c("input", {
              staticClass: "cover-layout__input",
              staticStyle: { display: "none" },
              attrs: { type: "radio", name: "theme" },
              domProps: { value: option.value },
              on: { input: (e) => _vm.$emit("input", e.target.value) },
            }),
            _c(
              "div",
              { staticClass: "cover-layout__trigger" },
              [
                _c("span", [_vm._v(" " + _vm._s(_vm.options.icon) + " ")]),
                [
                  _c("i", {
                    staticClass: "cover-layout__icon",
                    class: option.icon,
                  }),
                ],
              ],
              2
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }