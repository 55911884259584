<template>
  <form
    class="side-form"
    @submit.prevent="submitForm"
  >
    <p v-if="validationStatus !== ''" class="aside-message">
      {{ validationStatus }}
    </p>

    <transition name="fade3" mode="out-in">
      <div
        v-if="activeSection === ''"
        key="1"
        class="side-form__subforms-switcher"
      >
        <div
          v-for="section in sections"
          :key="section.name"
          class="side-form__subforms-switcher-item"
          @click="activeSection = section.name"
        >
          <h6 class="side-form__subforms-switcher-item-title">
            {{section.title}}
            <UiIcon
              v-if="section.name === 'webhooks' && !isAllowedFeature('webhook')"
              icon="mf-lock"
              class="collapsable-block__lock-icon"
            />
          </h6>
          <i class="side-form__subforms-switcher-item-icon icon-carret-down"></i>
        </div>
      </div>

      <div v-else key="2" class="side-form">
        <button
          type="button"
          class="side-form__back-btn"
          @click="activeSection = ''"
        >
          <i class="side-form__back-btn-icon icon-arrow"></i>
          Back to checkout
        </button>
        <div v-show="activeSection === 'formFields'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">
            Form fields
            <UiHelper
              class="va-bottom ml-2"
              popoverFeature="form-fields"
              showCloseBtn
              :videoData="{
                src: '/video/helper-popup/form-fields.mp4',
                btns: [
                  {
                    label: 'Watch video',
                    to: { name: 'help-video', query: { show: 'form-fields' }},
                    event: {
                      type: 'goToVideo'
                    }
                  }
                ]
              }"
            />
          </h6>
          <!-- <div class="tile-constructor__hint"
          style="margin-top: -15px; margin-bottom: 10px">
            <a target="_blank" href="https://help.spreadsimple.com/en/article/customizing-order-form-fields-1alashw/">Help: How to manage form fields</a>
          </div> -->
          <ProOptionWrapper :featureName="'checkout'">
            <FormBuilder v-model="options.checkout.fields"/>
          </ProOptionWrapper>
        </div>

        <div v-show="activeSection === 'cartSettings'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">
            Cart settings
            <UiHelper
              class="va-bottom ml-2"
              popoverFeature="сart-settings"
              showCloseBtn
              :videoData="{
                src: '/video/helper-popup/сart-settings.mp4',
                label: 'Learn how to set up and customize a shopping cart',
                btns: [
                  {
                    label: 'Learn more',
                    to: 'https://help.spreadsimple.com/en/article/cart-settings-65sm1g/',
                    externalResource: true,
                    color: 'secondary-blue',
                    event: {
                      type: 'goToHelp'
                    }
                  },
                  {
                    label: 'Watch video',
                    to: { name: 'help-video', query: { show: 'сart-settings' }},
                    event: {
                      type: 'goToVideo'
                    }
                  }
                ]
              }"
            />
          </h6>
          <!-- <div class="tile-constructor__hint"
            style="margin-top: -15px; margin-bottom: 10px">
            <a target="_blank" href="https://help.spreadsimple.com/en/article/cart-settings-65sm1g/">Help: How to set up cart view</a>
          </div> -->
          <div class="side-form__section">
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <FormField label="Shopping cart view">
                <v-select
                  v-model="options.cart.cartView"
                  :items="cartViewOptions"
                  item-text="label"
                  item-value="value"
                  item-disabled="disabled"
                  attach
                  placeholder="Choose cart view"
                  menu-props="offsetY"
                  append-icon="keyboard_arrow_down"
                  @change="$trackFeature('checkout', `cartView`, true, options.cart.cartView)"
                  >
                </v-select>
              </FormField>
            </ProOptionWrapper>
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <FormField label="On item added to the cart">
                <v-select
                  v-model="options.cart.onAddToCart"
                  :items="addToCartOptions"
                  item-text="label"
                  item-value="value"
                  item-disabled="disabled"
                  attach
                  placeholder="Choose action"
                  menu-props="offsetY"
                  append-icon="keyboard_arrow_down"
                  @change="$trackFeature('checkout', `onAddToCart`, true, options.cart.onAddToCart)"
                  >
                </v-select>
              </FormField>
            </ProOptionWrapper>
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Show cart preview</div>
                <label
                  :class="{ 'selected': options.cart.showCartPreview }"
                  class="switcher"
                >
                  <input
                    v-model="options.cart.showCartPreview"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'showCartPreview', options.cart.showCartPreview)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
          </div>
          <div v-if="options.cart.showCartPreview" class="side-form__section">
            <div class="side-form__item">
              <p class="side-form__item-label">Cart preview title</p>
              <p class="side-form__item-hint">Maximum length 25 characters</p>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.сartPreviewSettings.title.$model"
                  type="text"
                  :placeholder="msg.yourCart"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.сartPreviewSettings.title.previewMaxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 25 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Cart preview button text</p>
              <p class="side-form__item-hint">Maximum length 25 characters</p>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.сartPreviewSettings.submitButtonText.$model"
                  type="text"
                  :placeholder="msg.checkoutSubmit"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.сartPreviewSettings.submitButtonText.previewMaxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 25 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Cart preview "Continue" button text</p>
              <div class="side-form__item-hint">Maximum length 50 characters</div>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.сartPreviewSettings.backButtonText.$model"
                  type="text"
                  :placeholder="msg.checkoutContinueShopping"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.сartPreviewSettings.backButtonText.previewMaxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 50 characters
              </p>
            </div>
          </div>

          <div class="side-form__section">
            <div class="side-form__item">
              <p class="side-form__item-label">Cart title</p>
              <div class="side-form__item-hint">Maximum length 25 characters</div>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.cart_title.$model"
                  type="text"
                  :placeholder="msg.yourCart"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cart_title.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 25 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Cart form title</p>
              <div class="side-form__item-hint">Maximum length 25 characters</div>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.cartFormTitle.$model"
                  type="text"
                  :placeholder="msg.clientData"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cartFormTitle.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 50 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Cart button text</p>
              <div class="side-form__item-hint">Maximum length 25 characters</div>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.cart_button_text.$model"
                  type="text"
                  :placeholder="msg.checkoutSubmit"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cart_button_text.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 25 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Text for "Continue" button</p>
              <div class="side-form__item-hint">Maximum length 50 characters</div>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.cart_continue_text.$model"
                  type="text"
                  :placeholder="msg.checkoutContinueShopping"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cart_continue_text.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 50 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Cart details text</p>
              <ProOptionWrapper :featureName="'checkout'">
                <MarkdownComponent
                  v-model.trim="$v.options.cart.cart_details_message.$model"
                  height="200px"
                  :preview="false"
                  leftToolbar="bold italic strikethrough link"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cart_details_message.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 500 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label">Success message title</p>
              <p class="side-form__item-hint">Maximum length 25 characters</p>
              <ProOptionWrapper :featureName="'checkout'">
                <input
                  v-model.trim="$v.options.cart.cart_tm_title.$model"
                  type="text"
                  :placeholder="msg.checkoutThanksTitle"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.cart.cart_tm_title.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 25 characters
              </p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Success message text</p>
              <ProOptionWrapper :featureName="'checkout'">
                <MarkdownComponent
                  v-model.trim="$v.checkoutThanksText.$model"
                  height="200px"
                  :preview="false"
                  leftToolbar="bold italic strikethrough link"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.checkoutThanksText.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 250 characters
              </p>
            </div>
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Limit max amount of items in cart to 1</div>
                <label
                  :class="{ 'selected': options.cart.cartItemsAmountLimit }"
                  class="switcher"
                >
                  <input
                    v-model="options.cart.cartItemsAmountLimit"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'cartItemsAmountLimit', options.cart.cartItemsAmountLimit)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Limit max quantity of an item in cart to 1</div>
                <label
                  :class="{ 'selected': options.cart.cartItemQuantityLimit }"
                  class="switcher"
                >
                  <input
                    v-model="options.cart.cartItemQuantityLimit"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'cartItemQuantityLimit', options.cart.cartItemQuantityLimit)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">"Copy link to cart" button </div>
                <label
                  :class="{ 'selected': options.cart.cartCopyLink }"
                  class="switcher"
                >
                  <input
                    v-model="options.cart.cartCopyLink"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'cartCopyLink', options.cart.cartCopyLink)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
          </div>
        </div>

        <div v-show="activeSection === 'promocodes'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">Promo codes</h6>
          <div class="side-form__item">
            <ProOptionWrapper :featureName="'checkout'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Enable promo codes on checkout</div>
                <label
                  :class="{ 'selected': options.checkout.promocodes.enabled }"
                  class="switcher"
                >
                  <input
                    v-model="options.checkout.promocodes.enabled"
                    class="switcher__input"
                    type="checkbox"
                    @change="$trackFeature('checkout', 'promocodes', options.checkout.promocodes.enabled)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
          </div>
          <div class="side-form__item">
            <p class="side-form__item-hint is-link">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/setting-up-promo-codes-17o3klj/">Help: How to use promo codes?</a>
            </p>
            <ProOptionWrapper :featureName="'checkout'">
              <PromoCodesComponent/>
            </ProOptionWrapper>
          </div>
        </div>

        <div v-show="activeSection === 'shippingFeesPayment'" class="side-form__block-wrapper">
          <div class="side-form__item">
            <h6 class="side-form__section-title">Shipping</h6>
            <div class="tile-constructor__hint"
            style="margin-top: -15px; margin-bottom: 10px">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/adding-shipping-methods-and-rates-86cptr/">Help: How to add a shipping method</a>
            </div>
            <ProOptionWrapper :featureName="'checkout'">
              <OptionsBuilder
                v-model="options.checkout.shipping"
                addButtonTitle="+ Add shipping method"
                modalTitle="New shipping method"
                schema="shippingFieldSchema"
              />
            </ProOptionWrapper>
          </div>
          <div class="side-form__item">
            <h6 class="side-form__section-title">Taxes & Fees</h6>
            <div class="tile-constructor__hint"
            style="margin-top: -15px; margin-bottom: 10px">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/adding-taxes-and-fees-ctajlg/">Help: Adding taxes & fees</a>
            </div>
            <ProOptionWrapper :featureName="'checkout'">
              <OptionsBuilder
                v-model="options.checkout.fees"
                addButtonTitle="+ Add new fee"
                modalTitle="Tax or fee"
                schema="feeFieldSchema"
              />
            </ProOptionWrapper>
          </div>
          <div class="side-form__item">
            <h6 class="side-form__section-title">Tips</h6>
            <div class="tile-constructor__hint"
            style="margin-top: -15px; margin-bottom: 10px">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/adding-tips-1i6xarj/">Help: Adding tips</a>
            </div>
            <FormField
              label="Customize the section title"
              class="tile-constructor__field aside-select"
            >
              <v-text-field
                v-model.trim="options.checkout.tipsTitle"
              />
            </FormField>
            <ProOptionWrapper :featureName="'checkout'">
              <OptionsBuilder
                v-model="options.checkout.tips"
                addButtonTitle="+ Add new tip"
                modalTitle="Tips"
                schema="feeFieldSchema"
              />
            </ProOptionWrapper>
          </div>
          <div class="side-form__item">
            <h6 class="side-form__section-title">Payments</h6>
            <ProOptionWrapper :featureName="'checkout'">
              <PaymentsBuilder
                v-model="options.checkout.paymentsOrder"
                :integrations="integrations"
                :integrationsData="integrationsData"
                @open-payment-addons="$emit('open-payment-addons')"
                @disable-payment-addon="val => $emit('disable-payment-addon', val)"
              />
            </ProOptionWrapper>
          </div>
        </div>

        <div v-show="activeSection === 'emailNotifications'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">Email notifications</h6>
          <div class="side-form__section">
            <ProOptionWrapper :featureName="'notifications'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Enable Email notifications</div>
                <label
                  :class="{ 'selected': options.emailOptions.notificationsEmailEnabled }"
                  class="switcher"
                >
                  <input
                    v-model="options.emailOptions.notificationsEmailEnabled"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'notificationsEmailEnabled', options.emailOptions.notificationsEmailEnabled)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
          </div>
          <div v-if="options.emailOptions.notificationsEmailEnabled" class="side-form__section">
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">TO email:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="$v.emailTo.$model"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.emailTo.email"
                class="side-form__item-msg"
              >Please provide valid email address</p>
              <p
                v-if="!$v.emailTo.required"
                class="side-form__item-msg"
              >This field id required</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Sender name:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="options.emailOptions.notificationsEmailSenderName"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.emailOptions.notificationsEmailSenderName.required"
                class="side-form__item-msg"
              >This field id required</p>
              <p
                v-if="!$v.options.emailOptions.notificationsEmailSenderName.maxLength"
                class="side-form__item-msg"
              >Maximum length of the value should be less then 50 characters</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Subject:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="$v.emailSubject.$model"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.emailSubject.maxLength"
                class="side-form__item-msg"
              >Maximum length of the value should be less then 100 characters</p>
              <p
                v-if="!$v.emailSubject.required"
                class="side-form__item-msg"
              >This field id required</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Notification email template</p>
              <ProOptionWrapper :featureName="'notifications'">
                <MarkdownComponent
                  v-model.trim="emailNotificationTemplate"
                  :cols="cols"
                  height="200px"
                  :preview="false"
                  leftToolbar="bold italic strikethrough link | cartItemsTable"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.emailNotificationTemplate.maxLength"
                class="side-form__item-msg"
              >
                Maximum length of the value should be less then 5000 characters
              </p>
              <UiMsgBox
                v-if="isAllowedFeature('brandNotificationsTemplate')"
                icon="mf-error_outline"
                class="mt-12"
                color="warning"
                >
                <b>Your email notifications will be branded</b>
                <p>Please note that on the current plan, the email notifications will be branded with SpreadSimple logo and text. </p>
                <p>To remove SpreadSimple branding, apply a different kind of licesence to this website.</p>
                <UpgradePlanButton
                  :navigateToBilling="true"
                  tag="button"
                >
                  <b>Upgrade Plan</b>
                </UpgradePlanButton>
              </UiMsgBox>
            </div>
          </div>
        </div>

        <div v-show="activeSection === 'emailreceipts'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">Email receipts</h6>
          <div class="side-form__section">
            <ProOptionWrapper :featureName="'notifications'" class="side-form__item">
              <div class="side-form__switcher-wrap">
                <div class="side-form__switcher-label">Enable Email receipts</div>
                <label
                  :class="{ 'selected': options.emailOptions.receiptEmailEnabled }"
                  class="switcher"
                >
                  <input
                    v-model="options.emailOptions.receiptEmailEnabled"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('checkout', 'receiptEmailEnabled', options.emailOptions.receiptEmailEnabled)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </ProOptionWrapper>
            <div class="side-form__item-hint is-link placement-bottom">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-up-email-receipts-158mx3i/">Help: How to set up Email receipts</a>
            </div>
          </div>
          <div v-if="options.emailOptions.receiptEmailEnabled" class="side-form__section">
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">REPLY TO email:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="options.emailOptions.receiptReplyToEmail"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.emailOptions.receiptReplyToEmail.email"
                class="side-form__item-msg"
              >Please provide valid email address</p>
              <p
                v-if="!$v.options.emailOptions.receiptReplyToEmail.required"
                class="side-form__item-msg"
              >This field id required</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Receipt sender name:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="options.emailOptions.receiptSenderName"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.emailOptions.receiptSenderName.required"
                class="side-form__item-msg"
              >This field id required</p>
              <p
                v-if="!$v.options.emailOptions.receiptSenderName.maxLength"
                class="side-form__item-msg"
              >Maximum length of the value should be less then 50 characters</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Receipt Email subject:</p>
              <ProOptionWrapper :featureName="'notifications'">
                <input
                  v-model.trim="options.emailOptions.receiptEmailSubject"
                  type="text"
                  class="input"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.emailOptions.receiptEmailSubject.maxLength"
                class="side-form__item-msg"
              >Maximum length of the value should be less then 100 characters</p>
              <p
                v-if="!$v.options.emailOptions.receiptEmailSubject.required"
                class="side-form__item-msg"
              >This field id required</p>
            </div>
            <div class="side-form__item">
              <p class="side-form__item-label placement-top">Receipt email template</p>
              <ProOptionWrapper :featureName="'notifications'">
                <MarkdownComponent
                  v-model.trim="options.emailOptions.receiptEmailTemplate"
                  :cols="cols"
                  height="200px"
                  :preview="false"
                  leftToolbar="bold italic strikethrough link | cartItemsTable"
                />
              </ProOptionWrapper>
              <p
                v-if="!$v.options.emailOptions.receiptEmailTemplate.maxLength"
                class="side-form__item-msg"
              >Maximum length of the value should be less then 5000 characters</p>
              <UiMsgBox
                v-if="isAllowedFeature('brandNotificationsTemplate')"
                icon="mf-error_outline"
                class="mt-12"
                color="warning"
              >
                <p><b>Your email receipts will be branded</b></p>
                <p>Please note that on the current plan, the email receipts will be branded with SpreadSimple logo and text. </p>
                <p>To remove SpreadSimple branding, apply a different kind of licesence to this website.</p>
                <UpgradePlanButton :navigateToBilling="true">
                  <b>Upgrade Plan</b>
                </UpgradePlanButton>
              </UiMsgBox>
            </div>
          </div>
        </div>

        <div v-show="activeSection === 'webhooks'" class="side-form__block-wrapper">
          <h6 class="side-form__section-title">Webhooks</h6>
          <div class="side-form__item">
            <p class="side-form__item-label placement-top">Webhook URL</p>
            <ProOptionWrapper :featureName="'webhook'">
              <input
                v-model.trim="$v.options.webhooks.sendOrder.$model"
                type="text"
                class="input"
              />
            </ProOptionWrapper>
            <p class="side-form__item-hint is-link placement-bottom">
              <a target="_blank" href="https://help.spreadsimple.com/en/article/how-to-use-webhooks-for-automation-19majym/">Help: How to use Webhooks for automation?</a>
            </p>
            <p
              v-if="!$v.options.webhooks.sendOrder.url"
              class="side-form__item-msg"
            >Please provide a valid URL</p>
          </div>
        </div>
      </div>
    </transition>
    <UiBtn
      size="l"
      attr-type="submit"
      :loading="loading"
      :disabled="loading || spreadViewerOptionsInvalid"
      class="aside-save"
    >
      SAVE
    </UiBtn>
  </form>
</template>
<script>
import { maxLength, email, required, url } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import Vue from 'vue'
import MarkdownComponent from '@/components/markdown-component'
import FormBuilder from '@/components/form-builder'
import OptionsBuilder from '@/components/form-builder/OptionsBuilder.vue'
import PaymentsBuilder from '@/components/form-builder/PaymentsBuilder.vue'
import PromoCodesComponent from '@/components/promocodes-component/PromoCodesComponent.vue'
import FormField from '@/components/form-field'
import UiMsgBox from '@/components/ui-msg-box/UiMsgBox.vue'
import UiBtn from '@/components/ui-btn'
import UiHelper from '@/components/ui-helper'
import UiIcon from '@/components/ui-icon/UiIcon.vue'
import UpgradePlanButton from '@/components/pro-option-wrapper/UpgradePlanButton.vue'
import { allowFeature } from '@/modules/features-checker'
import { mapState, mapActions, mapGetters } from 'vuex'

Vue.use(Vuelidate)

const cartViewOptions = [{
  value: 'popup',
  label: 'Popup'
}, {
  value: 'page',
  label: 'Page'
}]
const onAddToCartOptions = [{
  value: 'openCheckout',
  label: 'Open checkout'
}, {
  value: 'openPreview',
  label: 'Open preview'
}, {
  value: 'doNothing',
  label: 'Do nothing'
}]

export default {
  name: 'SpreadViewerCheckoutOptions',
  components: {
    ProOptionWrapper,
    MarkdownComponent,
    FormBuilder,
    OptionsBuilder,
    PaymentsBuilder,
    FormField,
    UiBtn,
    PromoCodesComponent,
    UiMsgBox,
    UiIcon,
    UpgradePlanButton,
    UiHelper
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    },
    msg: {
      type: Object,
      required: true
    },
    integrations: {
      type: Array,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    },
    cols: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: null,
      validationStatus: '',
      emailTo: '',
      emailSubject: '',
      emailNotificationTemplate: '',
      cartViewOptions,
      onAddToCartOptions,
      activeSection: '',
      sections: [
        {
          title: 'CART SETTINGS',
          name: 'cartSettings'
        },
        {
          title: 'FORM FIELDS',
          name: 'formFields'
        },
        {
          title: 'SHIPPING, FEES, TIPS & PAYMENT',
          name: 'shippingFeesPayment'
        },
        {
          title: 'PROMO CODES',
          name: 'promocodes'
        },
        {
          title: 'EMAIL NOTIFICATIONS',
          name: 'emailNotifications'
        },
        {
          title: 'EMAIL RECEIPTS',
          name: 'emailreceipts'
        },
        {
          title: 'WEBHOOKS',
          name: 'webhooks'
        }
      ]
    }
  },
  validations: {
    emailTo: {
      email,
      required
    },
    emailSubject: {
      maxLength: maxLength(100),
      required
    },
    emailNotificationTemplate: {
      maxLength: maxLength(5000),
      required
    },
    options: {
      webhooks: {
        sendOrder: {
          url
        }
      },
      emailOptions: {
        receiptReplyToEmail: {
          email,
          required
        },
        receiptEmailSubject: {
          maxLength: maxLength(100),
          required
        },
        receiptEmailTemplate: {
          maxLength: maxLength(5000),
          required
        },
        receiptSenderName: {
          maxLength: maxLength(50),
          required
        },
        notificationsEmailSenderName: {
          maxLength: maxLength(50),
          required
        }
      },
      cart: {
        cartFormTitle: {
          maxLength: maxLength(50)
        },
        cart_title: {
          maxLength: maxLength(25)
        },
        cart_details_message: {
          maxLength: maxLength(500)
        },
        cart_button_text: {
          maxLength: maxLength(25)
        },
        cart_continue_text: {
          maxLength: maxLength(50)
        },
        cart_tm_title: {
          maxLength: maxLength(25)
        },
        сartPreviewSettings: {
          title: {
            previewMaxLength: function (value) {
              return this.options.cart.showCartPreview ? maxLength(25)(value) : true
            }
          },
          submitButtonText: {
            previewMaxLength: function (value) {
              return this.options.cart.showCartPreview ? maxLength(25)(value) : true
            }
          },
          backButtonText: {
            previewMaxLength: function (value) {
              return this.options.cart.showCartPreview ? maxLength(50)(value) : true
            }
          }
        }
      }
    },
    checkoutThanksText: {
      maxLength: maxLength(250)
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    checkoutThanksText: {
      get () {
        return this.options.cart.cart_tm_text.length ? this.options.cart.cart_tm_text : this.msg.checkoutThanksText
      },
      set (val) {
        val.length
          ? this.options.cart.cart_tm_text = val
          : this.options.cart.cart_tm_text = this.msg.checkoutThanksText
      }
    },
    addToCartOptions () {
      return JSON.parse(JSON.stringify(this.onAddToCartOptions)).reduce((acc, option) => {
        option['disabled'] = false
        if (option.value === 'openPreview') {
          option['disabled'] = !this.options.cart.showCartPreview
        }
        acc.push(option)
        return acc
      }, [])
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.options = val
      }
    },
    options: {
      deep: true,
      handler (val) {
        if (this.$v.$dirty && this.$v.$invalid) {
          this.validationStatus = this.$t('pageManageSheet.settings.optionsSubmitError')
        }
      }
    },
    'options.cart.showCartPreview': function (val) {
      if (!val && this.options.cart.onAddToCart === 'openPreview') {
        this.options.cart.onAddToCart = 'doNothing'
      }
    },
    emailTo (val) {
      this.options.emailOptions.notificationsEmailTo = val
    },
    emailSubject (val) {
      this.options.emailOptions.notificationsEmailSubject = val
    },
    emailNotificationTemplate (val) {
      this.options.emailOptions.notificationsEmailTemplate = val
    }
  },
  methods: {
    ...mapActions('app', ['setSpreadViewerOptionsValidationState']),
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      this.validationStatus = ''
      this.$emit('save', this.options)
    },
    isAllowedFeature (name) {
      return allowFeature(name, {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    }
  },
  created () {
    this.options = this.value
    this.emailTo = this.options.emailOptions.notificationsEmailTo
    this.emailSubject = this.options.emailOptions.notificationsEmailSubject
    this.emailNotificationTemplate = this.options.emailOptions.notificationsEmailTemplate
    if (!this.options.emailOptions.receiptSenderName) {
      this.options.emailOptions.receiptSenderName = this.options.spreadView.name || 'SpreadSimple'
    }
    if (!this.options.emailOptions.notificationsEmailSenderName) {
      this.options.emailOptions.notificationsEmailSenderName = this.options.spreadView.name || 'SpreadSimple'
    }
  },
  mounted () {
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'checkoutOptionsInvalid',
        isInvalid: val
      })
    })
  }
}
</script>
