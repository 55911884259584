var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "tag",
      class: [
        `tag--color-${_vm.color}`,
        `tag--size-${_vm.size}`,
        { "tag--clickable": _vm.clickable },
      ],
    },
    [_c("span", { staticClass: "tag__title" }, [_vm._v(_vm._s(_vm.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }