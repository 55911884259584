var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "UpgradePlanButton",
        {
          staticClass: "pro-option__wrapper",
          class: { "is-active": !_vm.featureIsAvailable },
          attrs: { runAction: !_vm.featureIsAvailable },
        },
        [
          _c(
            "div",
            {
              staticClass: "pro-option",
              class: { "is-not-pro": !_vm.featureIsAvailable },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }