var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "available-websites__section" }, [
    _c("h5", { staticClass: "available-websites__section-title" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _c("div", { staticClass: "available-websites__section-info" }, [
      _c("span", { staticClass: "available-websites__section-info--regular" }, [
        _vm._v(
          _vm._s(_vm.amountOfActiveSpreadViews) +
            " out of " +
            _vm._s(_vm.amountOfAvailableActiveSpreadViews)
        ),
      ]),
      _c(
        "span",
        { staticClass: "available-websites__section-info--archived" },
        [
          _vm._v(
            " (" + _vm._s(_vm.amountOfArchivedSpreadViews) + " in archive)"
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "available-websites__section-progress-bar" }, [
      _c("div", {
        class: {
          "available-websites__section-progress-bar-fill": true,
          "available-websites__section-progress-bar-fulfilled": _vm.isLimitOver,
        },
        style: `width: ${_vm.progressBarFillingProcent}%`,
      }),
    ]),
    _vm.isLimitOver
      ? _c("div", { staticClass: "available-websites__section-warning" }, [
          _c(
            "svg",
            {
              staticClass: "available-websites__section-warning-icon",
              attrs: {
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M6.99968 13.6667C3.31967 13.6667 0.333008 10.68 0.333008 7.00002C0.333008 3.32002 3.31967 0.333352 6.99968 0.333352C10.6797 0.333352 13.6663 3.32002 13.6663 7.00002C13.6663 10.68 10.6797 13.6667 6.99968 13.6667ZM7.66634 3.66669H6.33301V7.66669H7.66634V3.66669ZM7.66634 9.00002H6.33301V10.3334H7.66634V9.00002Z",
                  fill: "#FF4D4D",
                },
              }),
            ]
          ),
          _c(
            "span",
            { staticClass: "available-websites__section-warning-message" },
            [_vm._v(_vm._s(_vm.warningMessage))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }