var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-code" }, [
    _c("div", { staticClass: "iframe-code__left-side" }, [
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "FormField",
            { attrs: { label: "Routing options" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.routingOptions,
                  "item-text": "label",
                  "item-value": "value",
                  attach: "",
                  placeholder: "Choose routing mode",
                  "menu-props": "offsetY",
                  "append-icon": "keyboard_arrow_down",
                },
                model: {
                  value: _vm.routingMode,
                  callback: function ($$v) {
                    _vm.routingMode = $$v
                  },
                  expression: "routingMode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "settings-form__settings-wrap" },
        [
          _c(
            "FormField",
            {
              attrs: {
                label: "Widget Path",
                hint: "Leave empty to point widget to the home page",
              },
            },
            [
              _c("v-text-field", {
                attrs: { placeholder: "/" },
                model: {
                  value: _vm.widgetPath,
                  callback: function ($$v) {
                    _vm.widgetPath = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "widgetPath",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "iframe-code__right-side" }, [
      _c("textarea", {
        ref: "code",
        staticClass: "iframe-code__textarea",
        attrs: { readonly: "" },
        domProps: { value: _vm.widgetCode },
      }),
      _c("div", { staticClass: "iframe-code__footer" }, [
        _c(
          "button",
          {
            staticClass: "iframe-code__copy-button",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.copyToClipboard.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n          " + _vm._s(_vm.$t("global.copy")) + "\n          "
            ),
            _c("i", {
              staticClass: "icon icon-duplicate2 iframe-code__copy-button-icon",
            }),
          ]
        ),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "tile-constructor__hint",
        staticStyle: { "margin-bottom": "-10px", "margin-top": "0px" },
      },
      [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://help.spreadsimple.com/en/article/embedding-spreadsimple-as-a-widget-into-other-platforms-xorqqu/",
            },
          },
          [_vm._v("Help: Embedding as a widget")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }