var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-page" },
    [
      _c("div", { staticClass: "login-page__container" }, [
        _c("h1", { staticClass: "login-page__title" }, [
          _vm._v("\n      Sign in\n    "),
        ]),
        _c("p", { staticClass: "login-page__terms" }, [
          _vm._v(
            "\n      By continuing, Google will share your name, email address, language preference, and profile picture with SpreadSimple.\n    "
          ),
        ]),
        _c("div", { staticClass: "login-page__button-wrap" }, [
          _c("div", { ref: "googleSignInBtn" }),
          _vm.loginError
            ? _c(
                "p",
                {
                  staticClass: "login-page__terms",
                  staticStyle: { color: "red" },
                },
                [
                  _vm._v(
                    "\n        Failed to sign in.\n        Please, make sure that cookies are enabled in your browser settings.\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm.loginError
            ? _c(
                "p",
                {
                  staticClass: "login-page__terms",
                  staticStyle: { color: "red" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "login-page__terms-link",
                      attrs: {
                        href: "https://www.whatismybrowser.com/guides/how-to-enable-cookies/",
                        target: "_blank",
                        rel: "nofollow",
                      },
                    },
                    [_vm._v("\n          How to enable cookies?\n        ")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._m(0),
      ]),
      _c("Preloader", { attrs: { loading: _vm.isLoading } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "login-page__terms" }, [
      _vm._v("\n      See our\n      "),
      _c(
        "a",
        {
          staticClass: "login-page__terms-link",
          attrs: { href: "https://spreadsimple.com/policy/", target: "_blank" },
        },
        [_vm._v("\n        Privacy Policy\n      ")]
      ),
      _vm._v("\n      &\n      "),
      _c(
        "a",
        {
          staticClass: "login-page__terms-link",
          attrs: { href: "https://spreadsimple.com/terms/", target: "_blank" },
        },
        [_vm._v("\n        Terms of Service\n      ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }