var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sorting-constructor__item" },
    [
      _c("v-select", {
        attrs: {
          value: _vm.filtersState,
          items: _vm.cols,
          "item-text": "label",
          "item-value": "id",
          attach: "",
          "menu-props": "offsetY",
          chips: "",
          "deletable-chips": "",
          multiple: "",
          placeholder: "Choose options",
          "append-icon": "keyboard_arrow_down",
        },
        on: { change: _vm.updateFiltersValues },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (data) {
              return [
                _c(
                  "DraggableChip",
                  {
                    attrs: { id: data.index },
                    model: {
                      value: _vm.filtersState,
                      callback: function ($$v) {
                        _vm.filtersState = $$v
                      },
                      expression: "filtersState",
                    },
                  },
                  [_vm._v("\n        " + _vm._s(data.item.label) + "\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a",
        {
          staticClass: "sorting-constructor__link",
          attrs: {
            target: "_blank",
            href: "https://help.spreadsimple.com/en/article/how-to-use-filters-1mpgefx/?1595796655713",
          },
        },
        [_vm._v("Help: How to use filters?")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }