var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source-options" },
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.visible
          ? _c("div", { key: "btns" }, [
              _c("div", { staticClass: "source-options__row" }, [
                !_vm.accessRules.created
                  ? _c(
                      "button",
                      {
                        staticClass: "source-options__link",
                        attrs: { disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("create-users-spreadsheet")
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "source-options__link-icon icon-edit-google-sheet",
                        }),
                        _vm._v("\n          CREATE USERS SHEET\n        "),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "source-options__link",
                        attrs: {
                          href: _vm.accessRules.spreadsheetUrl,
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "source-options__link-icon icon-edit-google-sheet",
                        }),
                        _vm._v("\n          EDIT USERS SHEET\n        "),
                      ]
                    ),
                _vm.accessRules.created
                  ? _c(
                      "button",
                      {
                        staticClass: "source-options__button",
                        attrs: {
                          disabled: _vm.loading,
                          title: "Refresh Users Sheet data",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$emit("refresh-users-spreadsheet")
                          },
                        },
                      },
                      [
                        !_vm.refreshingSheetData
                          ? _c("i", { staticClass: "icon-refresh-arrow" })
                          : _c("div", { staticClass: "icon-loader" }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "button",
                {
                  staticClass: "source-options__change-btn",
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.visible = true
                    },
                  },
                },
                [
                  _vm.accessRules.created
                    ? _c("i", {
                        staticClass:
                          "source-options__change-btn-icon icon-pencil-new",
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        !_vm.accessRules.created
                          ? "Add existing sheet"
                          : "Change source sheet"
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ])
          : _c("div", { key: "form", staticClass: "source-options__form" }, [
              _c(
                "button",
                {
                  staticClass: "source-options__form-back",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.visible = false
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "source-options__form-back-icon icon-arrow",
                  }),
                  _vm._v("\n        Back\n      "),
                ]
              ),
              _c("div", { staticClass: "source-options__form-wrapper" }, [
                _c(
                  "form",
                  {
                    staticClass: "spread-form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("connect-users-spreadsheet", _vm.url)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "spread-form__form-elements" },
                      [
                        _c("v-text-field", {
                          staticClass: "spread-form__sidebar-input",
                          attrs: {
                            disabled: _vm.loading,
                            label: `${
                              _vm.accessRules.created
                                ? "Linked spread sheet"
                                : "Paste Google Sheet link here"
                            }`,
                          },
                          on: {
                            input: (val) =>
                              _vm.$emit("update-spread-sheet-url", val),
                          },
                          model: {
                            value: _vm.url,
                            callback: function ($$v) {
                              _vm.url = $$v
                            },
                            expression: "url",
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "spread-form__sidebar-add-btn",
                            attrs: { disabled: _vm.loading, type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.websiteAccess.scheme.length && _vm.url
                                    ? "UPDATE"
                                    : "CONNECT"
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "spread-form__messages-wrapp" }, [
                      !_vm.v.required
                        ? _c(
                            "span",
                            { staticClass: "spread-form__message error" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    !_vm.v.required && _vm.v.$dirty
                                      ? _vm.$t("global.fieldIsRequired")
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm.v.$invalid
                        ? _c(
                            "span",
                            { staticClass: "spread-form__message error" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.v.$invalid && _vm.v.$dirty
                                      ? _vm.$t("global.notValidUrl")
                                      : ""
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.accessRulesError
                        ? _c(
                            "span",
                            { staticClass: "spread-form__message error" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.accessRulesError) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ]),
            ]),
      ]),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "side-form__item", staticStyle: { "margin-top": "30px" } },
      [
        _c(
          "a",
          {
            staticClass: "sorting-constructor__link",
            attrs: {
              target: "_blank",
              href: "https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/",
            },
          },
          [_vm._v("\n      Help: How to set up website access rules?\n    ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }