var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "settings-form side-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _vm.validationStatus !== ""
        ? _c("p", { staticClass: "aside-message side-form__item" }, [
            _vm._v("\n    " + _vm._s(_vm.validationStatus) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            expanded: _vm.accordionsState.general,
            title: "General",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.general = !_vm.accordionsState.general
            },
          },
        },
        [
          _c("div", { staticClass: "settings-form__settings-wrap" }, [
            _c(
              "div",
              { staticClass: "content-form__switch-field" },
              [
                _c(
                  "FormField",
                  { attrs: { label: "Website language" } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.locales,
                        "item-text": "label",
                        "item-value": "value",
                        attach: "",
                        placeholder: "Choose language",
                        "menu-props": "offsetY",
                        "append-icon": "keyboard_arrow_down",
                      },
                      model: {
                        value: _vm.options.locale,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "locale", $$v)
                        },
                        expression: "options.locale",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked:
              !_vm.isAllowedFeature("updateDefaultDomain") ||
              !_vm.isAllowedFeature("updateCustomDomain"),
            expanded: _vm.accordionsState.domain,
            title: "DOMAIN SETTINGS",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.domain = !_vm.accordionsState.domain
            },
          },
        },
        [_c("DomainSettingsForm")],
        1
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked: !_vm.isAllowedFeature("widget"),
            expanded: _vm.accordionsState.widget,
            title: "WIDGET SETTINGS",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.widget = !_vm.accordionsState.widget
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("ProOptionWrapper", { attrs: { featureName: "widget" } }, [
                _c(
                  "div",
                  {
                    staticClass: "uploader__btn",
                    on: {
                      click: function ($event) {
                        _vm.widgetBuilderModal = true
                      },
                    },
                  },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [
                        _c("div", { key: "btn" }, [
                          _vm._v(
                            "\n              Open Widget Builder\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("ProOptionWrapper", { attrs: { featureName: "widget" } }, [
                _c("div", { staticClass: "content-form__switch-field" }, [
                  _c("div", { staticClass: "content-form__switcher-wrap" }, [
                    _c("div", { staticClass: "content-form__switcher-label" }, [
                      _vm._v("Allow only widget mode"),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: { selected: _vm.options.widget.onlyWidgetMode },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.widget.onlyWidgetMode,
                              expression: "options.widget.onlyWidgetMode",
                            },
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.widget.onlyWidgetMode
                            )
                              ? _vm._i(
                                  _vm.options.widget.onlyWidgetMode,
                                  null
                                ) > -1
                              : _vm.options.widget.onlyWidgetMode,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.options.widget.onlyWidgetMode,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.options.widget,
                                        "onlyWidgetMode",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.options.widget,
                                        "onlyWidgetMode",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.options.widget,
                                    "onlyWidgetMode",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.$trackFeature(
                                  "settings",
                                  "onlyWidgetMode",
                                  _vm.options.widget.onlyWidgetMode
                                )
                              },
                            ],
                          },
                        }),
                        _c("span", { staticClass: "switcher__indicator" }),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "content-form__switch-field" }, [
                  _c("div", { staticClass: "content-form__switcher-wrap" }, [
                    _c("div", { staticClass: "content-form__switcher-label" }, [
                      _vm._v("Disallow widget mode"),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: {
                          selected: _vm.options.widget.widgetModeDisallowed,
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.widget.widgetModeDisallowed,
                              expression: "options.widget.widgetModeDisallowed",
                            },
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.widget.widgetModeDisallowed
                            )
                              ? _vm._i(
                                  _vm.options.widget.widgetModeDisallowed,
                                  null
                                ) > -1
                              : _vm.options.widget.widgetModeDisallowed,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a =
                                    _vm.options.widget.widgetModeDisallowed,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.options.widget,
                                        "widgetModeDisallowed",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.options.widget,
                                        "widgetModeDisallowed",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.options.widget,
                                    "widgetModeDisallowed",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.$trackFeature(
                                  "settings",
                                  "widgetModeDisallowed",
                                  _vm.options.widget.widgetModeDisallowed
                                )
                              },
                            ],
                          },
                        }),
                        _c("span", { staticClass: "switcher__indicator" }),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "tile-constructor__hint",
              staticStyle: { "margin-bottom": "0px", "margin-top": "-20px" },
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://help.spreadsimple.com/en/article/embedding-spreadsimple-as-a-widget-into-other-platforms-xorqqu/",
                  },
                },
                [_vm._v("Help: Embedding as a widget")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked: !_vm.isAllowedFeature("scripts"),
            expanded: _vm.accordionsState.scripts,
            title: "Inject custom code",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.scripts = !_vm.accordionsState.scripts
            },
          },
        },
        [
          _c("p", { staticClass: "page-form__hint" }, [
            _vm._v(
              "\n      You can add valid HTML tags, styles or scripts. This code will be injected to all pages.\n    "
            ),
          ]),
          _c("p", { staticClass: "page-form__hint" }, [
            _c("br"),
            _vm._v("\n      Surround the CSS with "),
            _c("code", { pre: true }, [_vm._v("<style></style>")]),
            _vm._v(" tags\n      and the JavaScript with "),
            _c("code", { pre: true }, [_vm._v("<script></script>")]),
            _vm._v(" tags.\n    "),
          ]),
          _c("br"),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c(
                "FormField",
                { attrs: { label: "<head> section" } },
                [
                  _c(
                    "ProOptionWrapper",
                    { attrs: { featureName: "scripts" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "uploader__btn",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.scriptInHeadModal.open()
                            },
                          },
                        },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c("div", { key: "btn" }, [
                                _vm._v(
                                  "\n                Open Editor\n              "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c(
                "FormField",
                { attrs: { label: "<body> – start" } },
                [
                  _c(
                    "ProOptionWrapper",
                    { attrs: { featureName: "scripts" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "uploader__btn",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.scriptInBodyStartModal.open()
                            },
                          },
                        },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c("div", { key: "btn" }, [
                                _vm._v(
                                  "\n                Open Editor\n              "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c(
                "FormField",
                { attrs: { label: "<body> – end" } },
                [
                  _c(
                    "ProOptionWrapper",
                    { attrs: { featureName: "scripts" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "uploader__btn",
                          on: {
                            click: function ($event) {
                              return _vm.$refs.scriptInBodyFinishModal.open()
                            },
                          },
                        },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c("div", { key: "btn" }, [
                                _vm._v(
                                  "\n                Open Editor\n              "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked: !_vm.isAllowedFeature("seo"),
            expanded: _vm.accordionsState.seo,
            title: "SEO",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.seo = !_vm.accordionsState.seo
            },
          },
        },
        [
          _c("div", { staticClass: "page-form__uploaders" }, [
            _c(
              "div",
              { staticClass: "page-form__uploader" },
              [
                _c("div", { staticClass: "page-form__uploader-label" }, [
                  _vm._v("Favicon"),
                ]),
                _c("div", { staticClass: "page-form__hint" }, [
                  _vm._v(
                    "Recommended size 32x32 pixels, .png or .ico image format"
                  ),
                ]),
                _c(
                  "ProOptionWrapper",
                  { attrs: { featureName: "seo" } },
                  [
                    _c("UploadImage", {
                      attrs: {
                        buttonText: "Upload favicon",
                        unsplashEnabled: false,
                      },
                      model: {
                        value: _vm.options.seo.favIcon,
                        callback: function ($$v) {
                          _vm.$set(_vm.options.seo, "favIcon", $$v)
                        },
                        expression: "options.seo.favIcon",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "page-form__uploader" },
              [
                _c("div", { staticClass: "page-form__uploader-label" }, [
                  _vm._v("Preview (og:image)"),
                ]),
                _c("div", { staticClass: "page-form__hint" }, [
                  _vm._v("Recommended size 1200x630 pixels"),
                ]),
                _c(
                  "ProOptionWrapper",
                  { attrs: { featureName: "seo" } },
                  [
                    _c("UploadImage", {
                      attrs: { buttonText: "Upload image" },
                      model: {
                        value: _vm.options.seo.ogImage,
                        callback: function ($$v) {
                          _vm.$set(_vm.options.seo, "ogImage", $$v)
                        },
                        expression: "options.seo.ogImage",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("p", { staticClass: "settings-form__settings-wrap__label" }, [
                _vm._v("Meta title"),
              ]),
              _c("div", { staticClass: "page-form__hint" }, [
                _vm._v("Maximum length 160 characters"),
              ]),
              _c("ProOptionWrapper", { attrs: { featureName: "seo" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.options.seo.metaTitle.$model,
                      expression: "$v.options.seo.metaTitle.$model",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "settings-form__textarea",
                  domProps: { value: _vm.$v.options.seo.metaTitle.$model },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.options.seo.metaTitle,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              !_vm.$v.options.seo.metaTitle.maxLength
                ? _c("p", { staticClass: "form-field__error" }, [
                    _vm._v(
                      "\n        Maximum length of the value should be less then 160 characters\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("p", { staticClass: "settings-form__settings-wrap__label" }, [
                _vm._v("Meta description"),
              ]),
              _c("div", { staticClass: "page-form__hint" }, [
                _vm._v("Maximum length 160 characters"),
              ]),
              _c("ProOptionWrapper", { attrs: { featureName: "seo" } }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.$v.options.seo.metaDescription.$model,
                      expression: "$v.options.seo.metaDescription.$model",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "settings-form__textarea",
                  domProps: {
                    value: _vm.$v.options.seo.metaDescription.$model,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.$v.options.seo.metaDescription,
                        "$model",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              !_vm.$v.options.seo.metaDescription.maxLength
                ? _c("p", { staticClass: "form-field__error" }, [
                    _vm._v(
                      "\n        Maximum length of the value should be less then 160 characters\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("ProOptionWrapper", { attrs: { featureName: "seo" } }, [
                _c("div", { staticClass: "content-form__switcher-wrap" }, [
                  _c("div", { staticClass: "content-form__switcher-label" }, [
                    _vm._v("Disallow search engines to see this website"),
                  ]),
                  _c(
                    "label",
                    {
                      staticClass: "switcher",
                      class: { selected: _vm.notindexing },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.notindexing,
                            expression: "notindexing",
                          },
                        ],
                        staticClass: "switcher__input",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.notindexing)
                            ? _vm._i(_vm.notindexing, null) > -1
                            : _vm.notindexing,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.notindexing,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.notindexing = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.notindexing = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.notindexing = $$c
                              }
                            },
                            function ($event) {
                              return _vm.$trackFeature(
                                "settings",
                                "notindexing",
                                _vm.notindexing
                              )
                            },
                          ],
                        },
                      }),
                      _c("span", { staticClass: "switcher__indicator" }),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked: !_vm.isAllowedFeature("sideWidePasswords"),
            expanded: _vm.accordionsState.siteWidePasswords,
            title: "SITE-WIDE PASSWORDS",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.siteWidePasswords =
                !_vm.accordionsState.siteWidePasswords
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c(
                "ProOptionWrapper",
                { attrs: { featureName: "sideWidePasswords" } },
                [
                  _c("div", { staticClass: "content-form__switcher-wrap" }, [
                    _c("div", { staticClass: "content-form__switcher-label" }, [
                      _vm._v("Password protected"),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: { selected: _vm.passwordProtection.enabled },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordProtection.enabled,
                              expression: "passwordProtection.enabled",
                            },
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.passwordProtection.enabled
                            )
                              ? _vm._i(_vm.passwordProtection.enabled, null) >
                                -1
                              : _vm.passwordProtection.enabled,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.passwordProtection.enabled,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.passwordProtection,
                                        "enabled",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.passwordProtection,
                                        "enabled",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.passwordProtection,
                                    "enabled",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.$trackFeature(
                                  "settings",
                                  "passwordProtection",
                                  _vm.passwordProtection.enabled
                                )
                              },
                            ],
                          },
                        }),
                        _c("span", { staticClass: "switcher__indicator" }),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tile-constructor__hint",
                      staticStyle: {
                        "margin-top": "0px",
                        "margin-bottom": "10px",
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "https://help.spreadsimple.com/en/article/site-wide-password-protection-12i1d72/",
                          },
                        },
                        [_vm._v("Help: How to set up password protection")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c(
                "ProOptionWrapper",
                { attrs: { featureName: "sideWidePasswords" } },
                [
                  _c("div", { staticClass: "content-form__switcher-wrap" }, [
                    _c("div", { staticClass: "content-form__switcher-label" }, [
                      _vm._v("Protect only Sheets content (cards)"),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: { selected: _vm.passwordProtection.cardsOnly },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordProtection.cardsOnly,
                              expression: "passwordProtection.cardsOnly",
                            },
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.passwordProtection.cardsOnly
                            )
                              ? _vm._i(_vm.passwordProtection.cardsOnly, null) >
                                -1
                              : _vm.passwordProtection.cardsOnly,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.passwordProtection.cardsOnly,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.passwordProtection,
                                        "cardsOnly",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.passwordProtection,
                                        "cardsOnly",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.passwordProtection,
                                    "cardsOnly",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.$trackFeature(
                                  "settings",
                                  "passwordProtectionCardsOnly",
                                  _vm.passwordProtection.cardsOnly
                                )
                              },
                            ],
                          },
                        }),
                        _c("span", { staticClass: "switcher__indicator" }),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("p", { staticClass: "settings-form__settings-wrap__label" }, [
                _vm._v("Passwords"),
              ]),
              _c("div", { staticClass: "page-form__hint" }, [
                _vm._v("Each password should be added from the new line"),
              ]),
              _c(
                "ProOptionWrapper",
                { attrs: { featureName: "sideWidePasswords" } },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.passwords,
                        expression: "passwords",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "settings-form__textarea",
                    domProps: { value: _vm.passwords },
                    on: {
                      focus: function ($event) {
                        _vm.passwordsFocused = true
                      },
                      click: function ($event) {
                        _vm.passwordsFocused = true
                      },
                      blur: [
                        function ($event) {
                          _vm.passwordsFocused = false
                        },
                        function ($event) {
                          return _vm.$forceUpdate()
                        },
                      ],
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.passwords = $event.target.value.trim()
                      },
                    },
                  }),
                ]
              ),
              !_vm.$v.pwdProtection.passwords.maxLength
                ? _c("p", { staticClass: "form-field__error" }, [
                    _vm._v(
                      "\n        Passwords limit has been reached. You can add up to 10000 passwords.\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-form__settings-wrap" },
            [
              _c("p", { staticClass: "settings-form__settings-wrap__label" }, [
                _vm._v("Welcome text"),
              ]),
              _c("div", { staticClass: "page-form__hint" }),
              _c(
                "ProOptionWrapper",
                { attrs: { featureName: "sideWidePasswords" } },
                [
                  _c("MarkdownComponent", {
                    attrs: {
                      preview: false,
                      leftToolbar: "bold italic strikethrough link",
                    },
                    model: {
                      value: _vm.passwordProtection.message,
                      callback: function ($$v) {
                        _vm.$set(_vm.passwordProtection, "message", $$v)
                      },
                      expression: "passwordProtection.message",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            locked: !_vm.isAllowedFeature("redirectRules"),
            expanded: _vm.accordionsState.redirectRules,
            title: "REDIRECT RULES",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.redirectRules =
                !_vm.accordionsState.redirectRules
            },
          },
        },
        [
          _c("RedirectRulesForm", {
            model: {
              value: _vm.options.redirectRules,
              callback: function ($$v) {
                _vm.$set(_vm.options, "redirectRules", $$v)
              },
              expression: "options.redirectRules",
            },
          }),
        ],
        1
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: {
            expanded: _vm.accordionsState.socialMedia,
            title: "SOCIAL MEDIA",
            externalControl: true,
          },
          on: {
            "toggle-collapsable-block": function ($event) {
              _vm.accordionsState.socialMedia = !_vm.accordionsState.socialMedia
            },
          },
        },
        [
          _c("SocialNetworksForm", {
            attrs: { "vuelidate-object": _vm.$v.options.socialNetworks },
            model: {
              value: _vm.options.socialNetworks,
              callback: function ($$v) {
                _vm.$set(_vm.options, "socialNetworks", $$v)
              },
              expression: "options.socialNetworks",
            },
          }),
        ],
        1
      ),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "l",
            "attr-type": "submit",
            loading: _vm.loading,
            disabled: _vm.loading || _vm.spreadViewerOptionsInvalid,
          },
        },
        [_vm._v("\n    SAVE\n  ")]
      ),
      _c("CodeInjectModal", {
        ref: "scriptInHeadModal",
        model: {
          value: _vm.options.scripts.scriptInHead,
          callback: function ($$v) {
            _vm.$set(_vm.options.scripts, "scriptInHead", $$v)
          },
          expression: "options.scripts.scriptInHead",
        },
      }),
      _c("CodeInjectModal", {
        ref: "scriptInBodyStartModal",
        model: {
          value: _vm.options.scripts.scriptInBodyStart,
          callback: function ($$v) {
            _vm.$set(_vm.options.scripts, "scriptInBodyStart", $$v)
          },
          expression: "options.scripts.scriptInBodyStart",
        },
      }),
      _c("CodeInjectModal", {
        ref: "scriptInBodyFinishModal",
        model: {
          value: _vm.options.scripts.scriptInBodyFinish,
          callback: function ($$v) {
            _vm.$set(_vm.options.scripts, "scriptInBodyFinish", $$v)
          },
          expression: "options.scripts.scriptInBodyFinish",
        },
      }),
      _c(
        "Modal",
        {
          attrs: {
            closeOnClickOutside: false,
            modalName: "Widget Builder",
            hasCloseButton: true,
          },
          model: {
            value: _vm.widgetBuilderModal,
            callback: function ($$v) {
              _vm.widgetBuilderModal = $$v
            },
            expression: "widgetBuilderModal",
          },
        },
        [_c("WidgetCode", { attrs: { domain: _vm.domain } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }