var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ProOptionWrapper", { attrs: { featureName: "mapsView" } }, [
    _c("div", { staticClass: "content-form__switch-field" }, [
      _c("div", { staticClass: "content-form__switcher-wrap" }, [
        _c(
          "div",
          { staticClass: "content-form__switcher-label" },
          [
            _vm._v("\n        Map view\n        "),
            _c("UiHelper", {
              staticClass: "va-bottom ml-2",
              attrs: {
                popoverFeature: "map-view",
                showCloseBtn: "",
                videoData: {
                  src: "/video/helper-popup/map-view.mp4",
                  label: "Learn how to set up and customize map view",
                  btns: [
                    {
                      label: "Learn more",
                      to: "https://help.spreadsimple.com/en/article/setting-up-map-view-ilfdcu/",
                      externalResource: true,
                      color: "secondary-blue",
                      event: {
                        type: "goToHelp",
                      },
                    },
                    {
                      label: "Watch video",
                      to: { name: "help-video", query: { show: "map-view" } },
                      event: {
                        type: "goToVideo",
                      },
                    },
                  ],
                },
              },
            }),
          ],
          1
        ),
        _c(
          "label",
          {
            staticClass: "switcher",
            class: { selected: _vm.mapViewState.enabled },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mapViewState.enabled,
                  expression: "mapViewState.enabled",
                },
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox", value: "tiles" },
              domProps: {
                checked: Array.isArray(_vm.mapViewState.enabled)
                  ? _vm._i(_vm.mapViewState.enabled, "tiles") > -1
                  : _vm.mapViewState.enabled,
              },
              on: {
                change: [
                  function ($event) {
                    var $$a = _vm.mapViewState.enabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "tiles",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.mapViewState,
                            "enabled",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.mapViewState,
                            "enabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.mapViewState, "enabled", $$c)
                    }
                  },
                  function ($event) {
                    return _vm.$trackFeature(
                      "content",
                      "mapView",
                      _vm.mapViewState.enabled
                    )
                  },
                ],
              },
            }),
            _c("span", { staticClass: "switcher__indicator" }),
          ]
        ),
      ]),
      _vm.mapViewState.enabled
        ? _c(
            "div",
            {
              staticClass:
                "content-form__switch-hidden-field aside-select paddingless",
            },
            [
              _c(
                "div",
                { staticClass: "content-form__highlighted" },
                [
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Coordinates" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.cols,
                          "item-text": "label",
                          "item-value": "id",
                          "menu-props": "offsetY",
                          placeholder: "Marker address",
                          "append-icon": "keyboard_arrow_down",
                        },
                        model: {
                          value: _vm.mapViewState.markerColId,
                          callback: function ($$v) {
                            _vm.$set(_vm.mapViewState, "markerColId", $$v)
                          },
                          expression: "mapViewState.markerColId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sorting-constructor__item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "sorting-constructor__link",
                        attrs: {
                          target: "_blank",
                          href: "https://help.spreadsimple.com/en/article/how-to-get-coordinates-1oc7v23/",
                        },
                      },
                      [_vm._v("Help: How to get coordinates?")]
                    ),
                  ]),
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Map type" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.mapTypes,
                          "item-disabled": "disabled",
                          "item-text": "label",
                          "item-value": "value",
                          "menu-props": "offsetY",
                          placeholder: "Map type",
                          "append-icon": "keyboard_arrow_down",
                        },
                        model: {
                          value: _vm.mapViewState.mapType,
                          callback: function ($$v) {
                            _vm.$set(_vm.mapViewState, "mapType", $$v)
                          },
                          expression: "mapViewState.mapType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "sorting-constructor__item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "sorting-constructor__link",
                        attrs: {
                          target: "_blank",
                          href: "https://help.spreadsimple.com/en/article/google-maps-add-on-4ag4nq/",
                        },
                      },
                      [_vm._v("Help: How to set up Google Maps Add-on?")]
                    ),
                  ]),
                  !_vm.singleItemPage
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "n-form-field side-form__item aside-input",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-wrap" },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__switcher-label" },
                                [_vm._v("Use clusters")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass: "switcher",
                                  class: {
                                    selected: _vm.mapViewState.useClusters,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mapViewState.useClusters,
                                        expression: "mapViewState.useClusters",
                                      },
                                    ],
                                    staticClass: "switcher__input",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.mapViewState.useClusters
                                      )
                                        ? _vm._i(
                                            _vm.mapViewState.useClusters,
                                            null
                                          ) > -1
                                        : _vm.mapViewState.useClusters,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.mapViewState.useClusters,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.mapViewState,
                                                "useClusters",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.mapViewState,
                                                "useClusters",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.mapViewState,
                                            "useClusters",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _c("span", {
                                    staticClass: "switcher__indicator",
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Marker type" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.$options.markerTypes,
                          "item-text": "label",
                          "item-value": "value",
                          "menu-props": "offsetY",
                          placeholder: "Marker type",
                          "append-icon": "keyboard_arrow_down",
                        },
                        model: {
                          value: _vm.mapViewState.markerType,
                          callback: function ($$v) {
                            _vm.$set(_vm.mapViewState, "markerType", $$v)
                          },
                          expression: "mapViewState.markerType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.mapViewState.markerType === "image"
                    ? _c(
                        "FormField",
                        {
                          staticClass: "side-form__item aside-input",
                          attrs: { label: "Marker Image" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.cols,
                              "item-text": "label",
                              "item-value": "id",
                              "menu-props": "offsetY",
                              placeholder: "Marker image",
                              "append-icon": "keyboard_arrow_down",
                            },
                            model: {
                              value: _vm.mapViewState.markerImageColId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.mapViewState,
                                  "markerImageColId",
                                  $$v
                                )
                              },
                              expression: "mapViewState.markerImageColId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Map height" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          required: "",
                          min: 50,
                          max: 1200,
                        },
                        model: {
                          value: _vm.mapViewState.mapHeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.mapViewState, "mapHeight", $$v)
                          },
                          expression: "mapViewState.mapHeight",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Zoom" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "number",
                          min: 1,
                          max: 20,
                          required: "",
                        },
                        model: {
                          value: _vm.mapViewState.zoom,
                          callback: function ($$v) {
                            _vm.$set(_vm.mapViewState, "zoom", $$v)
                          },
                          expression: "mapViewState.zoom",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.singleItemPage
                    ? _c(
                        "FormField",
                        {
                          staticClass: "side-form__item aside-input",
                          attrs: { label: "Map center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: { type: "text", required: "" },
                            model: {
                              value: _vm.mapViewState.mapCenter,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapViewState, "mapCenter", $$v)
                              },
                              expression: "mapViewState.mapCenter",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.singleItemPage
                    ? _c(
                        "FormField",
                        {
                          staticClass: "side-form__item aside-input",
                          attrs: { label: "Default view" },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.$options.viewTypes,
                              "item-text": "label",
                              "item-value": "value",
                              "menu-props": "offsetY",
                              placeholder: "Default view",
                              "append-icon": "keyboard_arrow_down",
                            },
                            model: {
                              value: _vm.mapViewState.defaultView,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapViewState, "defaultView", $$v)
                              },
                              expression: "mapViewState.defaultView",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "sorting-constructor__item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "sorting-constructor__link",
                        attrs: {
                          target: "_blank",
                          href: "https://help.spreadsimple.com/en/article/setting-up-map-view-ilfdcu/",
                        },
                      },
                      [_vm._v("Help: How to use map?")]
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }