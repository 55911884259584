var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "n-form-field",
      class: { "without-label": !_vm.label && !_vm.$slots.label },
    },
    [
      _vm.$slots.label || _vm.label || _vm.helperContent || _vm.helperVideoData
        ? _c(
            "div",
            { staticClass: "n-form-field__top" },
            [
              _vm.$slots.label || _vm.label
                ? _c(
                    "span",
                    {
                      staticClass: "n-form-field__label",
                      class: {
                        "required-field": _vm.required,
                      },
                    },
                    [
                      _vm._t("label", function () {
                        return [_vm._v(_vm._s(_vm.label))]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.helperContent || _vm.helperVideoData
                ? _c("UiHelper", {
                    staticClass: "n-form-field__helper",
                    attrs: {
                      popoverContent: _vm.helperContent,
                      videoData: _vm.helperVideoData,
                      triggerAction: _vm.helperTriggerAction,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "n-form-field__control",
          class: { error: _vm.messages.length },
        },
        [_vm._t("default")],
        2
      ),
      _vm.messages.length
        ? _c("p", { staticClass: "n-form-field__error-message" }, [
            _vm._v(" " + _vm._s(_vm.messages.join(", ")) + " "),
          ])
        : _vm._e(),
      _vm.hint
        ? _c("p", {
            staticClass: "n-form-field__hint",
            domProps: { innerHTML: _vm._s(_vm.hint) },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }