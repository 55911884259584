var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "settings__back-btn",
        on: {
          click: function ($event) {
            return _vm.goBackAndSave()
          },
        },
      },
      [
        _c("i", { staticClass: "icon-arrow settings__back-btn-icon" }),
        _vm._v("\n    Back to list\n  "),
      ]
    ),
    _c(
      "form",
      {
        staticClass: "side-form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _vm.validationStatus !== ""
          ? _c("p", { staticClass: "aside-message" }, [
              _vm._v("\n      " + _vm._s(_vm.validationStatus) + "\n    "),
            ])
          : _vm._e(),
        _vm.route.type === "page"
          ? _c("div", { staticClass: "side-form__section" }, [
              _c("h6", { staticClass: "side-form__section-title" }, [
                _vm._v("Content"),
              ]),
              _c("div", { staticClass: "side-form__item" }, [
                _c(
                  "button",
                  {
                    staticClass: "side-form__text-btn is-filled",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.openFullscreenEditor = true
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "icon-expand side-form__text-btn-icon",
                    }),
                    _vm._v("\n          Expand editor\n        "),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "side-form__item" },
                [
                  _c("MarkdownComponent", {
                    attrs: { preview: false },
                    model: {
                      value: _vm.route.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.route, "content", $$v)
                      },
                      expression: "route.content",
                    },
                  }),
                  _vm.openFullscreenEditor
                    ? _c(
                        "FullscreenMdEditor",
                        {
                          on: {
                            onclose: function ($event) {
                              _vm.openFullscreenEditor = false
                            },
                          },
                        },
                        [
                          _c("MarkdownComponent", {
                            attrs: { height: "100%", preview: true },
                            model: {
                              value: _vm.route.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.route, "content", $$v)
                              },
                              expression: "route.content",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "CollapsableBlock",
          {
            staticClass: "side-form__section",
            attrs: { expanded: true, title: "Navigation" },
          },
          [
            _c(
              "div",
              { staticClass: "side-form__item" },
              [
                _c(
                  "FormField",
                  { attrs: { label: `Menu item title` } },
                  [
                    _c("v-text-field", {
                      model: {
                        value: _vm.route.navTitle,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.route,
                            "navTitle",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "route.navTitle",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "side-form__item" },
              [
                _vm.route.type === "link"
                  ? _c(
                      "FormField",
                      {
                        attrs: {
                          label: "URL",
                          hint: "External URL must include a protocol prefix e.g. https://",
                        },
                      },
                      [
                        _c("v-text-field", {
                          on: { blur: _vm.trimInternalLink },
                          model: {
                            value: _vm.$v.route.$model.navUrl,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.route.$model,
                                "navUrl",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.route.$model.navUrl",
                          },
                        }),
                        _vm.$v.route.navUrl.$invalid
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("global.fieldIsRequired")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Page address",
                          messages: _vm.currentRouteUrlValidationErrors,
                        },
                      },
                      [
                        _c("v-text-field", {
                          on: { blur: _vm.trimInternalPageUrl },
                          model: {
                            value: _vm.$v.currentRouteUrl.$model,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.$v.currentRouteUrl,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.currentRouteUrl.$model",
                          },
                        }),
                        !_vm.$v.currentRouteUrl.$model
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("global.fieldIsRequired")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "side-form__item" },
              [
                _c(
                  "FormField",
                  { attrs: { label: `Open ${_vm.route.type} in` } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.navTargetOptions,
                        "item-text": "label",
                        "item-value": "value",
                        attach: "",
                        "menu-props": "offsetY",
                        "append-icon": "keyboard_arrow_down",
                      },
                      model: {
                        value: _vm.route.navTarget,
                        callback: function ($$v) {
                          _vm.$set(_vm.route, "navTarget", $$v)
                        },
                        expression: "route.navTarget",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "side-form__item" },
              [
                _c(
                  "FormField",
                  { attrs: { label: "Menu item type" } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.navStyleOptions,
                        "item-text": "label",
                        "item-value": "value",
                        attach: "",
                        "menu-props": "offsetY",
                        "append-icon": "keyboard_arrow_down",
                      },
                      model: {
                        value: _vm.route.navStyle,
                        callback: function ($$v) {
                          _vm.$set(_vm.route, "navStyle", $$v)
                        },
                        expression: "route.navStyle",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "side-form__item" }, [
              _c("div", { staticClass: "side-form__switcher-wrap" }, [
                _c("div", { staticClass: "side-form__switcher-label" }, [
                  _vm._v("Hide this " + _vm._s(_vm.route.type) + " from menu"),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "switcher",
                    class: { selected: _vm.route.navHide },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.route.navHide,
                          expression: "route.navHide",
                        },
                      ],
                      staticClass: "switcher__input",
                      attrs: { type: "checkbox", value: "tiles" },
                      domProps: {
                        checked: Array.isArray(_vm.route.navHide)
                          ? _vm._i(_vm.route.navHide, "tiles") > -1
                          : _vm.route.navHide,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.route.navHide,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "tiles",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.route,
                                  "navHide",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.route,
                                  "navHide",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.route, "navHide", $$c)
                          }
                        },
                      },
                    }),
                    _c("span", { staticClass: "switcher__indicator" }),
                  ]
                ),
              ]),
            ]),
          ]
        ),
        _vm.route.type === "link"
          ? _c(
              "CollapsableBlock",
              {
                staticClass: "side-form__section",
                attrs: { title: "URL Aliases" },
              },
              [
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "URL Alias",
                          hint: "A nice looking URL path for filtered content. <a href='https://help.spreadsimple.com/en/article/url-aliases-1xkqzul/' target=_blank>Learn more</a>",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: "(no alias)" },
                          on: { blur: _vm.trimAlias },
                          model: {
                            value: _vm.route.navAlias,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "navAlias",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "route.navAlias",
                          },
                        }),
                        _vm.route.navAlias && !_vm.$v.route.navAlias.isUnique
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            Route Alias URL must be unique\n          "
                              ),
                            ])
                          : _vm.route.navAlias &&
                            !_vm.$v.route.navAlias.isInternalUrl
                          ? _c("p", { staticClass: "form-field__error" }, [
                              _vm._v(
                                "\n            Aliases work only for internal URLs.\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Alias page title",
                          hint: "Maximum length 50 characters",
                        },
                      },
                      [
                        _c("v-text-field", {
                          model: {
                            value: _vm.route.aliasPageTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "aliasPageTitle",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "route.aliasPageTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Alias page subtitle",
                          hint: "Maximum length 300 characters",
                        },
                      },
                      [
                        _c("v-text-field", {
                          model: {
                            value: _vm.route.aliasPageSubTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "aliasPageSubTitle",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "route.aliasPageSubTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item",
                        attrs: { label: "Alias - text before cards" },
                      },
                      [
                        _c("MarkdownComponent", {
                          attrs: {
                            preview: false,
                            leftToolbar: "bold italic strikethrough link",
                          },
                          model: {
                            value: _vm.route.aliasContentBeforeDataMd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "aliasContentBeforeDataMd",
                                $$v
                              )
                            },
                            expression: "route.aliasContentBeforeDataMd",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item",
                        attrs: { label: "Alias - text after cards" },
                      },
                      [
                        _c("MarkdownComponent", {
                          attrs: {
                            preview: false,
                            leftToolbar: "bold italic strikethrough link",
                          },
                          model: {
                            value: _vm.route.aliasContentAfterDataMd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "aliasContentAfterDataMd",
                                $$v
                              )
                            },
                            expression: "route.aliasContentAfterDataMd",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Alias meta title",
                          hint: "Maximum length 160 characters",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: _vm.route.navTitle },
                          model: {
                            value: _vm.route.metaTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "metaTitle",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "route.metaTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Alias meta description",
                          hint: "Maximum length 160 characters",
                        },
                      },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.route.metaDescription,
                              expression: "route.metaDescription",
                            },
                          ],
                          staticClass: "textarea",
                          domProps: { value: _vm.route.metaDescription },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.route,
                                "metaDescription",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.route.type === "page"
          ? _c(
              "CollapsableBlock",
              { staticClass: "side-form__section", attrs: { title: "Seo" } },
              [
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Meta title",
                          hint: "Maximum length 160 characters",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: _vm.route.navTitle },
                          model: {
                            value: _vm.route.metaTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.route,
                                "metaTitle",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "route.metaTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "Meta description",
                          hint: "Maximum length 160 characters",
                        },
                      },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.route.metaDescription,
                              expression: "route.metaDescription",
                            },
                          ],
                          staticClass: "textarea",
                          domProps: { value: _vm.route.metaDescription },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.route,
                                "metaDescription",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "side-form__item" }, [
                  _c("div", { staticClass: "side-form__switcher-wrap" }, [
                    _c("div", { staticClass: "side-form__switcher-label" }, [
                      _vm._v("Hide this page from search results"),
                    ]),
                    _c(
                      "label",
                      {
                        staticClass: "switcher",
                        class: { selected: _vm.route.seoHide },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.route.seoHide,
                              expression: "route.seoHide",
                            },
                          ],
                          staticClass: "switcher__input",
                          attrs: { type: "checkbox", value: "tiles" },
                          domProps: {
                            checked: Array.isArray(_vm.route.seoHide)
                              ? _vm._i(_vm.route.seoHide, "tiles") > -1
                              : _vm.route.seoHide,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.route.seoHide,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "tiles",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.route,
                                      "seoHide",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.route,
                                      "seoHide",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.route, "seoHide", $$c)
                              }
                            },
                          },
                        }),
                        _c("span", { staticClass: "switcher__indicator" }),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm.$ft.ALLOW_USER_ACCESS
          ? _c(
              "CollapsableBlock",
              { staticClass: "side-form__section", attrs: { title: "ACCESS" } },
              [
                _c(
                  "div",
                  { staticClass: "side-form__item" },
                  [
                    _c(
                      "FormField",
                      {
                        attrs: {
                          label: "User groups that have access to this page",
                        },
                      },
                      [
                        _c(
                          "ProOptionWrapper",
                          { attrs: { featureName: "userAccess" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.websiteAccess.availableAccessGroups,
                                disabled: !_vm.websiteAccess.enabled,
                                "item-text": "name",
                                "item-value": "name",
                                attach: "",
                                placeholder: !_vm.websiteAccess.enabled
                                  ? "Create or enable access rules"
                                  : "Choose group",
                                clearable: "",
                                multiple: "",
                                chips: "",
                                "menu-props": "offsetY",
                                "append-icon": "keyboard_arrow_down",
                              },
                              model: {
                                value: _vm.route.accessRules.allowedGroups,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.route.accessRules,
                                    "allowedGroups",
                                    $$v
                                  )
                                },
                                expression: "route.accessRules.allowedGroups",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "sorting-constructor__link",
                        attrs: {
                          target: "_blank",
                          href: "https://help.spreadsimple.com/en/article/how-to-set-up-website-access-rules-1vre7ri/",
                        },
                      },
                      [_vm._v("Help: How to set up website access rules?")]
                    ),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "UiBtn",
          {
            staticClass: "aside-save",
            attrs: {
              size: "l",
              "attr-type": "submit",
              loading: _vm.loading,
              disabled: _vm.loading || _vm.$v.$invalid,
            },
          },
          [_vm._v("\n      SAVE\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }