var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "page-form side-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _vm.$v.$invalid
        ? _c("p", { staticClass: "aside-message" }, [
            _vm._v("\n    " + _vm._s(_vm.validationStatus) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "side-form__section" },
        [
          _c("ThemePicker", {
            model: {
              value: _vm.options.appearance.theme,
              callback: function ($$v) {
                _vm.$set(_vm.options.appearance, "theme", $$v)
              },
              expression: "options.appearance.theme",
            },
          }),
          _c(
            "div",
            { staticClass: "side-form__item is-justified" },
            [
              _c("p", { staticClass: "side-form__item-label" }, [
                _vm._v("Website background color"),
              ]),
              _c("ColorPicker", {
                staticStyle: { top: "0" },
                attrs: { value: _vm.options.appearance.bgColor },
                on: { input: (val) => (_vm.options.appearance.bgColor = val) },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "side-form__item is-justified" }, [
            _c("div", { staticClass: "side-form__item-label" }, [
              _vm._v("Show 'Scroll to top' button"),
            ]),
            _c(
              "label",
              {
                staticClass: "switcher",
                class: { selected: _vm.options.appearance.scrollToTopBtn },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.appearance.scrollToTopBtn,
                      expression: "options.appearance.scrollToTopBtn",
                    },
                  ],
                  staticClass: "switcher__input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.options.appearance.scrollToTopBtn
                    )
                      ? _vm._i(_vm.options.appearance.scrollToTopBtn, null) > -1
                      : _vm.options.appearance.scrollToTopBtn,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.options.appearance.scrollToTopBtn,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.options.appearance,
                                "scrollToTopBtn",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.options.appearance,
                                "scrollToTopBtn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.options.appearance,
                            "scrollToTopBtn",
                            $$c
                          )
                        }
                      },
                      function ($event) {
                        return _vm.$trackFeature(
                          "design",
                          "scrollToTopBtn",
                          _vm.options.appearance.scrollToTopBtn
                        )
                      },
                    ],
                  },
                }),
                _c("span", { staticClass: "switcher__indicator" }),
              ]
            ),
          ]),
          _vm._m(0),
          _c(
            "div",
            { staticClass: "side-form__item is-justified" },
            [
              _c(
                "FormField",
                {
                  staticClass: "side-form__item aside-input",
                  attrs: { label: "Heading Font" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      value: _vm.options.appearance.globalFonts.headingFont,
                      items: _vm.popularGoogleFontsLabels,
                      "item-text": "label",
                      "item-value": "value",
                      clearable: "",
                      attach: "",
                      "deletable-chips": "",
                      placeholder: "Use default font",
                      "append-icon": "keyboard_arrow_down",
                    },
                    on: { input: _vm.updateGoogleFont },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "side-form__item is-justified" },
            [
              _c(
                "FormField",
                {
                  staticClass: "side-form__item aside-input",
                  attrs: { label: "Body Text Font" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      value: _vm.options.appearance.globalFonts.bodyTextFont,
                      items: _vm.popularGoogleFontsLabels,
                      "item-text": "label",
                      "item-value": "value",
                      clearable: "",
                      attach: "",
                      "deletable-chips": "",
                      placeholder: "Use default font",
                      "append-icon": "keyboard_arrow_down",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateGoogleFont($event, "body")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.options.header
        ? _c(
            "CollapsableBlock",
            {
              staticClass: "side-form__section",
              attrs: { expanded: false, title: "Header" },
            },
            [
              _c(
                "div",
                { staticClass: "side-form__item" },
                [
                  _c("UiHelper", {
                    attrs: {
                      label: "How to configure global header",
                      labelClasses: "text--weight-500 text--size-xs",
                      popoverFeature: "header",
                      showCloseBtn: "",
                      videoData: {
                        src: "/video/helper-popup/header.mp4",
                        label: "Learn how to set up a global header",
                        btns: [
                          {
                            label: "Learn more",
                            to: "https://help.spreadsimple.com/en/article/setting-up-global-header-and-footer-163wwkn/#3-setting-up-and-customizing-your-global-header",
                            externalResource: true,
                            color: "secondary-blue",
                            event: {
                              type: "goToHelp",
                            },
                          },
                          {
                            label: "Watch video",
                            to: {
                              name: "help-video",
                              query: { show: "header" },
                            },
                            event: {
                              type: "goToVideo",
                            },
                          },
                        ],
                      },
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "side-form__item is-justified" }, [
                _c("div", { staticClass: "side-form__item-label" }, [
                  _vm._v("\n        Show header\n      "),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "switcher",
                    class: { selected: _vm.options.header.showHeader },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.options.header.showHeader,
                          expression: "options.header.showHeader",
                        },
                      ],
                      staticClass: "switcher__input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.options.header.showHeader)
                          ? _vm._i(_vm.options.header.showHeader, null) > -1
                          : _vm.options.header.showHeader,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.options.header.showHeader,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.options.header,
                                    "showHeader",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.options.header,
                                    "showHeader",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.options.header, "showHeader", $$c)
                            }
                          },
                          function ($event) {
                            return _vm.$trackFeature(
                              "design",
                              "showHeader",
                              _vm.options.header.showHeader
                            )
                          },
                        ],
                      },
                    }),
                    _c("span", { staticClass: "switcher__indicator" }),
                  ]
                ),
              ]),
              _vm.options.header.showHeader
                ? [
                    _c("div", { staticClass: "side-form__item is-justified" }, [
                      _c("div", { staticClass: "side-form__item-label" }, [
                        _vm._v("Compact menu"),
                      ]),
                      _c(
                        "label",
                        {
                          staticClass: "switcher",
                          class: {
                            selected: _vm.options.header.sideBarMenuByDefault,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.options.header.sideBarMenuByDefault,
                                expression:
                                  "options.header.sideBarMenuByDefault",
                              },
                            ],
                            staticClass: "switcher__input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.options.header.sideBarMenuByDefault
                              )
                                ? _vm._i(
                                    _vm.options.header.sideBarMenuByDefault,
                                    null
                                  ) > -1
                                : _vm.options.header.sideBarMenuByDefault,
                            },
                            on: {
                              change: function ($event) {
                                var $$a =
                                    _vm.options.header.sideBarMenuByDefault,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.options.header,
                                        "sideBarMenuByDefault",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.options.header,
                                        "sideBarMenuByDefault",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.options.header,
                                    "sideBarMenuByDefault",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c("span", { staticClass: "switcher__indicator" }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "side-form__item is-justified" }, [
                      _c("div", { staticClass: "side-form__item-label" }, [
                        _vm._v("Fix header on scroll"),
                      ]),
                      _c(
                        "label",
                        {
                          staticClass: "switcher",
                          class: { selected: _vm.options.header.stickyHeader },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.options.header.stickyHeader,
                                expression: "options.header.stickyHeader",
                              },
                            ],
                            staticClass: "switcher__input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.options.header.stickyHeader
                              )
                                ? _vm._i(
                                    _vm.options.header.stickyHeader,
                                    null
                                  ) > -1
                                : _vm.options.header.stickyHeader,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.options.header.stickyHeader,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.options.header,
                                        "stickyHeader",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.options.header,
                                        "stickyHeader",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.options.header,
                                    "stickyHeader",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _c("span", { staticClass: "switcher__indicator" }),
                        ]
                      ),
                    ]),
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item aside-select",
                        attrs: { label: "Header background color" },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.headerStyleOptions,
                            "item-text": "label",
                            "item-value": "value",
                            attach: "",
                            placeholder: "Choose style",
                            "menu-props": "offsetY",
                            "append-icon": "keyboard_arrow_down",
                          },
                          model: {
                            value: _vm.options.header.headerStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.options.header, "headerStyle", $$v)
                            },
                            expression: "options.header.headerStyle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "side-form__item is-justified" },
                      [
                        _c("p", { staticClass: "side-form__item-label" }, [
                          _vm._v("Header button color"),
                        ]),
                        _c("ColorPicker", {
                          staticStyle: { top: "0" },
                          attrs: {
                            value: _vm.options.header.headerButtonsColor,
                          },
                          on: {
                            input: (val) =>
                              (_vm.options.header.headerButtonsColor = val),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item",
                        attrs: { label: "Header Logo" },
                      },
                      [
                        _c("UploadImage", {
                          attrs: { buttonText: "Upload a logo" },
                          model: {
                            value: _vm.options.header.headerLogoImage,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.options.header,
                                "headerLogoImage",
                                $$v
                              )
                            },
                            expression: "options.header.headerLogoImage",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item aside-input",
                        attrs: { label: "Header tagline" },
                      },
                      [
                        _c("v-text-field", {
                          model: {
                            value: _vm.options.header.headerTagline,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.options.header,
                                "headerTagline",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "options.header.headerTagline",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "FormField",
                      { staticClass: "side-form__item aside-input" },
                      [
                        _c(
                          "div",
                          { staticClass: "content-form__switch-field" },
                          [
                            _c(
                              "div",
                              { staticClass: "content-form__switcher-wrap" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content-form__switcher-label",
                                  },
                                  [_vm._v("Link logo to external URL")]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "switcher",
                                    class: {
                                      selected:
                                        _vm.options.header.headerLogoExternalURL
                                          .enabled,
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.options.header
                                              .headerLogoExternalURL.enabled,
                                          expression:
                                            "options.header.headerLogoExternalURL.enabled",
                                        },
                                      ],
                                      staticClass: "switcher__input",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.options.header
                                            .headerLogoExternalURL.enabled
                                        )
                                          ? _vm._i(
                                              _vm.options.header
                                                .headerLogoExternalURL.enabled,
                                              null
                                            ) > -1
                                          : _vm.options.header
                                              .headerLogoExternalURL.enabled,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.options.header
                                                .headerLogoExternalURL.enabled,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.options.header
                                                    .headerLogoExternalURL,
                                                  "enabled",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.options.header
                                                    .headerLogoExternalURL,
                                                  "enabled",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.options.header
                                                .headerLogoExternalURL,
                                              "enabled",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", {
                                      staticClass: "switcher__indicator",
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.options.header.headerLogoExternalURL.enabled
                      ? _c(
                          "FormField",
                          {
                            staticClass: "side-form__item aside-input",
                            attrs: { label: "Logo external URL" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: { placeholder: "https://" },
                              model: {
                                value:
                                  _vm.options.header.headerLogoExternalURL.link,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.options.header.headerLogoExternalURL,
                                    "link",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "options.header.headerLogoExternalURL.link",
                              },
                            }),
                            !_vm.$v.options.header.headerLogoExternalURL.link
                              .required ||
                            !_vm.$v.options.header.headerLogoExternalURL.link
                              .url
                              ? _c("p", { staticClass: "form-field__error" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        !_vm.$v.options.header
                                          .headerLogoExternalURL.link.required
                                          ? _vm.$t("global.fieldIsRequired")
                                          : _vm.$t("global.notValidUrl")
                                      ) +
                                      "\n        "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.options.footer
        ? _c(
            "CollapsableBlock",
            {
              staticClass: "side-form__section",
              attrs: { expanded: false, title: "Footer" },
            },
            [
              _c("div", { staticClass: "side-form__item is-justified" }, [
                _c("p", { staticClass: "side-form__item-label" }, [
                  _vm._v("Show footer"),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "switcher",
                    class: { selected: _vm.options.footer.showFooter },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.options.footer.showFooter,
                          expression: "options.footer.showFooter",
                        },
                      ],
                      staticClass: "switcher__input",
                      attrs: { type: "checkbox", value: "tiles" },
                      domProps: {
                        checked: Array.isArray(_vm.options.footer.showFooter)
                          ? _vm._i(_vm.options.footer.showFooter, "tiles") > -1
                          : _vm.options.footer.showFooter,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.options.footer.showFooter,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "tiles",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.options.footer,
                                    "showFooter",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.options.footer,
                                    "showFooter",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.options.footer, "showFooter", $$c)
                            }
                          },
                          function ($event) {
                            return _vm.$trackFeature(
                              "design",
                              "showFooter",
                              _vm.options.footer.showFooter
                            )
                          },
                        ],
                      },
                    }),
                    _c("span", { staticClass: "switcher__indicator" }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "side-form__item is-justified" }, [
                _c("p", { staticClass: "side-form__item-label" }, [
                  _vm._v("Show social media icons"),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "switcher",
                    class: { selected: _vm.options.footer.showSocialNetworks },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.options.footer.showSocialNetworks,
                          expression: "options.footer.showSocialNetworks",
                        },
                      ],
                      staticClass: "switcher__input",
                      attrs: { type: "checkbox", value: "tiles" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.options.footer.showSocialNetworks
                        )
                          ? _vm._i(
                              _vm.options.footer.showSocialNetworks,
                              "tiles"
                            ) > -1
                          : _vm.options.footer.showSocialNetworks,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.options.footer.showSocialNetworks,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "tiles",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.options.footer,
                                    "showSocialNetworks",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.options.footer,
                                    "showSocialNetworks",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(
                                _vm.options.footer,
                                "showSocialNetworks",
                                $$c
                              )
                            }
                          },
                          function ($event) {
                            return _vm.$trackFeature(
                              "design",
                              "showSocialNetworks",
                              _vm.options.footer.showSocialNetworks
                            )
                          },
                        ],
                      },
                    }),
                    _c("span", { staticClass: "switcher__indicator" }),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "side-form__item tile-constructor__hint",
                  staticStyle: { "margin-top": "0" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://help.spreadsimple.com/en/article/adding-social-media-icons-kzg8iq/",
                      },
                    },
                    [
                      _vm._v(
                        "Help: How\n        to\n        add social media icons"
                      ),
                    ]
                  ),
                ]
              ),
              _vm.options.footer.showFooter
                ? _c(
                    "div",
                    { staticClass: "side-form__item" },
                    [
                      _c("MarkdownComponent", {
                        attrs: {
                          preview: false,
                          leftToolbar: "bold italic strikethrough link",
                        },
                        model: {
                          value: _vm.options.footer.footerText,
                          callback: function ($$v) {
                            _vm.$set(_vm.options.footer, "footerText", $$v)
                          },
                          expression: "options.footer.footerText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: { expanded: false, title: "Home page intro" },
        },
        [
          _c("div", { staticClass: "side-form__item is-justified" }, [
            _c("div", { staticClass: "side-form__item-label" }, [
              _vm._v("Show home page intro"),
            ]),
            _c(
              "label",
              {
                staticClass: "switcher",
                class: { selected: _vm.options.appearance.showHomeIntro },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.appearance.showHomeIntro,
                      expression: "options.appearance.showHomeIntro",
                    },
                  ],
                  staticClass: "switcher__input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.options.appearance.showHomeIntro)
                      ? _vm._i(_vm.options.appearance.showHomeIntro, null) > -1
                      : _vm.options.appearance.showHomeIntro,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.options.appearance.showHomeIntro,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.options.appearance,
                                "showHomeIntro",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.options.appearance,
                                "showHomeIntro",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.options.appearance, "showHomeIntro", $$c)
                        }
                      },
                      function ($event) {
                        return _vm.$trackFeature(
                          "design",
                          "showHomeIntro",
                          _vm.options.appearance.showHomeIntro
                        )
                      },
                    ],
                  },
                }),
                _c("span", { staticClass: "switcher__indicator" }),
              ]
            ),
          ]),
          _c(
            "FormField",
            {
              staticClass: "side-form__item aside-input with-colopicker",
              attrs: { label: "Page title" },
            },
            [
              _c("v-text-field", {
                model: {
                  value: _vm.$v.options.page.title.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.options.page.title,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.options.page.title.$model",
                },
              }),
              _c("ColorPicker", {
                model: {
                  value: _vm.options.page.titleColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.page, "titleColor", $$v)
                  },
                  expression: "options.page.titleColor",
                },
              }),
              !_vm.$v.options.page.title.maxLength
                ? _c("p", { staticClass: "n-form-field__error-message" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("global.inputMaxLengthError")) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "FormField",
            {
              staticClass: "side-form__item aside-input with-colopicker",
              attrs: { label: "Page subtitle" },
            },
            [
              _c("v-text-field", {
                model: {
                  value: _vm.$v.options.page.subtitle.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.options.page.subtitle,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.options.page.subtitle.$model",
                },
              }),
              _c("ColorPicker", {
                model: {
                  value: _vm.options.page.subtitleColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.page, "subtitleColor", $$v)
                  },
                  expression: "options.page.subtitleColor",
                },
              }),
              !_vm.$v.options.page.subtitle.maxLength
                ? _c("p", { staticClass: "n-form-field__error-message" }, [
                    _vm._v(
                      "\n        Max lenght of the value should be less then 300 symbols\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "FormField",
            { staticClass: "side-form__item", attrs: { label: "Logo" } },
            [
              _c("UploadImage", {
                attrs: { buttonText: "Upload a logo" },
                model: {
                  value: _vm.options.appearance.logoImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.appearance, "logoImage", $$v)
                  },
                  expression: "options.appearance.logoImage",
                },
              }),
            ],
            1
          ),
          _c(
            "FormField",
            { staticClass: "side-form__item", attrs: { label: "Cover Image" } },
            [
              _c("UploadImage", {
                attrs: { buttonText: "Upload a cover image" },
                model: {
                  value: _vm.options.appearance.coverImage,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.appearance, "coverImage", $$v)
                  },
                  expression: "options.appearance.coverImage",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "side-form__item" }, [
            _c(
              "div",
              {
                class: {
                  "side-form__item-disabled":
                    _vm.options.appearance.introKeepAspectRatio,
                },
              },
              [
                _c("CustomOptionsPicker", {
                  attrs: {
                    options: _vm.coverLayoutOptions,
                    label: "Cover layout",
                  },
                  model: {
                    value: _vm.options.appearance.coverLayout,
                    callback: function ($$v) {
                      _vm.$set(_vm.options.appearance, "coverLayout", $$v)
                    },
                    expression: "options.appearance.coverLayout",
                  },
                }),
                _vm.options.appearance.introKeepAspectRatio
                  ? _c("div", { staticClass: "side-form__item-label" }, [
                      _vm._v(
                        '\n          You can not use this option along with "Keep intro aspect ratio"\n        '
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "side-form__item is-justified" }, [
            _c("div", { staticClass: "side-form__item-label" }, [
              _vm._v("Keep intro image aspect ratio"),
            ]),
            _c(
              "label",
              {
                staticClass: "switcher",
                class: {
                  selected: _vm.options.appearance.introKeepAspectRatio,
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.options.appearance.introKeepAspectRatio,
                      expression: "options.appearance.introKeepAspectRatio",
                    },
                  ],
                  staticClass: "switcher__input",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.options.appearance.introKeepAspectRatio
                    )
                      ? _vm._i(
                          _vm.options.appearance.introKeepAspectRatio,
                          null
                        ) > -1
                      : _vm.options.appearance.introKeepAspectRatio,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.options.appearance.introKeepAspectRatio,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.options.appearance,
                              "introKeepAspectRatio",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.options.appearance,
                              "introKeepAspectRatio",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.options.appearance,
                          "introKeepAspectRatio",
                          $$c
                        )
                      }
                    },
                  },
                }),
                _c("span", { staticClass: "switcher__indicator" }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "CollapsableBlock",
        {
          staticClass: "side-form__section",
          attrs: { expanded: false, title: "Home page content" },
        },
        [
          _c(
            "div",
            { staticClass: "side-form__item" },
            [
              _c("UiHelper", {
                attrs: {
                  label: "How to configure home page content",
                  labelClasses: "text--weight-500 text--size-xs",
                  popoverFeature: "header",
                  showCloseBtn: "",
                  videoData: {
                    src: "/video/helper-popup/home-page-content.mp4",
                    label: "Learn how to add more text to your home page",
                    btns: [
                      {
                        label: "Learn more",
                        to: "https://help.spreadsimple.com/en/article/how-to-customize-your-design-duvqd2/#3-add-some-text",
                        externalResource: true,
                        color: "secondary-blue",
                        event: {
                          type: "goToHelp",
                        },
                      },
                      {
                        label: "Watch video",
                        to: {
                          name: "help-video",
                          query: { show: "home-page-content" },
                        },
                        event: {
                          type: "goToVideo",
                        },
                      },
                    ],
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "FormField",
            { staticClass: "side-form__item", attrs: { label: "Description" } },
            [
              _c("MarkdownComponent", {
                attrs: {
                  preview: false,
                  leftToolbar: "bold italic strikethrough link",
                },
                model: {
                  value: _vm.options.page.contentBeforeDataMd,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.page, "contentBeforeDataMd", $$v)
                  },
                  expression: "options.page.contentBeforeDataMd",
                },
              }),
            ],
            1
          ),
          _c(
            "FormField",
            {
              staticClass: "side-form__item",
              attrs: { label: "Text after cards" },
            },
            [
              _c("MarkdownComponent", {
                attrs: {
                  preview: false,
                  leftToolbar: "bold italic strikethrough link",
                },
                model: {
                  value: _vm.options.page.contentAfterDataMd,
                  callback: function ($$v) {
                    _vm.$set(_vm.options.page, "contentAfterDataMd", $$v)
                  },
                  expression: "options.page.contentAfterDataMd",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.options.header
        ? _c(
            "CollapsableBlock",
            { staticClass: "side-form__section", attrs: { expanded: false } },
            [
              _c("template", { slot: "header" }, [
                _c("span", { staticClass: "side-form__section-title" }, [
                  _vm._v("Mobile Bottom Menu"),
                ]),
              ]),
              _c("div", { staticClass: "side-form__item is-justified" }, [
                _c("div", { staticClass: "side-form__item-label" }, [
                  _vm._v("Enabled"),
                ]),
                _c(
                  "label",
                  {
                    staticClass: "switcher",
                    class: {
                      selected:
                        _vm.options.header.mobileBottomNavigationEnabled,
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.options.header.mobileBottomNavigationEnabled,
                          expression:
                            "options.header.mobileBottomNavigationEnabled",
                        },
                      ],
                      staticClass: "switcher__input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(
                          _vm.options.header.mobileBottomNavigationEnabled
                        )
                          ? _vm._i(
                              _vm.options.header.mobileBottomNavigationEnabled,
                              null
                            ) > -1
                          : _vm.options.header.mobileBottomNavigationEnabled,
                      },
                      on: {
                        change: function ($event) {
                          var $$a =
                              _vm.options.header.mobileBottomNavigationEnabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.options.header,
                                  "mobileBottomNavigationEnabled",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.options.header,
                                  "mobileBottomNavigationEnabled",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.options.header,
                              "mobileBottomNavigationEnabled",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _c("span", { staticClass: "switcher__indicator" }),
                  ]
                ),
              ]),
              _vm.options.header.mobileBottomNavigationEnabled
                ? [
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item aside-input",
                        attrs: { label: "Mobile menu items" },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            value:
                              _vm.options.header.mobileBottomNavigationItems,
                            items: _vm.menuItemsOptions,
                            "item-text": "label",
                            "item-value": "value",
                            chips: "",
                            attach: "",
                            multiple: "",
                            "deletable-chips": "",
                            "append-icon": "keyboard_arrow_down",
                          },
                          on: { input: _vm.updateMobileMenuItems },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "DraggableChip",
                                      {
                                        attrs: { id: data.index },
                                        model: {
                                          value:
                                            _vm.options.header
                                              .mobileBottomNavigationItems,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.options.header,
                                              "mobileBottomNavigationItems",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "options.header.mobileBottomNavigationItems",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(data.item.label) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4273445282
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "l",
            "attr-type": "submit",
            loading: _vm.loading,
            disabled: _vm.loading || _vm.spreadViewerOptionsInvalid,
          },
        },
        [_vm._v("\n    SAVE\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "side-form__item is-justified" }, [
      _c("div", { staticClass: "side-form__item-label" }, [
        _vm._v("Сhoose global Google Fonts for your website"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }