import { render, staticRenderFns } from "./WorkspaceDropdown.vue?vue&type=template&id=564b8520"
import script from "./WorkspaceDropdown.vue?vue&type=script&lang=js"
export * from "./WorkspaceDropdown.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('564b8520')) {
      api.createRecord('564b8520', component.options)
    } else {
      api.reload('564b8520', component.options)
    }
    module.hot.accept("./WorkspaceDropdown.vue?vue&type=template&id=564b8520", function () {
      api.rerender('564b8520', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workspace-dropdown/WorkspaceDropdown.vue"
export default component.exports