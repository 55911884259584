<template>
  <div class="tile-constructor">
    <div class="tile-constructor__form-fields">
      <div
        v-for="field in tileFields"
        :key="field.name"
        class="tile-constructor__field aside-select"
        >
        <h6 class="aside-field-label">
          {{ field.label }}
          <UiHelper
            v-if="field.helper"
            class="va-bottom ml-2"
            :popoverFeature="field.helper.name"
            showCloseBtn
            :videoData="{
              src: `/video/helper-popup/${field.helper.name}.mp4`,
              label: field.helper.string,
              btns: [
                {
                  label: 'Learn more',
                  to: field.helper.blogUrl,
                  externalResource: true,
                  color: 'secondary-blue',
                  event: {
                    type: 'goToHelp'
                  }
                },
                {
                  label: 'Watch video',
                  to: { name: 'help-video', query: { show: field.helper.name }},
                  event: {
                    type: 'goToVideo'
                  }
                }
              ]
            }"
          />
        </h6>
        <v-select
          :value="tileOptions[field.name][field.multiple ? 'ids' : 'id']"
          :items="cols"
          item-text="label"
          item-value="id"
          attach
          placeholder="Choose a column"
          menu-props="offsetY"
          :clearable="field.clearable"
          :chips="field.multiple"
          :deletable-chips="field.multiple"
          :multiple="field.multiple"
          append-icon="keyboard_arrow_down"
          @input="val => updateTileOptionValue(val, field.name, field.multiple)"
          >
          <template slot="selection" slot-scope="data">
            <DraggableChip
              v-if="field.multiple"
              :id="data.index"
              :value="tileOptions[field.name][field.multiple ? 'ids' : 'id']"
              @input="val => updateTileOptionValue(val, field.name, field.multiple)"
            >
              {{ data.item.label }}
            </DraggableChip>
            <div v-else class="v-select__selection--comma">{{ data.item.label }}</div>
          </template>
          <div slot="prepend-item" class="list-title"> {{ field.dropdownLabel }}</div>
        </v-select>
        <i class="aside-select__field-icon icon-arrow-down"></i>
        <p
          v-if="!!v[field.name] && !v[field.name].id.required"
          class="form-field__error"
        >
          {{ $t('global.fieldIsRequired') }}
        </p>
        <template field.hint>
          <div class="tile-constructor__hint" v-html="field.hint"></div>
        </template>
        <div
          v-if="field.name === 'image' && tileOptions.image.keepAspectRatio !== undefined"
          class="content-form__switch-field"
          style="margin-top: 20px;"
        >
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Keep image aspect ratio</div>
              <label
                :class="{ 'selected': tileOptions.image.keepAspectRatio }"
                class="switcher"
              >
                <input v-model="tileOptions.image.keepAspectRatio" class="switcher__input" type="checkbox">
                <span class="switcher__indicator"></span>
              </label>
          </div>
        </div>
      </div>
      <CtaButtonConstructor
        v-model="tileOptions.cta"
        class="tile-constructor__field aside-select"
        widgetLabel="Primary button settings"
        :cols="cols"
        :enableSingleItemView="enableSingleItemView"
        :msg="msg"
        @change-tab="$emit('change-tab', 'checkout-options')"
      />
      <CtaButtonConstructor
        v-model="tileOptions.cta_second"
        class="tile-constructor__field aside-select"
        widgetLabel="Secondary button settings"
        :cols="cols"
        :enableSingleItemView="enableSingleItemView"
        :msg="msg"
        @change-tab="$emit('change-tab', 'checkout-options')"
      />
      <div class="tile-constructor__field">
        <CustomOptionsPicker
          v-model="cardStyle"
          label="Select card style"
          :options="[
            { value: 'tile', label: 'Tile view', icon: 'icon-tiles-view2' },
            { value: 'list', label: 'List view', icon: 'icon-list-view2' },
            { value: 'horizontal', label: 'Horizontal view', icon: 'icon-horizontal-view' },
            { value: 'table', label: 'Table view', icon: 'icon-table-view-style' }
          ]"
        />
      </div>
      <CustomOptionsPicker
        v-if="cardStyle !== 'table'"
        v-model="tileOptions.tilesSize.type"
        label="Select card size"
        :options="getCardSizeOptions(tileOptions.cardStyle.value)"
      />
      <div v-if="tileOptions.cardStyle.value === 'table'" class="content-form__switch-field" style="margin-top: 20px;">
        <div class="content-form__switcher-wrap">
          <div class="content-form__switcher-label">Scrollable table rows</div>
            <label
              :class="{ 'selected': tileOptions.isTableScrollable }"
              class="switcher"
            >
              <input v-model="tileOptions.isTableScrollable" class="switcher__input" type="checkbox">
              <span class="switcher__indicator"></span>
            </label>
        </div>
      </div>
      <div v-if="tileOptions.cardStyle.value === 'tile'" class="content-form__switch-field" style="margin-top: 20px;">
        <div class="content-form__switcher-wrap">
          <div class="content-form__switcher-label">Don't force tiles vertical distribution</div>
            <label
              :class="{ 'selected': tileOptions.doNotStretchContent }"
              class="switcher"
            >
              <input v-model="tileOptions.doNotStretchContent" class="switcher__input" type="checkbox">
              <span class="switcher__indicator"></span>
            </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CtaButtonConstructor from '@/components/cta-button-constructor'
import CustomOptionsPicker from '@/components/custom-options-picker/'
import DraggableChip from '@/components/draggable-chip/DraggableChip.vue'
import UiHelper from '@/components/ui-helper'
export default {
  name: 'TilesConstructor',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DraggableChip,
    CtaButtonConstructor,
    CustomOptionsPicker,
    UiHelper
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    v: {
      type: Object
    },
    msg: {
      type: Object,
      required: true
    },
    enableSingleItemView: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      tileOptions: {},
      tileFields: [
        {
          name: 'title',
          label: 'Title',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'subtitle',
          label: 'Subtitle',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'price',
          label: 'Price',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'oldPrice',
          label: 'Old price',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'content',
          label: 'Text',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        },
        {
          name: 'image',
          label: 'Image',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false,
          hint: `<a target="_blank" href="https://help.spreadsimple.com/en/article/adding-images-1k2y9tj/">Help: Adding images</a>`,
          helper: {
            name: 'adding-images',
            string: 'Learn how to add images',
            blogUrl: 'https://help.spreadsimple.com/en/article/adding-images-1k2y9tj/'
          }
        },
        {
          name: 'specs',
          label: 'Specifications',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false
        },
        {
          name: 'badges',
          label: 'Badges',
          dropdownLabel: 'Select sheet column',
          multiple: true,
          clearable: false,
          helper: {
            name: 'badges',
            string: 'Learn how to set up and customize badges',
            blogUrl: 'https://help.spreadsimple.com/en/article/adding-badges-1xf834/'
          }
        },
        {
          name: 'sku',
          label: 'SKU',
          dropdownLabel: 'Select sheet column',
          multiple: false,
          clearable: true
        }
      ]
    }
  },
  computed: {
    cardStyle: {
      get () {
        return this.tileOptions.cardStyle.value
      },
      set (val) {
        const sizes = this.getCardSizeOptions(val)
        if (!sizes.map(s => s.value).includes(this.tileOptions.tilesSize.type)) {
          this.tileOptions.tilesSize.type = sizes[0].value
        }
        this.tileOptions.cardStyle.value = val
      }
    }
  },
  watch: {
    tileOptions: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateTileOptionValue (val, name, multiple) {
      const value = (val === undefined) ? null : val
      this.tileOptions[name][multiple ? 'ids' : 'id'] = value
    },
    getCardSizeOptions (style) {
      if (style === 'tile') {
        return [{ value: 'small', label: 'Small', icon: 'icon-card-small' }, { value: 'large', label: 'Large', icon: 'icon-card-large' }]
      } else if (style === 'list') {
        return [{ value: 'small', label: 'Small', icon: 'icon-list-view-small' }, { value: 'large', label: 'Large', icon: 'icon-list-view2' }]
      } else {
        return [{ value: 'small', label: 'Small', icon: 'icon-horizontal-view' }]
      }
    }

  },
  created () {
    let obj = JSON.parse(JSON.stringify(this.value))
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object' && !obj[key].ids && obj[key].id === undefined) {
        obj[key].id = ''
      }
    })
    this.tileOptions = obj

    if (this.tileOptions.tilesWidth) {
      this.tileOptions.tilesSize.type = 'large'
    }
  }
}
</script>
