var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "template-preview-modal",
      attrs: {
        "has-close-button": false,
        maxWidth: "1440px",
        fullscreen: "",
        closeOnPressEscBtn: "",
      },
      model: {
        value: _vm.modalOpened,
        callback: function ($$v) {
          _vm.modalOpened = $$v
        },
        expression: "modalOpened",
      },
    },
    [
      _c(
        "div",
        { staticClass: "template-preview" },
        [
          _c("div", { staticClass: "template-preview__header" }, [
            _c("div", { staticClass: "template-preview__header-container" }, [
              _c(
                "a",
                {
                  staticClass: "template-preview__header-logo",
                  attrs: { href: "/" },
                },
                [
                  _c("img", {
                    staticClass: "template-preview__header-logo-img",
                    attrs: {
                      src: "/img/logo-main.svg",
                      alt: "spreadsimple",
                      width: "153",
                      height: "32",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticClass: "template-preview__header-right" },
                [
                  _c(
                    "div",
                    { staticClass: "template-preview__header-view-switcher" },
                    _vm._l(_vm.previewTypes, function (item) {
                      return _c(
                        "label",
                        {
                          key: item.value,
                          staticClass: "template-preview__header-radio",
                          class: {
                            "is-active": _vm.previewType === item.value,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.previewType,
                                expression: "previewType",
                              },
                            ],
                            attrs: { type: "radio" },
                            domProps: {
                              value: item.value,
                              checked: _vm._q(_vm.previewType, item.value),
                            },
                            on: {
                              change: function ($event) {
                                _vm.previewType = item.value
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "template-preview__header-radio-icon",
                            class: `icon-${item.icon}`,
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "UiBtn",
                    { attrs: { size: "s" }, on: { click: _vm.onStartClick } },
                    [_vm._v("START WITH THIS TEMPLATE")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.template
            ? _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _vm.previewType === "desktop"
                  ? _c(
                      "div",
                      { key: "1", staticClass: "template-preview__desktop" },
                      [
                        _c("iframe", {
                          staticClass: "template-preview__iframe",
                          attrs: {
                            src: _vm.template.previewLink,
                            frameborder: "0",
                            "data-openreplay-capture": "",
                          },
                        }),
                      ]
                    )
                  : _c(
                      "div",
                      { key: "2", staticClass: "template-preview__phone-wrap" },
                      [
                        _c("div", { staticClass: "template-preview__phone" }, [
                          _c("iframe", {
                            staticClass: "template-preview__phone-iframe",
                            attrs: {
                              src: _vm.template.previewLink,
                              frameborder: "0",
                            },
                          }),
                        ]),
                      ]
                    ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }