<template>
  <div class="collapsable-block">
    <div
      class="collapsable-block__head"
      @click="toggleCollapsable"
    >
      <div class="collapsable-block__title-wrap">
        <span class="collapsable-block__title">
          <slot name="header">{{ title }}</slot>
        </span>
        <UiIcon
          v-if="locked"
          icon="mf-lock"
          class="collapsable-block__lock-icon"
        />
      </div>
      <i
        v-if="!disableCollapsing"
        class="icon-carret-down collapsable-block__head-icon"
        :class="{'is-flipped': opened}"
      ></i>
    </div>
    <div class="collapsable-block__body" :class="{'is-active': opened}">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import UiIcon from '@/components/ui-icon/UiIcon.vue'
export default {
  name: 'CollapsableBlock',
  components: {
    UiIcon
  },
  props: {
    expanded: {
      type: Boolean,
      default: false
    },
    disableCollapsing: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    locked: {
      type: Boolean,
      default: false
    },
    externalControl: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      opened: false
    }
  },
  watch: {
    'expanded': function (val, oldVal) {
      this.opened = val
    }
  },
  methods: {
    toggleCollapsable () {
      if (this.disableCollapsing) return
      if (this.externalControl) {
        this.$emit('toggle-collapsable-block')
        return
      }
      this.opened = !this.opened
    }
  },
  created () {
    this.opened = this.expanded
  }
}
</script>
