var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        modalName: "Code editor",
        hasCloseButton: true,
        closeOnClickOutside: false,
        maxWidth: "850px",
      },
      model: {
        value: _vm.modalOpened,
        callback: function ($$v) {
          _vm.modalOpened = $$v
        },
        expression: "modalOpened",
      },
    },
    [
      _c(
        "div",
        { staticClass: "code-editor-form" },
        [
          _c(
            "FormField",
            {
              staticClass: "code-editor-form__item",
              attrs: { label: "Inject custom code" },
            },
            [
              _c("CodeTextArea", {
                model: {
                  value: _vm.codeString,
                  callback: function ($$v) {
                    _vm.codeString = $$v
                  },
                  expression: "codeString",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "code-editor-form__btns" },
            [
              _c("UiBtn", {
                attrs: { type: "secondary", label: "Cancel" },
                on: { click: _vm.onCancelClick },
              }),
              _c("UiBtn", {
                attrs: { label: "Save" },
                on: { click: _vm.onSaveClick },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }