var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collapsable-block" }, [
    _c(
      "div",
      {
        staticClass: "collapsable-block__head",
        on: { click: _vm.toggleCollapsable },
      },
      [
        _c(
          "div",
          { staticClass: "collapsable-block__title-wrap" },
          [
            _c(
              "span",
              { staticClass: "collapsable-block__title" },
              [
                _vm._t("header", function () {
                  return [_vm._v(_vm._s(_vm.title))]
                }),
              ],
              2
            ),
            _vm.locked
              ? _c("UiIcon", {
                  staticClass: "collapsable-block__lock-icon",
                  attrs: { icon: "mf-lock" },
                })
              : _vm._e(),
          ],
          1
        ),
        !_vm.disableCollapsing
          ? _c("i", {
              staticClass: "icon-carret-down collapsable-block__head-icon",
              class: { "is-flipped": _vm.opened },
            })
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "collapsable-block__body",
        class: { "is-active": _vm.opened },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }