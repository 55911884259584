var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "side-form" },
    [
      _vm.value.length
        ? _c(
            "div",
            { staticClass: "side-form__item side-form__draggable-wrap" },
            [
              _c(
                "draggable",
                {
                  staticClass: "draggable",
                  attrs: {
                    value: _vm.value,
                    tag: "div",
                    handle: ".draggable__item-trigger",
                  },
                  on: { input: (val) => _vm.onDrag(val) },
                },
                _vm._l(_vm.value, function (field, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "draggable__item" },
                    [
                      _c("div", {
                        staticClass: "draggable__item-trigger icon-draggable",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "draggable__item-title-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(field, index)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "draggable__item-title" }, [
                            _vm._v(_vm._s(field.label)),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "draggable__item-controls" },
                        [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "draggable__item-control",
                              attrs: { trigger: "click" },
                            },
                            [
                              _c("i", { staticClass: "icon-gear" }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "draggable__dropdown-menu",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.onEdit(field, index)
                                        },
                                      },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.onDelete(index)
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "side-form__btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--transparent",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.builderModalOpened = true
              },
            },
          },
          [_vm._v(_vm._s(_vm.addButtonTitle))]
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            closeOnClickOutside: false,
            modalName: _vm.modalTitle,
            hasCloseButton: true,
          },
          model: {
            value: _vm.builderModalOpened,
            callback: function ($$v) {
              _vm.builderModalOpened = $$v
            },
            expression: "builderModalOpened",
          },
        },
        [
          _c("OptionItemBuilder", {
            attrs: { value: _vm.currentEditingField, schema: _vm.schema },
            on: {
              input: _vm.onAddField,
              "on-edit": _vm.onUpdateField,
              "cancel-submission": function ($event) {
                _vm.builderModalOpened = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }