export const samples = [
  {
    templateName: 'handymen',
    version: 'v2',
    name: 'Handymen Directory',
    link: 'https://handymen.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1UPXlT2LFKrDQ0WWhQ6_bpTQ1SMC4Tfh0gzLdNwT23qY/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1UPXlT2LFKrDQ0WWhQ6_bpTQ1SMC4Tfh0gzLdNwT23qY/template/preview',
    imageUrl: '/img/sheet-samples/handymen.jpg',
    categories: ['Services'],
    features: ['Search, Filters & Sorting', 'Customized Order Form', 'Content Pages', 'Product Details Pages']
  },
  {
    templateName: 'tire-store',
    version: 'v2',
    name: 'Tire Store',
    link: 'https://tire-store.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1YOw-tIJgHj8CU2Ou55NqQz33nWRMA2whpD1ZyTSeyUs/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1YOw-tIJgHj8CU2Ou55NqQz33nWRMA2whpD1ZyTSeyUs/template/preview',
    imageUrl: '/img/sheet-samples/tire-store.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting', 'Shopping cart and Checkout', 'Content Pages', 'Product Details Pages', 'Item variations']
  },
  {
    templateName: 'ted-podcasts',
    version: 'v2',
    name: 'TED Podcasts',
    link: 'https://ted-podcasts.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1zsbYnGUDEa9KOuvWK-PWfmwF-KeIdoK8AQvv_xuRO6M/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1zsbYnGUDEa9KOuvWK-PWfmwF-KeIdoK8AQvv_xuRO6M/template/preview',
    imageUrl: '/img/sheet-samples/ted-podcasts.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting', 'Content Pages', 'Product Details Pages']
  },
  {
    templateName: 'recipe-collection',
    version: 'v2',
    name: 'Recipe Collection',
    link: 'https://recipe-collection.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1fvkvTcOPhRs1Ts2ckq7L4Qm5vx8HkS6B_9GzFwa9mC4/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1fvkvTcOPhRs1Ts2ckq7L4Qm5vx8HkS6B_9GzFwa9mC4/template/preview',
    imageUrl: '/img/sheet-samples/recipe-collection.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting', 'Content Pages', 'Product Details Pages']
  },
  {
    templateName: 'ai-startups',
    version: 'v2',
    name: 'AI Startups',
    link: 'https://ai-startups.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1g_3eqbotjP8dtqox-_YJfWDpOfqWMAWLtqVtEc2KNYA/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1g_3eqbotjP8dtqox-_YJfWDpOfqWMAWLtqVtEc2KNYA/template/preview',
    imageUrl: '/img/sheet-samples/ai-startups.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'law-firm',
    version: 'v2',
    name: 'Law Firm',
    link: 'https://law-firm.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1ik9-9LQqlUk5Axcj0AObYRmsWZyBwyXzuW10DGGR6rc/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1ik9-9LQqlUk5Axcj0AObYRmsWZyBwyXzuW10DGGR6rc/template/preview',
    imageUrl: '/img/sheet-samples/law-firm.jpg',
    categories: ['Services'],
    features: ['Search, Filters & Sorting',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'bakery-store',
    version: 'v2',
    name: 'Bakery Store',
    link: 'https://boulangerie.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1mYvgtafEEq_8rZXaX81orhdmnYJKw5kqC9twGsTpRfo/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1mYvgtafEEq_8rZXaX81orhdmnYJKw5kqC9twGsTpRfo/template/preview',
    imageUrl: '/img/sheet-samples/bakery-store.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'florist',
    version: 'v2',
    name: 'Flower Shop',
    link: 'https://florist.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1EngWepODvk-902GFSJEWE52wetbqzy85fwtsXzamPkM/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1EngWepODvk-902GFSJEWE52wetbqzy85fwtsXzamPkM/template/preview',
    imageUrl: '/img/sheet-samples/florist.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'car-dealer',
    version: 'v2',
    name: 'Car Dealer',
    link: 'https://car-dealer.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1XyGjxZmMkxoaKPpojVmFQRa0ewSEnsGc_6BIlOXHCsw/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1XyGjxZmMkxoaKPpojVmFQRa0ewSEnsGc_6BIlOXHCsw/template/preview',
    imageUrl: '/img/sheet-samples/car-dealer.jpg',
    categories: ['Services'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Shopping cart and Checkout',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'grocery-store',
    version: 'v2',
    name: 'Grocery Store',
    link: 'https://grocery-store.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1u0hUr8VhGIuiX0nzT6vJ48Wc_PXiemxo-WR9K2k_MQ4/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1u0hUr8VhGIuiX0nzT6vJ48Wc_PXiemxo-WR9K2k_MQ4/template/preview',
    imageUrl: '/img/sheet-samples/grocery-store.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Shopping cart and Checkout',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages']
  },
  {
    templateName: 'templates-store',
    version: 'v2',
    name: 'Templates Store',
    link: 'https://templates-store.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1NCNARy81iyICl0OgUUlW4Vjz3YQL_2CCYoUa9IhJlS8/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1NCNARy81iyICl0OgUUlW4Vjz3YQL_2CCYoUa9IhJlS8/template/preview',
    imageUrl: '/img/sheet-samples/templates-store.jpg',
    categories: ['Digital Downloads'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Shopping cart and Checkout',
      'Content Pages',
      'Product Details Pages',
      'Digital Downloads (Secret Columns)',
      'Email Subscription Add-on']
  },
  {
    templateName: 'travel-agency',
    version: 'v2',
    name: 'Travel Agency',
    link: 'https://travel-agency.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1JK9KfgmfPOyoeWYZt3M8UrHE0K5HJoFX4ZrtALrc5sE/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1JK9KfgmfPOyoeWYZt3M8UrHE0K5HJoFX4ZrtALrc5sE/template/preview',
    imageUrl: '/img/sheet-samples/travel-agency.jpg',
    categories: ['Services'],
    features: ['Search, Filters & Sorting',
      'Map View',
      'Customized Order Form',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages',
      'Item Variations',
      'Email Subscription Add-on']
  },
  {
    templateName: 'community-center-schedule',
    version: 'v2',
    name: 'Community Center Schedule',
    link: 'https://community-center-schedule.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1LfVQd0RHuF0PiHU0jD9PHD-ZvPFJz3ou5pdwBDGXuEc/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1LfVQd0RHuF0PiHU0jD9PHD-ZvPFJz3ou5pdwBDGXuEc/template/preview',
    imageUrl: '/img/sheet-samples/community-center-schedule.jpg',
    categories: ['Catalog/Directory'],
    features: [
      'Customized Order Form',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages',
      'Item Variations',
      'Email Subscription Add-on']
  },
  {
    templateName: 'video-catalog',
    version: 'v2',
    name: 'Video Catalog',
    link: 'https://video-catalog.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1WZZgBPOQJedKWoUah4OCQlmgPp0OWr7qz_qEQmFZw8c/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1WZZgBPOQJedKWoUah4OCQlmgPp0OWr7qz_qEQmFZw8c/template/preview',
    imageUrl: '/img/sheet-samples/video-catalog.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Content Pages',
      'Product Details Pages',
      'Disqus Add-on']
  },
  {
    templateName: 'dental-clinics',
    version: 'v2',
    name: 'Dental Clinics Directory',
    link: 'https://dental-clinics-directory.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1iOXm6myoVg1sc8R5_bLtupxpDV9JuIjZap28NdIGUog/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1iOXm6myoVg1sc8R5_bLtupxpDV9JuIjZap28NdIGUog/template/preview',
    imageUrl: '/img/sheet-samples/dental-clinics.jpg',
    categories: ['Catalog/Directory'],
    features: ['Map View',
      'Content Pages',
      'Product Details Pages',
      'Disqus Add-on',
      'Email Subscription Add-on']
  },
  {
    templateName: 'clothes-store',
    version: 'v2',
    name: 'Apparel Store',
    link: 'https://clothes-store.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/u/4/d/1anPNuqP9WOprYg2CFlwbnjJASM-x1nPN6vGn4kgpztQ/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/u/4/d/1anPNuqP9WOprYg2CFlwbnjJASM-x1nPN6vGn4kgpztQ/template/preview',
    imageUrl: '/img/sheet-samples/clothes-store.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages',
      'Item Variations']
  },
  {
    templateName: 'eco-store',
    version: 'v2',
    name: 'Eco Store',
    link: 'https://eco-store-v2.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/u/4/d/1VC9sEy3sz7jXl6-EeMbpzR9u-KH8C2UxhmzTtaNVjoo/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/u/4/d/1VC9sEy3sz7jXl6-EeMbpzR9u-KH8C2UxhmzTtaNVjoo/template/preview',
    imageUrl: '/img/sheet-samples/eco-store.png',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages',
      'Item Variations']
  },
  {
    templateName: 'food-delivery',
    version: 'v2',
    name: 'Food Delivery + WhatsApp',
    link: 'https://food-delivery.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1USX1hO682-p0BdVCO0fwkrsxnD2T_iB8GisziIerEis/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1USX1hO682-p0BdVCO0fwkrsxnD2T_iB8GisziIerEis/template/preview',
    imageUrl: '/img/sheet-samples/food-delivery.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout',
      'Category Pages (URL Aliases)',
      'Content Pages',
      'Product Details Pages',
      'Item Variations',
      'WhatsApp Add-on']
  },
  {
    templateName: 'tourist-guide',
    version: 'v2',
    name: 'Tourist Guide',
    link: 'https://tourist-guide.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1ZUldamsImfZG0uplhTWUWZwIv9ySC9wVoXf4RaE7nyc/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1ZUldamsImfZG0uplhTWUWZwIv9ySC9wVoXf4RaE7nyc/template/preview',
    imageUrl: '/img/sheet-samples/tourist-guide.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting',
      'Map View',
      'Highlighted Items',
      'Content Pages',
      'Product Details Pages',
      'Email Subscription Add-on']
  },
  {
    templateName: 'marketing-books',
    version: 'v2',
    name: 'Marketing Books',
    link: 'https://marketing-books.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1aAXUSRCQpPhkJzylkeDlpbLiZbsyG58zv_40PfEzIVA/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1aAXUSRCQpPhkJzylkeDlpbLiZbsyG58zv_40PfEzIVA/template/preview',
    imageUrl: '/img/sheet-samples/marketing-books.jpg',
    categories: ['Affiliate'],
    features: ['Password Protection',
      'Search, Filters & Sorting',
      'Content Pages',
      'Product Details Pages',
      'Email Subscription Add-on']
  },
  {
    templateName: 'aircc',
    version: 'v1',
    name: 'Real Estate',
    link: 'https://aircc.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/14PgA97Gy-2kEQ2sDe4RcnpT4J8p29aqpU5SC2-14uB8',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/14PgA97Gy-2kEQ2sDe4RcnpT4J8p29aqpU5SC2-14uB8/template/preview',
    imageUrl: '/img/sheet-samples/aircc.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Product Details Pages']
  },
  {
    templateName: 'sushi',
    version: 'v1',
    name: 'Sushi Restaurant',
    link: 'https://sushi.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1pNMoH4OU-co9CQT0Mua5qbZYM6BV8vPLSvjx17tb42c',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1pNMoH4OU-co9CQT0Mua5qbZYM6BV8vPLSvjx17tb42c/template/preview',
    imageUrl: '/img/sheet-samples/sushi.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout']
  },
  {
    templateName: 'furniture',
    version: 'v1',
    name: 'Furniture Store',
    link: 'https://furniture2.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1jQwwmTkXEEKBBfBQDxnvsi-A00GMnsy8n1qjDVsXDEY',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1jQwwmTkXEEKBBfBQDxnvsi-A00GMnsy8n1qjDVsXDEY/template/preview',
    imageUrl: '/img/sheet-samples/furniture.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting',
      'Shopping cart and Checkout']
  },
  {
    templateName: 'oculus-amazon',
    version: 'v1',
    name: 'Oculus Products',
    link: 'https://oculus-amazon.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1MC0GtvNOUmuGTRMJXK6OhAZGF6f-QDEuC3xYs9h5ayM',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1MC0GtvNOUmuGTRMJXK6OhAZGF6f-QDEuC3xYs9h5ayM/template/preview',
    imageUrl: '/img/sheet-samples/oculus.jpg',
    categories: ['Affiliate'],
    features: ['Search, Filters & Sorting']
  },
  {
    templateName: 'emoji-tees',
    version: 'v1',
    name: 'Emoji Tees',
    link: 'https://emoji-tees.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/13CciNP_LA19OFFJwYMa44vrBMH3khvdv_qiyAk4_Euo',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/13CciNP_LA19OFFJwYMa44vrBMH3khvdv_qiyAk4_Euo/template/preview',
    imageUrl: '/img/sheet-samples/emoji-tees.jpg',
    categories: ['E-commerce'],
    features: ['Search, Filters & Sorting']
  },
  {
    templateName: 'job-board',
    version: 'v1',
    name: 'Job Board',
    link: 'https://jobboard.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/12N1ARYJlKBJ0NKBuZyEHrUA4d_JuHH5goDZk14o7Abo',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/12N1ARYJlKBJ0NKBuZyEHrUA4d_JuHH5goDZk14o7Abo/template/preview',
    imageUrl: '/img/sheet-samples/job-board.jpg',
    categories: ['Catalog/Directory'],
    features: ['Search, Filters & Sorting',
      'Highlighted Items',
      'Product Details Pages'
    ]
  },
  {
    templateName: 'online-courses',
    version: 'v2',
    name: 'Online Courses',
    link: 'https://online-courses.spread.name/',
    googleDocs: 'https://docs.google.com/spreadsheets/d/1CVwnra_WRHrifYL51f0u5eOOSPLInOh5gZKdxxwyhno/edit#gid=0',
    copyGoogleDocs: 'https://docs.google.com/spreadsheets/d/1CVwnra_WRHrifYL51f0u5eOOSPLInOh5gZKdxxwyhno/template/preview',
    imageUrl: '/img/sheet-samples/online-courses.jpg',
    categories: ['Catalog/Directory'],
    features: ['Content Pages', 'Product Details Pages']
  }
]
