var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "content-form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _vm.validationStatus !== ""
          ? _c("p", { staticClass: "aside-message" }, [
              _vm._v("\n      " + _vm._s(_vm.validationStatus) + "\n    "),
            ])
          : _vm._e(),
        _c("div", { staticClass: "content-form__content-tabs" }, [
          _c(
            "div",
            { staticClass: "content-form__content-tabs__header" },
            _vm._l(_vm.contentTabs, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "content-form__content-tabs__header-title",
                  class: { active: _vm.currentTabIndex === index },
                  on: {
                    click: function ($event) {
                      _vm.currentTabIndex = index
                    },
                  },
                },
                [
                  _vm._v("\n          " + _vm._s(item.title) + "\n          "),
                  item.invalid
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "content-form__content-tabs__header-title-invalid-icon",
                        },
                        [_vm._v("!")]
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "content-form__content-tabs__body" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentTabIndex === 0,
                    expression: "currentTabIndex === 0",
                  },
                ],
                staticClass: "content-form__content-tab",
              },
              [
                _c(
                  "div",
                  { staticClass: "content-form__tiles-constructor-wrap" },
                  [
                    _c("TilesConstructor", {
                      attrs: {
                        cols: _vm.cols,
                        enableSingleItemView:
                          _vm.options.viewer.enableSingleItemView,
                        v: _vm.$v.options.viewer.tileOptions,
                        msg: _vm.msg,
                      },
                      on: {
                        "change-tab": function ($event) {
                          return _vm.$emit("change-tab", "checkout-options")
                        },
                      },
                      model: {
                        value: _vm.options.viewer.tileOptions,
                        callback: function ($$v) {
                          _vm.$set(_vm.options.viewer, "tileOptions", $$v)
                        },
                        expression: "options.viewer.tileOptions",
                      },
                    }),
                  ],
                  1
                ),
                _c("MapViewConstructor", {
                  attrs: {
                    cols: _vm.cols,
                    integrationsData: _vm.integrationsData,
                  },
                  model: {
                    value: _vm.options.viewer.mapView,
                    callback: function ($$v) {
                      _vm.$set(_vm.options.viewer, "mapView", $$v)
                    },
                    expression: "options.viewer.mapView",
                  },
                }),
                _c("VariantsConstructor", {
                  attrs: {
                    cols: _vm.cols,
                    variantsGroupByValidationError:
                      !_vm.$v.options.variants.variantsGroupBy.id.required,
                  },
                  model: {
                    value: _vm.options.variants,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "variants", $$v)
                    },
                    expression: "options.variants",
                  },
                }),
                _c("StockConstructor", {
                  attrs: {
                    cols: _vm.cols,
                    domain: _vm.domain,
                    orderActionEnabled: _vm.orderActionEnabled,
                  },
                  model: {
                    value: _vm.options.stock,
                    callback: function ($$v) {
                      _vm.$set(_vm.options, "stock", $$v)
                    },
                    expression: "options.stock",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "content-form__switchers" },
                  [
                    _c("SearchConstructor", {
                      attrs: { cols: _vm.cols, msg: _vm.msg },
                      model: {
                        value: _vm.options.search,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "search", $$v)
                        },
                        expression: "options.search",
                      },
                    }),
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pageManageSheet.settings.enableFilters"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: { selected: _vm.options.filters.enabled },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.filters.enabled,
                                    expression: "options.filters.enabled",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.filters.enabled
                                  )
                                    ? _vm._i(
                                        _vm.options.filters.enabled,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.filters.enabled,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.options.filters.enabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.filters,
                                              "enabled",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.filters,
                                              "enabled",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.filters,
                                          "enabled",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "filters",
                                        _vm.options.filters.enabled
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm.options.filters.enabled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content-form__switch-hidden-field aside-select",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__highlighted" },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "aside-field-label" },
                                    [_vm._v("Filter by columns")]
                                  ),
                                  _c("FiltersConstructor", {
                                    attrs: { cols: _vm.cols },
                                    model: {
                                      value: _vm.options.filters.values,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.options.filters,
                                          "values",
                                          $$v
                                        )
                                      },
                                      expression: "options.filters.values",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content-form__switch-field",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-form__switcher-wrap",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-form__switcher-label",
                                            },
                                            [
                                              _vm._v(
                                                "Disable unavailable filter options for current results."
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "switcher",
                                              class: {
                                                selected:
                                                  _vm.options.filters
                                                    .blockUnavailableOptions,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.options.filters
                                                        .blockUnavailableOptions,
                                                    expression:
                                                      "options.filters.blockUnavailableOptions",
                                                  },
                                                ],
                                                staticClass: "switcher__input",
                                                attrs: {
                                                  type: "checkbox",
                                                  value: "tiles",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.options.filters
                                                      .blockUnavailableOptions
                                                  )
                                                    ? _vm._i(
                                                        _vm.options.filters
                                                          .blockUnavailableOptions,
                                                        "tiles"
                                                      ) > -1
                                                    : _vm.options.filters
                                                        .blockUnavailableOptions,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.options.filters
                                                          .blockUnavailableOptions,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = "tiles",
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.options.filters,
                                                            "blockUnavailableOptions",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.options.filters,
                                                            "blockUnavailableOptions",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.options.filters,
                                                        "blockUnavailableOptions",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "switcher__indicator",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content-form__switch-field",
                                      staticStyle: { "margin-top": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-form__switcher-wrap",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-form__switcher-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pageManageSheet.settings.asideFiltersView"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "switcher",
                                              class: {
                                                selected:
                                                  _vm.options.filters
                                                    .asideFiltersView,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.options.filters
                                                        .asideFiltersView,
                                                    expression:
                                                      "options.filters.asideFiltersView",
                                                  },
                                                ],
                                                staticClass: "switcher__input",
                                                attrs: {
                                                  type: "checkbox",
                                                  value: "tiles",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.options.filters
                                                      .asideFiltersView
                                                  )
                                                    ? _vm._i(
                                                        _vm.options.filters
                                                          .asideFiltersView,
                                                        "tiles"
                                                      ) > -1
                                                    : _vm.options.filters
                                                        .asideFiltersView,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.options.filters
                                                            .asideFiltersView,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = "tiles",
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.options
                                                                .filters,
                                                              "asideFiltersView",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.options
                                                                .filters,
                                                              "asideFiltersView",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.options.filters,
                                                          "asideFiltersView",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.$trackFeature(
                                                        "content",
                                                        "filters",
                                                        _vm.options.filters
                                                          .asideFiltersView
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "switcher__indicator",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pageManageSheet.settings.enableSorting"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: { selected: _vm.options.sorting.enabled },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.sorting.enabled,
                                    expression: "options.sorting.enabled",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.sorting.enabled
                                  )
                                    ? _vm._i(
                                        _vm.options.sorting.enabled,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.sorting.enabled,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.options.sorting.enabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.sorting,
                                              "enabled",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.sorting,
                                              "enabled",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.sorting,
                                          "enabled",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "sorting",
                                        _vm.options.sorting.enabled
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm.options.sorting.enabled
                        ? _c(
                            "div",
                            {
                              staticClass: "content-form__switch-hidden-field",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__highlighted" },
                                [
                                  _c("SortingConstructor", {
                                    attrs: {
                                      cols: _vm.cols,
                                      v: _vm.$v.options.sorting,
                                    },
                                    model: {
                                      value: _vm.options.sorting,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.options, "sorting", $$v)
                                      },
                                      expression: "options.sorting",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content-form__switch-field",
                                      staticStyle: { "margin-top": "20px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "content-form__switcher-wrap",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "content-form__switcher-label",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pageManageSheet.settings.asideSortingView"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "switcher",
                                              class: {
                                                selected:
                                                  _vm.options.sorting
                                                    .asideSortingView,
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.options.sorting
                                                        .asideSortingView,
                                                    expression:
                                                      "options.sorting.asideSortingView",
                                                  },
                                                ],
                                                staticClass: "switcher__input",
                                                attrs: {
                                                  type: "checkbox",
                                                  value: "tiles",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.options.sorting
                                                      .asideSortingView
                                                  )
                                                    ? _vm._i(
                                                        _vm.options.sorting
                                                          .asideSortingView,
                                                        "tiles"
                                                      ) > -1
                                                    : _vm.options.sorting
                                                        .asideSortingView,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.options.sorting
                                                            .asideSortingView,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = "tiles",
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.options
                                                                .sorting,
                                                              "asideSortingView",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.options
                                                                .sorting,
                                                              "asideSortingView",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.options.sorting,
                                                          "asideSortingView",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      return _vm.$trackFeature(
                                                        "content",
                                                        "sorting",
                                                        _vm.options.sorting
                                                          .asideSortingView
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _c("span", {
                                                staticClass:
                                                  "switcher__indicator",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [
                              _vm._v(
                                "Open filters & sorting on mobile by default"
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected:
                                  _vm.options.appearance
                                    .openFiltersOnMobileByDefault,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.options.appearance
                                        .openFiltersOnMobileByDefault,
                                    expression:
                                      "options.appearance.openFiltersOnMobileByDefault",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.appearance
                                      .openFiltersOnMobileByDefault
                                  )
                                    ? _vm._i(
                                        _vm.options.appearance
                                          .openFiltersOnMobileByDefault,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.appearance
                                        .openFiltersOnMobileByDefault,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a =
                                          _vm.options.appearance
                                            .openFiltersOnMobileByDefault,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.appearance,
                                              "openFiltersOnMobileByDefault",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.appearance,
                                              "openFiltersOnMobileByDefault",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.appearance,
                                          "openFiltersOnMobileByDefault",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "openFiltersOnMobileByDefault",
                                        _vm.options.appearance
                                          .openFiltersOnMobileByDefault
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Fix search, sort and filter on scroll")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected: _vm.options.appearance.stickyFilters,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.appearance.stickyFilters,
                                    expression:
                                      "options.appearance.stickyFilters",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.appearance.stickyFilters
                                  )
                                    ? _vm._i(
                                        _vm.options.appearance.stickyFilters,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.appearance.stickyFilters,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a =
                                          _vm.options.appearance.stickyFilters,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.appearance,
                                              "stickyFilters",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.appearance,
                                              "stickyFilters",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.appearance,
                                          "stickyFilters",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "stickyFilters",
                                        _vm.options.appearance.stickyFilters
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pageManageSheet.settings.enablePagitation"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected: _vm.options.pagination.enabled,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.options.pagination.enabled,
                                    expression: "options.pagination.enabled",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.pagination.enabled
                                  )
                                    ? _vm._i(
                                        _vm.options.pagination.enabled,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.pagination.enabled,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.options.pagination.enabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.pagination,
                                              "enabled",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.pagination,
                                              "enabled",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.pagination,
                                          "enabled",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "pagination",
                                        _vm.options.pagination.enabled
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm.options.pagination.enabled
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "content-form__switch-hidden-field aside-input",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__highlighted" },
                                [
                                  _c(
                                    "FormField",
                                    {
                                      staticClass:
                                        "side-form__item aside-input",
                                      attrs: { label: "Items per page" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "page-form__hint" },
                                        [
                                          _vm._v(
                                            "Be aware that due to productivity reasons we limit to 100 a number of shown items in editing mode."
                                          ),
                                        ]
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: 1,
                                          max: 1000,
                                          required: "",
                                        },
                                        model: {
                                          value:
                                            _vm.options.pagination.itemsPerPage,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.options.pagination,
                                              "itemsPerPage",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "options.pagination.itemsPerPage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "ProOptionWrapper",
                      { attrs: { featureName: "hiddenColumns" } },
                      [
                        _c(
                          "div",
                          { staticClass: "content-form__switch-field" },
                          [
                            _c("PrivateColumnsConstructor", {
                              attrs: { cols: _vm.cols },
                              model: {
                                value: _vm.options.privateData.privateCells,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.options.privateData,
                                    "privateCells",
                                    $$v
                                  )
                                },
                                expression: "options.privateData.privateCells",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.$ft.ALLOW_USER_ACCESS
                      ? [
                          _c(
                            "ProOptionWrapper",
                            { attrs: { featureName: "userAccess" } },
                            [
                              _c(
                                "div",
                                { staticClass: "content-form__switch-field" },
                                [
                                  _c("PrivateRowConstructor", {
                                    attrs: { cols: _vm.cols },
                                    model: {
                                      value: _vm.options.privateData.privateRow,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.options.privateData,
                                          "privateRow",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "options.privateData.privateRow",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentTabIndex === 1,
                    expression: "currentTabIndex === 1",
                  },
                ],
                staticClass: "content-form__content-tab",
              },
              [
                _c(
                  "ProOptionWrapper",
                  { attrs: { featureName: "detailsPage" } },
                  [
                    _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Enable details page")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected:
                                  _vm.options.viewer.enableSingleItemView,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.options.viewer.enableSingleItemView,
                                    expression:
                                      "options.viewer.enableSingleItemView",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.viewer.enableSingleItemView
                                  )
                                    ? _vm._i(
                                        _vm.options.viewer.enableSingleItemView,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.viewer.enableSingleItemView,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a =
                                          _vm.options.viewer
                                            .enableSingleItemView,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.viewer,
                                              "enableSingleItemView",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.viewer,
                                              "enableSingleItemView",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.viewer,
                                          "enableSingleItemView",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "enableSingleItemView",
                                        _vm.options.viewer.enableSingleItemView
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.options.viewer.enableSingleItemView
                  ? _c("div", { staticClass: "content-form__switch-field" }, [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Show details as popup")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: {
                                selected:
                                  _vm.options.viewer.showSingleItemAsPopup,
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.options.viewer.showSingleItemAsPopup,
                                    expression:
                                      "options.viewer.showSingleItemAsPopup",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox", value: "tiles" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.options.viewer.showSingleItemAsPopup
                                  )
                                    ? _vm._i(
                                        _vm.options.viewer
                                          .showSingleItemAsPopup,
                                        "tiles"
                                      ) > -1
                                    : _vm.options.viewer.showSingleItemAsPopup,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a =
                                          _vm.options.viewer
                                            .showSingleItemAsPopup,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "tiles",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.options.viewer,
                                              "showSingleItemAsPopup",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.options.viewer,
                                              "showSingleItemAsPopup",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.options.viewer,
                                          "showSingleItemAsPopup",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        "showSingleItemAsPopup",
                                        _vm.options.viewer.showSingleItemAsPopup
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.options.viewer.enableSingleItemView
                  ? _c("SingleItemConstructor", {
                      attrs: {
                        cols: _vm.cols,
                        v: _vm.$v.options.viewer.singleItemOptions,
                        msg: _vm.msg,
                        integrationsData: _vm.integrationsData,
                      },
                      on: {
                        "change-tab": function ($event) {
                          return _vm.$emit("change-tab", "checkout-options")
                        },
                      },
                      model: {
                        value: _vm.options.viewer.singleItemOptions,
                        callback: function ($$v) {
                          _vm.$set(_vm.options.viewer, "singleItemOptions", $$v)
                        },
                        expression: "options.viewer.singleItemOptions",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "UiBtn",
          {
            staticClass: "aside-save",
            attrs: {
              size: "l",
              "attr-type": "submit",
              loading: _vm.loading,
              disabled: _vm.loading || _vm.spreadViewerOptionsInvalid,
            },
          },
          [_vm._v("\n      SAVE\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }