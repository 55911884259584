<template>
  <div>
    <!-- <div class="tile-constructor__hint"
      style="margin-bottom: 30px; margin-top: -20px;">
      <a target="_blank" href="https://help.spreadsimple.com/en/article/how-to-configure-and-manage-your-content-q1fgsr/">Help: How to configure and manage your Content</a>
    </div> -->
    <form
      class="content-form"
      @submit.prevent="submitForm"
    >
      <p v-if="validationStatus !== ''" class="aside-message">
        {{ validationStatus }}
      </p>

      <div class="content-form__content-tabs">
        <div class="content-form__content-tabs__header">
          <div
            v-for="(item, index) in contentTabs"
            :key="index"
            class="content-form__content-tabs__header-title"
            :class="{'active': currentTabIndex === index}"
            @click="currentTabIndex = index"
          >
            {{item.title}}
            <span v-if="item.invalid" class="content-form__content-tabs__header-title-invalid-icon">!</span>
          </div>
          <!-- <div class="content-form__content-tabs__header-title">Details page</div> -->
        </div>
        <div class="content-form__content-tabs__body">
          <!-- first tab -->
          <div v-show="currentTabIndex === 0" class="content-form__content-tab">
            <div class="content-form__tiles-constructor-wrap">
              <TilesConstructor
                v-model="options.viewer.tileOptions"
                :cols="cols"
                :enableSingleItemView="options.viewer.enableSingleItemView"
                :v="$v.options.viewer.tileOptions"
                :msg="msg"
                @change-tab="$emit('change-tab', 'checkout-options')"
              />
            </div>

            <MapViewConstructor
              v-model="options.viewer.mapView"
              :cols="cols"
              :integrationsData="integrationsData"
            />

            <VariantsConstructor
              v-model="options.variants"
              :cols="cols"
              :variantsGroupByValidationError="!$v.options.variants.variantsGroupBy.id.required"
            />
            <StockConstructor
              v-model="options.stock"
              :cols="cols"
              :domain="domain"
              :orderActionEnabled="orderActionEnabled"
            />

            <div class="content-form__switchers">

              <SearchConstructor
                v-model="options.search"
                :cols="cols"
                :msg="msg"
              />

              <div class="content-form__switch-field">
                <div class="content-form__switcher-wrap">
                  <div class="content-form__switcher-label">
                    {{ $t('pageManageSheet.settings.enableFilters') }}
                    <UiHelper
                      class="va-bottom ml-2"
                      popoverFeature="filters"
                      showCloseBtn
                      :videoData="{
                        src: '/video/helper-popup/filters.mp4',
                        label: 'Learn how to set up filters',
                        btns: [
                          {
                            label: 'Learn more',
                            to: 'https://help.spreadsimple.com/en/article/how-to-use-filters-1mpgefx/',
                            externalResource: true,
                            color: 'secondary-blue',
                            event: {
                              type: 'goToHelp'
                            }
                          },
                          {
                            label: 'Watch video',
                            to: { name: 'help-video', query: { show: 'filters' }},
                            event: {
                              type: 'goToVideo'
                            }
                          }
                        ]
                      }"
                    />
                  </div>
                  <label
                    :class="{ 'selected': options.filters.enabled }"
                    class="switcher"
                  >
                    <input
                      v-model="options.filters.enabled"
                      class="switcher__input"
                      type="checkbox"
                      value="tiles"
                      @change="$trackFeature('content', 'filters', options.filters.enabled)"
                    >
                    <span class="switcher__indicator"></span>
                  </label>
                </div>
                <div v-if="options.filters.enabled" class="content-form__switch-hidden-field aside-select">
                  <div class="content-form__highlighted">
                    <h6 class="aside-field-label">Filter by columns</h6>
                    <FiltersConstructor
                      v-model="options.filters.values"
                      :cols="cols"
                    />
                    <div class="content-form__switch-field">
                      <div class="content-form__switcher-wrap">
                        <div class="content-form__switcher-label">Disable unavailable filter options for current results.</div>
                          <label
                            :class="{ 'selected': options.filters.blockUnavailableOptions }"
                            class="switcher"
                          >
                            <input v-model="options.filters.blockUnavailableOptions" class="switcher__input" type="checkbox" value="tiles">
                            <span class="switcher__indicator"></span>
                          </label>
                      </div>
                    </div>
                    <div class="content-form__switch-field" style="margin-top: 20px;">
                      <div class="content-form__switcher-wrap">
                        <div class="content-form__switcher-label">{{ $t('pageManageSheet.settings.asideFiltersView') }}</div>
                        <label
                          :class="{ 'selected': options.filters.asideFiltersView }"
                          class="switcher"
                        >
                          <input
                            v-model="options.filters.asideFiltersView"
                            class="switcher__input"
                            type="checkbox"
                            value="tiles"
                            @change="$trackFeature('content', 'filters', options.filters.asideFiltersView)"
                          >
                          <span class="switcher__indicator"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-form__switch-field">
                <div class="content-form__switcher-wrap">
                  <div class="content-form__switcher-label">
                    {{ $t('pageManageSheet.settings.enableSorting') }}
                    <UiHelper
                      class="va-bottom ml-2"
                      popoverFeature="sorting"
                      showCloseBtn
                      :videoData="{
                        src: '/video/helper-popup/sorting.mp4',
                        label: 'Learn how to set up sorting',
                        btns: [
                          {
                            label: 'Learn more',
                            to: 'https://help.spreadsimple.com/en/article/how-to-set-up-sorting-1gzusuk/',
                            externalResource: true,
                            color: 'secondary-blue',
                            event: {
                              type: 'goToHelp'
                            }
                          },
                          {
                            label: 'Watch video',
                            to: { name: 'help-video', query: { show: 'sorting' }},
                            event: {
                              type: 'goToVideo'
                            }
                          }
                        ]
                      }"
                    />
                  </div>
                  <label
                    :class="{ 'selected': options.sorting.enabled }"
                    class="switcher"
                  >
                    <input
                      v-model="options.sorting.enabled"
                      class="switcher__input"
                      type="checkbox"
                      value="tiles"
                      @change="$trackFeature('content', 'sorting', options.sorting.enabled)"
                    >
                    <span class="switcher__indicator"></span>
                  </label>
                </div>
                <div v-if="options.sorting.enabled" class="content-form__switch-hidden-field">
                  <div class="content-form__highlighted">
                    <SortingConstructor
                      v-model="options.sorting"
                      :cols="cols"
                      :v="$v.options.sorting"
                    />
                    <div class="content-form__switch-field" style="margin-top: 20px;">
                      <div class="content-form__switcher-wrap">
                        <div class="content-form__switcher-label">{{ $t('pageManageSheet.settings.asideSortingView') }}</div>
                          <label
                            :class="{ 'selected': options.sorting.asideSortingView }"
                            class="switcher"
                          >
                            <input
                              v-model="options.sorting.asideSortingView"
                              class="switcher__input"
                              type="checkbox"
                              value="tiles"
                              @change="$trackFeature('content', 'sorting', options.sorting.asideSortingView)"
                            >
                            <span class="switcher__indicator"></span>
                          </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-form__switch-field">
                <div class="content-form__switcher-wrap">
                  <div class="content-form__switcher-label">Open filters & sorting on mobile by default</div>
                    <label
                      :class="{ 'selected': options.appearance.openFiltersOnMobileByDefault }"
                      class="switcher"
                    >
                      <input
                      v-model="options.appearance.openFiltersOnMobileByDefault"
                      class="switcher__input" type="checkbox" value="tiles"
                      @change="$trackFeature('content', 'openFiltersOnMobileByDefault', options.appearance.openFiltersOnMobileByDefault)"
                      >
                      <span class="switcher__indicator"></span>
                    </label>
                </div>
              </div>

              <div class="content-form__switch-field">
                <div class="content-form__switcher-wrap">
                  <div class="content-form__switcher-label">Fix search, sort and filter on scroll</div>
                    <label
                      :class="{ 'selected': options.appearance.stickyFilters }"
                      class="switcher"
                    >
                      <input
                      v-model="options.appearance.stickyFilters"
                      class="switcher__input" type="checkbox" value="tiles"
                      @change="$trackFeature('content', 'stickyFilters', options.appearance.stickyFilters)"
                      >
                      <span class="switcher__indicator"></span>
                    </label>
                </div>
              </div>

              <div class="content-form__switch-field">
                <div class="content-form__switcher-wrap">
                  <div class="content-form__switcher-label">{{ $t('pageManageSheet.settings.enablePagitation') }}</div>
                    <label
                      :class="{ 'selected': options.pagination.enabled }"
                      class="switcher"
                    >
                      <input v-model="options.pagination.enabled"
                      class="switcher__input"
                      type="checkbox" value="tiles" @change="$trackFeature('content', 'pagination', options.pagination.enabled)">
                      <span class="switcher__indicator"></span>
                    </label>
                </div>
                <div v-if="options.pagination.enabled" class="content-form__switch-hidden-field aside-input">
                  <div class="content-form__highlighted">
                    <FormField label="Items per page" class="side-form__item aside-input">
                      <div class="page-form__hint">Be aware that due to productivity reasons we limit to 100 a number of shown items in editing mode.</div>
                      <v-text-field
                        v-model="options.pagination.itemsPerPage"
                        type="number"
                        :min="1"
                        :max="1000"
                        required
                      >
                      </v-text-field>
                    </FormField>

                  </div>
                </div>
              </div>
              <ProOptionWrapper :featureName="'hiddenColumns'">
                <div class="content-form__switch-field">
                  <PrivateColumnsConstructor
                    v-model="options.privateData.privateCells"
                    :cols="cols"
                  />
                </div>
              </ProOptionWrapper>
              <template v-if="$ft.ALLOW_USER_ACCESS">
              <ProOptionWrapper :featureName="'userAccess'">
                <div class="content-form__switch-field">
                  <PrivateRowConstructor
                    v-model="options.privateData.privateRow"
                    :cols="cols"
                  />
                </div>
              </ProOptionWrapper>
              </template>
            </div>
          </div>
          <!-- second tab -->
          <div v-show="currentTabIndex === 1" class="content-form__content-tab">
            <UiHelper
              labelClasses="text--weight-500 text--size-xs"
              label="How to configure your details page"
              popoverFeature="details-page"
              class="mb-20"
              showCloseBtn
              :videoData="{
                src: '/video/helper-popup/details-page.mp4',
                label: 'Learn how to set up and configure details pages',
                btns: [
                  {
                    label: 'Learn more',
                    to: 'https://help.spreadsimple.com/en/article/how-to-manage-details-page-16h9j6e/',
                    externalResource: true,
                    color: 'secondary-blue',
                    event: {
                      type: 'goToHelp'
                    }
                  },
                  {
                    label: 'Watch video',
                    to: { name: 'help-video', query: { show: 'details-page' }},
                    event: {
                      type: 'goToVideo'
                    }
                  }
                ]
              }"
            />
            <ProOptionWrapper :featureName="'detailsPage'">
            <div class="content-form__switch-field">
              <div class="content-form__switcher-wrap">
                <div class="content-form__switcher-label">Enable details page</div>
                <label
                  :class="{ 'selected': options.viewer.enableSingleItemView }"
                  class="switcher"
                >
                  <input
                    v-model="options.viewer.enableSingleItemView"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('content', 'enableSingleItemView', options.viewer.enableSingleItemView)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </div>
            </ProOptionWrapper>
            <div v-if="options.viewer.enableSingleItemView"
              class="content-form__switch-field"
            >
              <div class="content-form__switcher-wrap">
                <div class="content-form__switcher-label">Show details as popup</div>
                <label
                  :class="{ 'selected': options.viewer.showSingleItemAsPopup }"
                  class="switcher"
                >
                  <input
                    v-model="options.viewer.showSingleItemAsPopup"
                    class="switcher__input"
                    type="checkbox"
                    value="tiles"
                    @change="$trackFeature('content', 'showSingleItemAsPopup', options.viewer.showSingleItemAsPopup)"
                  >
                  <span class="switcher__indicator"></span>
                </label>
              </div>
            </div>
            <SingleItemConstructor
              v-if="options.viewer.enableSingleItemView"
              v-model="options.viewer.singleItemOptions"
              :cols="cols"
              :v="$v.options.viewer.singleItemOptions"
              :msg="msg"
              :integrationsData="integrationsData"
              @change-tab="$emit('change-tab', 'checkout-options')"
            />
          </div>
        </div>
      </div>
      <UiBtn
        size="l"
        attr-type="submit"
        :loading="loading"
        :disabled="loading || spreadViewerOptionsInvalid"
        class="aside-save"
      >
        SAVE
      </UiBtn>
    </form>
  </div>
</template>
<script>
import { requiredIf, maxLength, helpers } from 'vuelidate/lib/validators'
import TilesConstructor from '@/components/tiles-constructor'
import SingleItemConstructor from '@/components/single-item-constructor'
import SearchConstructor from '@/components/search-constructor'
import SortingConstructor from '@/components/sorting-constructor'
import FiltersConstructor from '@/components/filters-constructor'
import StockConstructor from '@/components/stock-constructor'
import VariantsConstructor from '@/components/variants-constructor'
import PrivateColumnsConstructor from '@/components/private-columns-constructor'
import PrivateRowConstructor from '@/components/private-row-constructor'
import FormField from '@/components/form-field'
import UiBtn from '@/components/ui-btn'
import UiHelper from '@/components/ui-helper'
import Vuelidate from 'vuelidate'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import { mapDetailsOptionsToTileOptions } from '@/modules/options-mapper'
import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import MapViewConstructor from '@/components/map-view-constructor/'
import { allowFeature } from '@/modules/features-checker'
Vue.use(Vuelidate)
const routeSlugRegexp = helpers.regex('routeSlugRegexp', /^[A-Za-z0-9\-_]*$/)
function checkSlugFor404 (value) {
  return value !== '404'
}
export default {
  name: 'SpreadViewerContentOptions',
  components: {
    TilesConstructor,
    SortingConstructor,
    FiltersConstructor,
    SingleItemConstructor,
    SearchConstructor,
    ProOptionWrapper,
    StockConstructor,
    VariantsConstructor,
    MapViewConstructor,
    PrivateColumnsConstructor,
    PrivateRowConstructor,
    FormField,
    UiBtn,
    UiHelper
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cols: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean
    },
    domain: {
      type: String,
      required: true
    },
    msg: {
      type: Object,
      required: true
    },
    integrationsData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: null,
      validationStatus: '',
      currentTabIndex: 0
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    ...mapGetters('app', ['spreadViewerOptionsInvalid']),
    detailsPageAllowed () {
      return allowFeature('detailsPage', {
        user: this.userData,
        website: this.currentSpreadViewData
      })
    },
    contentTabs () {
      return [
        {
          title: 'Items list',
          invalid: this.$v.options.viewer.tileOptions.$invalid
        },
        {
          title: 'Details page',
          invalid: this.$v.options.viewer.singleItemOptions.$invalid
        }
      ]
    },

    orderActionEnabled () {
      if ((this.value.viewer.enableSingleItemView && this.value.viewer.singleItemOptions.cta.type === 'order') ||
          (this.value.viewer.enableSingleItemView && this.value.viewer.singleItemOptions.cta_second.type === 'order')) {
        return true
      }
      if (this.value.viewer.tileOptions.cta.type === 'order' || this.value.viewer.tileOptions.cta_second.type === 'order') {
        return true
      }
      return false
    }
  },
  validations: {
    options: {
      viewer: {
        tileOptions: {
        },
        singleItemOptions: {
          url: {
            id: {
              required: requiredIf(function () {
                return this.options.viewer.enableSingleItemView
              })
            }
          },
          baseRoute: {
            required: requiredIf(function () {
              return this.options.viewer.enableSingleItemView
            }),
            maxLength: maxLength(50),
            routeSlugRegexp,
            checkSlugFor404

          },
          socialShareButtons: {
            required: requiredIf(function () {
              return this.options.viewer.singleItemOptions.socialShareButtonsActive
            })
          }
        }
      },
      sorting: {
        ids: {
          required: requiredIf(function () {
            return this.options.sorting.enabled
          })
        },
        default: {
          id: {
            required: requiredIf(function () {
              return this.options.sorting.enabled
            })
          },
          direction: {
            required: requiredIf(function () {
              return this.options.sorting.enabled
            })
          }
        }
      },
      variants: {
        variantsGroupBy: {
          id: {
            required: requiredIf(function () {
              return this.options.variants.enabled
            })
          }
        }
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        this.options = val
      }
    },
    options: {
      deep: true,
      handler (val) {
        if (this.$v.$invalid) {
          this.validationStatus = this.$t('pageManageSheet.settings.optionsSubmitError')
        }
        this.validationStatus = ''
      }
    },
    'options.viewer.enableSingleItemView' (val, oldVal) {
      if (this.detailsPageAllowed && val && this.currentTabIndex === 1) {
        this.options.viewer.singleItemOptions = mapDetailsOptionsToTileOptions(this.options.viewer.tileOptions, this.options.viewer.singleItemOptions)
        this.$emit('open-default-single-item', true)
      } else {
        this.$emit('open-default-single-item', false)
      }
    },
    currentTabIndex (val) {
      if (this.detailsPageAllowed && this.options.viewer.enableSingleItemView && val === 1) {
        this.$emit('open-default-single-item', true)
      } else {
        this.$emit('open-default-single-item', false)
      }
    }
  },
  methods: {
    ...mapActions('app', ['setSpreadViewerOptionsValidationState']),
    submitForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.validationStatus = this.$t('global.formSubmitError')
        return
      }
      this.$emit('save', this.options)
      this.validationStatus = ''
    }
  },
  created () {
    this.options = this.value
  },
  mounted () {
    this.$watch('$v.$invalid', (val) => {
      this.setSpreadViewerOptionsValidationState({
        optionName: 'contentOptionsInvalid',
        isInvalid: val
      })
    })
  }
}
</script>
