var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "fields-builder",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "fields-builder__items" },
        [
          _c(
            "div",
            { staticClass: "fields-builder__item" },
            [
              _c(
                "FormField",
                {
                  staticClass: "fields-builder__item-control",
                  attrs: {
                    label: `Field type ${
                      _vm.fieldData.isBuiltIn ? "(read only)" : ""
                    }`,
                    messages:
                      _vm.$v.fieldData.type.$dirty &&
                      _vm.$v.fieldData.type.$invalid
                        ? ["Field is required"]
                        : [],
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.fieldTypes,
                      readonly: _vm.fieldData.isBuiltIn,
                      "item-text": "label",
                      "item-value": "type",
                      "menu-props": "offsetY",
                      placeholder: "Choose field type",
                      "append-icon": "keyboard_arrow_down",
                    },
                    model: {
                      value: _vm.$v.fieldData.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.fieldData.type, "$model", $$v)
                      },
                      expression: "$v.fieldData.type.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.fieldData.type === "markdown"
            ? [
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: {
                          label: "Label",
                          messages:
                            _vm.$v.fieldData.label.$dirty &&
                            _vm.$v.fieldData.label.$invalid
                              ? ["Field is required"]
                              : [],
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: "Enter field label" },
                          on: { change: _vm.setSysNameFromLabel },
                          model: {
                            value: _vm.$v.fieldData.label.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.fieldData.label, "$model", $$v)
                            },
                            expression: "$v.fieldData.label.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: { label: "Description" },
                      },
                      [
                        _c("MarkdownComponent", {
                          attrs: {
                            preview: false,
                            leftToolbar: "bold italic strikethrough link",
                          },
                          model: {
                            value: _vm.fieldData.default,
                            callback: function ($$v) {
                              _vm.$set(_vm.fieldData, "default", $$v)
                            },
                            expression: "fieldData.default",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: {
                          label: `Field system name ${
                            _vm.fieldData.isBuiltIn ? "(read only)" : ""
                          }`,
                          hint: `Must be unique. ${
                            _vm.fieldData.name.length
                              ? `Replacing tag for this field will be {{formFields.${_vm.fieldData.name}}}`
                              : ""
                          }`,
                          messages:
                            _vm.$v.fieldData.name.$dirty &&
                            _vm.$v.fieldData.name.$invalid
                              ? _vm.systemNameErrorMessages
                              : [],
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            value: _vm.$v.fieldData.name.$model,
                            readonly: _vm.fieldData.isBuiltIn,
                            placeholder: "Enter system name",
                          },
                          on: { change: _vm.setSysNameManually },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: { label: "Field width" },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.widthOptions,
                            "item-text": "label",
                            "item-value": "value",
                            "menu-props": "offsetY",
                            placeholder: "Choose field width",
                            "append-icon": "keyboard_arrow_down",
                          },
                          model: {
                            value: _vm.fieldData.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.fieldData, "width", $$v)
                            },
                            expression: "fieldData.width",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: {
                          label: "Label",
                          messages:
                            _vm.$v.fieldData.label.$dirty &&
                            _vm.$v.fieldData.label.$invalid
                              ? ["Field is required"]
                              : [],
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: "Enter field label" },
                          on: { change: _vm.setSysNameFromLabel },
                          model: {
                            value: _vm.$v.fieldData.label.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.fieldData.label, "$model", $$v)
                            },
                            expression: "$v.fieldData.label.$model",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "fields-builder__item" }, [
                  ["select", "multiselect", "radio", "checkbox"].includes(
                    _vm.fieldData.type
                  )
                    ? _c(
                        "div",
                        { staticClass: "fields-builder__draggable" },
                        [
                          _c("div", { staticClass: "n-form-field__label" }, [
                            _vm._v("\n            Options\n          "),
                          ]),
                          _vm.fieldData.options.length
                            ? _c(
                                "draggable",
                                {
                                  staticClass: "draggable",
                                  attrs: {
                                    tag: "div",
                                    handle: ".draggable__item-trigger",
                                  },
                                  model: {
                                    value: _vm.fieldData.options,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fieldData, "options", $$v)
                                    },
                                    expression: "fieldData.options",
                                  },
                                },
                                _vm._l(
                                  _vm.$v.fieldData.options.$each.$iter,
                                  function (option, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "draggable__item hover-disabled",
                                      },
                                      [
                                        _c("div", {
                                          staticClass:
                                            "draggable__item-trigger icon-draggable",
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "draggable__item-input-wrap",
                                          },
                                          [
                                            _c(
                                              "FormField",
                                              {
                                                attrs: {
                                                  messages:
                                                    option.value.$dirty &&
                                                    option.value.$invalid
                                                      ? ["Required"]
                                                      : [],
                                                },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    value: option.value.$model,
                                                    placeholder:
                                                      "Enter option here",
                                                  },
                                                  on: {
                                                    input: (val) =>
                                                      _vm.setOptionValue(
                                                        val,
                                                        index
                                                      ),
                                                    keydown: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "tab",
                                                          9,
                                                          $event.key,
                                                          "Tab"
                                                        )
                                                      )
                                                        return null
                                                      $event.preventDefault()
                                                      return _vm.addOption.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "draggable__item-controls fields-builder__control-toolbar",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fields-builder__control-toolbar-item",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteOption(
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "fields-builder__control-toolbar-item",
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        "Select default item",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "radio draggable__item-control-btn",
                                                      },
                                                      [
                                                        _c("input", {
                                                          staticClass:
                                                            "radio__input",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            value:
                                                              _vm.fieldData
                                                                .options[index]
                                                                .default,
                                                            checked:
                                                              _vm.fieldData
                                                                .options[index]
                                                                .default,
                                                          },
                                                          on: {
                                                            input: function (
                                                              $event
                                                            ) {
                                                              return _vm.updateDefaultOption(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("div", {
                                                          staticClass:
                                                            "radio__indicator",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              staticClass:
                                "fields-builder__item-add-btn for-draggable-items",
                              attrs: { type: "button" },
                              on: { click: _vm.addOption },
                            },
                            [
                              _vm._v(
                                "\n            + Add new option\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "fields-builder__item-msg is-error is-flex-row for-draggable",
                    },
                    [
                      _vm.$v.fieldData.options.$dirty &&
                      _vm.$v.fieldData.options.$invalid &&
                      !_vm.$v.fieldData.options.required
                        ? _c("p", [_vm._v("Add options to the list")])
                        : _vm._e(),
                      _vm.$v.fieldData.options.$dirty &&
                      _vm.$v.fieldData.options.$invalid &&
                      !_vm.$v.fieldData.options.minOptionsLength
                        ? _c("p", [_vm._v("Min 2 options required")])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    ["text", "number", "email", "textarea"].includes(
                      _vm.fieldData.type
                    )
                      ? _c(
                          "FormField",
                          {
                            staticClass: "fields-builder__item-control",
                            attrs: { label: "Default value", hint: "Optional" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: { placeholder: "Enter default value" },
                              model: {
                                value: _vm.fieldData.default,
                                callback: function ($$v) {
                                  _vm.$set(_vm.fieldData, "default", $$v)
                                },
                                expression: "fieldData.default",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    !["radio", "checkbox", "time"].includes(_vm.fieldData.type)
                      ? _c(
                          "FormField",
                          {
                            staticClass: "fields-builder__item-control",
                            attrs: { label: "Placeholder", hint: "Optional" },
                          },
                          [
                            _c("v-text-field", {
                              attrs: { placeholder: "Enter placeholder" },
                              model: {
                                value: _vm.fieldData.placeholder,
                                callback: function ($$v) {
                                  _vm.$set(_vm.fieldData, "placeholder", $$v)
                                },
                                expression: "fieldData.placeholder",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: { label: "Hint", hint: "Optional" },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: "Enter field hint" },
                          model: {
                            value: _vm.fieldData.hint,
                            callback: function ($$v) {
                              _vm.$set(_vm.fieldData, "hint", $$v)
                            },
                            expression: "fieldData.hint",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: {
                          label: `Field system name ${
                            _vm.fieldData.isBuiltIn ? "(read only)" : ""
                          }`,
                          hint: `Must be unique. ${
                            _vm.fieldData.name.length
                              ? `Replacing tag for this field will be {{formFields.${_vm.fieldData.name}}}`
                              : ""
                          }`,
                          messages:
                            _vm.$v.fieldData.name.$dirty &&
                            _vm.$v.fieldData.name.$invalid
                              ? _vm.systemNameErrorMessages
                              : [],
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            value: _vm.$v.fieldData.name.$model,
                            readonly: _vm.fieldData.isBuiltIn,
                            placeholder: "Enter system name",
                          },
                          on: { change: _vm.setSysNameManually },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fields-builder__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "fields-builder__item-control",
                        attrs: { label: "Field width" },
                      },
                      [
                        _c("v-select", {
                          attrs: {
                            items: _vm.widthOptions,
                            "item-text": "label",
                            "item-value": "value",
                            "menu-props": "offsetY",
                            placeholder: "Choose field width",
                            "append-icon": "keyboard_arrow_down",
                          },
                          model: {
                            value: _vm.fieldData.width,
                            callback: function ($$v) {
                              _vm.$set(_vm.fieldData, "width", $$v)
                            },
                            expression: "fieldData.width",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "fields-builder__item" }, [
                  ["text", "number", "email", "textarea"].includes(
                    _vm.fieldData.type
                  )
                    ? _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Make this field readonly")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: { selected: _vm.fieldData.readOnly },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.fieldData.readOnly,
                                    expression: "fieldData.readOnly",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.fieldData.readOnly)
                                    ? _vm._i(_vm.fieldData.readOnly, null) > -1
                                    : _vm.fieldData.readOnly,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.fieldData.readOnly,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.fieldData,
                                            "readOnly",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.fieldData,
                                            "readOnly",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.fieldData, "readOnly", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  { staticClass: "fields-builder__validations" },
                  [
                    _c(
                      "h3",
                      { staticClass: "fields-builder__validations-title" },
                      [_vm._v("Validation rules")]
                    ),
                    _vm._l(
                      _vm.$v.selectedValidations.$each.$iter,
                      function (rule) {
                        return _c(
                          "div",
                          {
                            key: rule.name,
                            staticClass: "fields-builder__item",
                          },
                          [
                            _c(
                              "h5",
                              { staticClass: "fields-builder__item-title" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(rule.$model.label) +
                                    "\n            "
                                ),
                                _c("i", {
                                  staticClass:
                                    "fields-builder__item-delete-btn icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteValidationRule(
                                        rule.$model
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "fields-builder__item is-flex-row",
                              },
                              [
                                rule.$model.numberOfArgs
                                  ? _vm._l(
                                      rule.$model.numberOfArgs,
                                      function (val, index) {
                                        return _c(
                                          "FormField",
                                          {
                                            key: index,
                                            staticClass:
                                              "fields-builder__item-control",
                                            attrs: {
                                              label: "Validation value",
                                              messages:
                                                rule.args.$each.$iter[index]
                                                  .value.$dirty &&
                                                rule.args.$each.$iter[index]
                                                  .value.$invalid
                                                  ? ["Field is required"]
                                                  : [],
                                            },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                value:
                                                  rule.$model.args[index].value,
                                                type: rule.$model.typeOfArgs,
                                                placeholder: "Enter value",
                                              },
                                              on: {
                                                input: (val) =>
                                                  (rule.$model.args[
                                                    index
                                                  ].value = val),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                _c(
                                  "FormField",
                                  {
                                    staticClass: "fields-builder__item-control",
                                    attrs: { label: "Error message" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        placeholder: "Enter error message",
                                      },
                                      model: {
                                        value: rule.$model.message,
                                        callback: function ($$v) {
                                          _vm.$set(rule.$model, "message", $$v)
                                        },
                                        expression: "rule.$model.message",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }
                    ),
                    _c(
                      "div",
                      { staticClass: "fields-builder__item" },
                      [
                        _vm.availiableValidations.length
                          ? _c(
                              "el-dropdown",
                              { attrs: { trigger: "click" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fields-builder__item-add-btn",
                                  },
                                  [_vm._v("+ Add validation rule")]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  _vm._l(
                                    _vm.availiableValidations,
                                    function (rule) {
                                      return _c(
                                        "el-dropdown-item",
                                        {
                                          key: rule.name,
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.addValidationRule(rule)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(rule.label))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _c("div", { staticClass: "fields-builder__footer" }, [
        _c("div", { staticClass: "fields-builder__footer-btns" }, [
          _c(
            "button",
            {
              staticClass: "fields-builder__footer-btn btn btn--text-only",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel-submission")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "fields-builder__footer-btn btn",
              attrs: { type: "submit" },
            },
            [_vm._v(_vm._s(_vm.value ? "SAVE" : "ADD"))]
          ),
        ]),
        _vm.validationStatus.length
          ? _c("p", { staticClass: "fields-builder__footer-msg" }, [
              _c("i", {
                staticClass:
                  "icon-circle-close fields-builder__footer-msg-icon",
              }),
              _vm._v("\n      " + _vm._s(_vm.validationStatus) + "\n    "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }