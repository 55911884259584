var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paginator" }, [
    _c("button", {
      staticClass: "paginator__prev",
      attrs: { type: "button", disabled: _vm.prevPage === null },
      on: {
        click: function ($event) {
          return _vm.$emit("change-page", _vm.prevPage)
        },
      },
    }),
    _c(
      "div",
      { staticClass: "paginator__list" },
      _vm._l(_vm.pageNumbers, function (page, index) {
        return _c(
          "button",
          {
            key: index,
            staticClass: "paginator__item",
            class: { "is-active": page === _vm.currentPage },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("change-page", page)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(page) + "\n    ")]
        )
      }),
      0
    ),
    _c("button", {
      staticClass: "paginator__next",
      attrs: { type: "button", disabled: _vm.nextPage === null },
      on: {
        click: function ($event) {
          return _vm.$emit("change-page", _vm.nextPage)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }