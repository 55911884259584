var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.checkDomainAvaliability()
        },
      },
    },
    [
      _c("p", { staticClass: "text--size-xs text--color-secondary" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.$t("pageManageSheet.modal.title")) + "\n  "
        ),
      ]),
      _c(
        "ProOptionWrapper",
        { staticClass: "mt-12", attrs: { featureName: "updateDefaultDomain" } },
        [
          _c(
            "FormField",
            { attrs: { messages: [_vm.domainValidationErrors] } },
            [
              _c(
                "div",
                {
                  staticClass: "domain-field",
                  class: { "is-disabled": _vm.useCustomDomain },
                },
                [
                  _c("span", { staticClass: "domain-field__prepend" }, [
                    _vm._v("https://"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalSubDomainValue,
                        expression: "internalSubDomainValue",
                      },
                      {
                        name: "autowidth",
                        rawName: "v-autowidth",
                        value: {
                          maxWidth: "100%",
                          minWidth: "10px",
                          comfortZone: 5,
                        },
                        expression:
                          "{maxWidth: '100%', minWidth: '10px', comfortZone: 5}",
                      },
                    ],
                    staticClass: "domain-field__input",
                    attrs: {
                      disabled: _vm.useCustomDomain,
                      name: "domain",
                      type: "text",
                    },
                    domProps: { value: _vm.internalSubDomainValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.internalSubDomainValue = $event.target.value
                      },
                    },
                  }),
                  _c("span", { staticClass: "domain-field__append" }, [
                    _vm._v("." + _vm._s(_vm.internalDomainBase)),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("p", { staticClass: "text--size-xs text--color-secondary mt-12" }, [
        _vm._v(
          "\n    Once you apply a license to your website, you can connect it to your custom domain or subdomain purchased from a domain provider.\n  "
        ),
      ]),
      _c(
        "ProOptionWrapper",
        { staticClass: "mt-12", attrs: { featureName: "updateCustomDomain" } },
        [
          _c("UiSwitcher", {
            attrs: { label: "Connect your own domain" },
            model: {
              value: _vm.useCustomDomain,
              callback: function ($$v) {
                _vm.useCustomDomain = $$v
              },
              expression: "useCustomDomain",
            },
          }),
          _c(
            "FormField",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.useCustomDomain,
                  expression: "useCustomDomain",
                },
              ],
              staticClass: "mt-12",
              attrs: { messages: [_vm.customDomainValidationErrors] },
            },
            [
              _c(
                "div",
                {
                  staticClass: "domain-field",
                  class: { "is-disabled": !_vm.useCustomDomain },
                },
                [
                  _c("span", { staticClass: "domain-field__prepend" }, [
                    _vm._v("https://"),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customDomainValue,
                        expression: "customDomainValue",
                      },
                    ],
                    staticClass: "domain-field__input",
                    attrs: {
                      disabled: !_vm.useCustomDomain,
                      type: "text",
                      placeholder: "Enter your domain",
                    },
                    domProps: { value: _vm.customDomainValue },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.customDomainValue = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.useCustomDomain && _vm.customDomainValue && _vm.customDomainIpInvalid
        ? _c(
            "div",
            { staticClass: "mt-12 text--size-xs text--color-secondary" },
            [
              _c("UiMsgBox", {
                staticClass: "mt-8",
                attrs: {
                  size: "s",
                  title: "Awaiting DNS configuration",
                  color: "warning",
                },
              }),
              _c("p", { staticClass: "mt-8" }, [
                _vm._v(
                  "Set the following record on your DNS provider to continue:"
                ),
              ]),
              _c(
                "table",
                { staticClass: "table mt-8", attrs: { width: "100%" } },
                [
                  _vm._m(0),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.customSubdomainDNSName === "@" ? "A" : "CNAME"
                          )
                        ),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.customSubdomainDNSName))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.customSubdomainDNSName === "@"
                              ? _vm.spreadSimpleIp
                              : _vm.spreadSimpleCname
                          )
                        ),
                      ]),
                    ]),
                    _vm.customSubdomainDNSName === "@"
                      ? _c("tr", [
                          _c("td", [_vm._v("CNAME")]),
                          _c("td", [_vm._v("www")]),
                          _c("td", [_vm._v(_vm._s(_vm.spreadSimpleCname))]),
                        ])
                      : _vm._e(),
                  ]),
                ]
              ),
              _c("p", { staticClass: "mt-8" }, [
                _vm._v(
                  "Keep in mind that this procedure may take up to 12 hours depends on your domain provider."
                ),
              ]),
              _c("UiBtn", {
                staticClass: "mt-8",
                attrs: {
                  size: "s",
                  attrType: "button",
                  type: "secondary",
                  fullWidth: "",
                  disabled: _vm.checkingDns,
                  loading: _vm.checkingDns,
                  label: _vm.checkingDns ? "Checking..." : "Refresh",
                },
                on: { click: _vm.checkDomainDns },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.useCustomDomain && !_vm.checkingDns && !_vm.customDomainIpInvalid
        ? _c(
            "div",
            { staticClass: "mt-12 text--size-xs text--color-secondary" },
            [
              _vm.ssl === "NOT_ISSUED" || _vm.ssl === "FAILED"
                ? _c("UiBtn", {
                    attrs: {
                      label: "Issue SSL certificate",
                      attrType: "button",
                      type: "secondary-blue",
                      size: "s",
                      fullWidth: "",
                      disabled: _vm.issuingSsl,
                      loading: _vm.issuingSsl,
                    },
                    on: { click: _vm.issueSslSert },
                  })
                : _vm._e(),
              _vm.ssl === "ISSUING"
                ? _c("UiMsgBox", {
                    staticClass: "mt-8",
                    attrs: {
                      color: "warning",
                      title: "SSL certificate issuing in progress",
                      size: "s",
                    },
                  })
                : _vm._e(),
              _vm.ssl === "ISSUED"
                ? _c("UiMsgBox", {
                    staticClass: "mt-8",
                    attrs: {
                      color: "success",
                      title: "SSL certificate issued successfully",
                      size: "s",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "mt-12 mb-12",
        class: { "ui-divider": _vm.useCustomDomain },
      }),
      _c(
        "ProOptionWrapper",
        {
          staticClass: "mt-12",
          attrs: { featureName: "updateDefaultDomain" || "updateCustomDomain" },
        },
        [
          _c("UiBtn", {
            attrs: {
              label: "Update domain",
              attrType: "submit",
              fullWidth: "",
              uppercase: "",
              size: "m",
              disabled: _vm.loading || _vm.emptyCustomDomain,
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("td", [_vm._v("Type")]),
      _c("td", [_vm._v("Name")]),
      _c("td", [_vm._v("Value")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }