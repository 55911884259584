<template>
  <div class="content-form__switch-field">
    <div class="content-form__switcher-wrap">
      <div class="content-form__switcher-label">
        Enable stock limitation
        <UiHelper
          class="va-bottom ml-2"
          popoverFeature="stock-limitation"
          showCloseBtn
          :videoData="{
            src: '/video/helper-popup/stock-limitation.mp4',
            label: 'Learn how to use stock limitation',
            btns: [
              {
                label: 'Learn more',
                to: 'https://help.spreadsimple.com/en/article/how-to-set-stock-limitation-1d97k2f/',
                externalResource: true,
                color: 'secondary-blue',
                event: {
                  type: 'goToHelp'
                }
              },
              {
                label: 'Watch video',
                to: { name: 'help-video', query: { show: 'stock-limitation' }},
                event: {
                  type: 'goToVideo'
                }
              }
            ]
          }"
        />
      </div>
      <label
        :class="{ 'selected': stock.enabled }"
        class="switcher"
      >
        <input
          v-model="stock.enabled"
          class="switcher__input"
          type="checkbox"
          value="tiles"
          @change="$trackFeature('content', 'stock', stock.enabled)"
        >
        <span class="switcher__indicator"></span>
      </label>
    </div>
    <div v-if="stock.enabled" class="content-form__switch-hidden-field aside-select paddingless">
      <div class="sorting-constructor__highlighted">
        <div class="sorting-constructor__item">
            <div v-if="!orderActionEnabled" class="page-form__hint error">This option will work only if the action button type is set to 'Order Form'</div>

          <div class="sorting-constructor__select aside-select paddingless">
            <h6 class="sorting-constructor__item-label">Stock column</h6>
            <v-select
              v-model="stock.id"
              attach
              clearable
              menu-props="offsetY"
              :items="mappedCols"
              item-text="label"
              item-value="value"
              append-icon="keyboard_arrow_down"
            >
            </v-select>
            <a class="sorting-constructor__link" target="_blank" href="https://help.spreadsimple.com/en/article/how-to-set-stock-limitation-1d97k2f/">Help: How to use stock limitation?</a>
          </div>
        </div>
        <div class="sorting-constructor__item">
          <FormField label="Out of stock button label" class="side-form__item aside-input">
            <div class="page-form__hint">Changes the action button label if the stock column has zero value.</div>
            <v-text-field
              v-model.trim="stock.outOfStockLabel"
              placeholder="Out of stock"
            />
          </FormField>
        </div>
        <div class="sorting-constructor__item">
          <FormField label="Out of stock link" class="side-form__item aside-input">
            <div class="page-form__hint">If the stock column has zero value the action button will open this link. Leave it empty to make the button disabled for zero stock.</div>
            <v-text-field
              v-model.trim="stock.outOfStockLink"
              placeholder=""
              @blur="trimInternalLink()"
            />
          </FormField>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormField from '@/components/form-field'
import UiHelper from '@/components/ui-helper'

export default {
  name: 'StockConstructor',
  components: {
    FormField,
    UiHelper
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    orderActionEnabled: {
      type: Boolean,
      required: true
    },
    domain: {
      type: String,
      required: true
    },
    v: {
      type: Object
    }
  },
  data () {
    return {
      stock: {}
    }
  },
  computed: {
    mappedCols () {
      return this.cols.map((col) => {
        return { value: col.id, label: col.label }
      })
    }
  },
  watch: {
    stock: {
      deep: true,
      handler: function (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    trimInternalLink () {
      const baseDomain = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_VIEWER_APP_BASE_URL : `https://${this.domain}`
      if (this.stock.outOfStockLink.startsWith(baseDomain)) {
        this.stock.outOfStockLink = this.stock.outOfStockLink.trim().split(baseDomain)[1] || '/'
      }
    }
  },
  created () {
    this.stock = JSON.parse(JSON.stringify(this.value))
  }
}
</script>

<style>
.page-form__hint.error {
  margin-top: 10px;
  color: red;
}
</style>
