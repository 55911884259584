var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "page-form side-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "side-form__section" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.generate.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "FormField",
              {
                staticClass: "side-form__item aside-input",
                attrs: { label: "Prompt" },
              },
              [
                _c("v-textarea", {
                  attrs: { spellcheck: "false" },
                  on: {
                    keydown: function ($event) {
                      if (!$event.type.indexOf("key") && $event.keyCode !== 13)
                        return null
                      if (!$event.metaKey) return null
                      return _vm.generate.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.prompt,
                    callback: function ($$v) {
                      _vm.prompt = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "prompt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "side-form__btns side-form__item aside-input" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn--transparent",
                    attrs: {
                      type: "submit",
                      disabled: _vm.isGenerating || _vm.prompt.trim() === "",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.isGenerating ? "✨ generating..." : "Generate")
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "CollapsableBlock",
        { staticClass: "side-form__section", attrs: { expanded: false } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "side-form__section-title" }, [
              _vm._v("Options"),
            ]),
          ]),
          _c("textarea", {
            staticStyle: {
              "font-size": "10px",
              width: "100%",
              "overflow-x": "scroll",
              "line-height": "1",
              color: "lightslategray",
            },
            attrs: { rows: "50", "data-gramm": "false" },
            domProps: { value: JSON.stringify(_vm.value, null, 2) },
            on: {
              blur: function ($event) {
                return _vm.updateOptions($event.target.value)
              },
            },
          }),
        ],
        2
      ),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "big",
            "attr-type": "submit",
            loading: _vm.loading,
            disabled: _vm.loading,
          },
        },
        [_vm._v("\n    SAVE\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }