var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    attrs: {
      src:
        _vm.viewerBaseUrl +
        `?_preview_mode=1&_preview_generation_id=${_vm.generationId}`,
      frameborder: "0",
      "data-openreplay-capture": "",
      allow: "clipboard-write",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }