var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentRouteIndex === null
        ? _c("ManageRoutes", {
            attrs: { loading: _vm.loading },
            on: {
              "set-current": _vm.setCurrentRoute,
              update: _vm.updateRoute,
              duplicate: _vm.duplicateRoute,
              create: _vm.createRoute,
              delete: _vm.deleteRoute,
              submit: _vm.save,
            },
            model: {
              value: _vm.options.routes,
              callback: function ($$v) {
                _vm.$set(_vm.options, "routes", $$v)
              },
              expression: "options.routes",
            },
          })
        : _vm._e(),
      _vm.currentRouteIndex !== null
        ? _c("RouteForm", {
            attrs: {
              loading: _vm.loading,
              routes: _vm.options.routes,
              currentRouteIndex: _vm.currentRouteIndex,
              domain: _vm.domain,
            },
            on: {
              back: function ($event) {
                return _vm.setCurrentRoute(null)
              },
              submit: _vm.save,
            },
            model: {
              value: _vm.currentRoute,
              callback: function ($$v) {
                _vm.currentRoute = $$v
              },
              expression: "currentRoute",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }