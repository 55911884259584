var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "billing" }, [
      _c("h4", { staticClass: "billing__title" }, [_vm._v("Appsumo Codes")]),
      _c("div", { staticClass: "billing__body" }, [
        _c("div", { staticClass: "billing__grid-wrap" }, [
          _vm.appsumoDeals
            ? _c("div", { staticClass: "billing-grid" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "billing-grid__body" },
                  [
                    _vm._l(_vm.appsumoDeals, function (deal, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "billing-grid__row" },
                        [
                          _vm._m(1, true),
                          _c(
                            "div",
                            { staticClass: "billing-grid__cell is-bold" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(deal.licensesQuantity) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "billing-grid__cell" }, [
                            deal.appsumoCodes.length
                              ? _c("p", [
                                  _vm._v(
                                    "Number of codes applied: " +
                                      _vm._s(deal.appsumoCodes.length)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", {
                            staticClass: "billing-grid__cell align-end",
                          }),
                        ]
                      )
                    }),
                    _c("div", { staticClass: "billing-grid__row is-divided" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "billing-grid__cell is-bold" }, [
                        _c("span", [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.userData.dealOptions.calculatedWebsites
                                  .paidLicenses["appsumo"].applied
                              ) +
                              "\n                  "
                          ),
                          _c("span", { staticClass: "text-color-secondary" }, [
                            _vm._v(
                              "\n                    of " +
                                _vm._s(
                                  _vm.userData.dealOptions.calculatedWebsites
                                    .paidLicenses["appsumo"].total
                                ) +
                                "\n                  "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billing-grid__head" }, [
      _c("div", { staticClass: "billing-grid__cell is-heading" }, [
        _vm._v("Plan"),
      ]),
      _c("div", { staticClass: "billing-grid__cell is-heading" }, [
        _vm._v("Licenses"),
      ]),
      _c("div", { staticClass: "billing-grid__cell is-heading" }),
      _c("div", { staticClass: "billing-grid__cell is-heading" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billing-grid__cell is-bold" }, [
      _c("span", [_vm._v("Appsumo")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billing-grid__cell is-bold" }, [
      _c("span", [_vm._v("Applied: ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }