var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "profile-tab__container" }, [
      _c("h4", { staticClass: "profile-tab__title" }, [
        _vm._v("Affiliate program"),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "Get 30% from each referral payment and up to 12 recurring payments by sharing your referral link"
        ),
      ]),
      _c("p", [
        _c(
          "a",
          {
            staticClass: "profile-tab__button",
            staticStyle: { display: "inline-block" },
            attrs: {
              href: "https://affiliate.spreadsimple.com/",
              target: "_blank",
            },
          },
          [_vm._v("Become a partner")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }