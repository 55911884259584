var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.workspaces
    ? _c(
        "div",
        [
          _c(
            "el-popover",
            {
              staticClass: "workspaces-popover",
              attrs: {
                placement: "bottom-start",
                width: "400",
                trigger: "click",
                "visible-arrow": false,
                "popper-class": "workspaces-popover__menu",
              },
              model: {
                value: _vm.showWorkspacesDropdown,
                callback: function ($$v) {
                  _vm.showWorkspacesDropdown = $$v
                },
                expression: "showWorkspacesDropdown",
              },
            },
            [
              _vm._l(_vm.workspacesInOrder, function (ws, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "workspaces-popover__menu-item",
                    class: { disabled: _vm.wsUserInactive(ws) },
                    on: {
                      click: function ($event) {
                        return _vm.updateCurrentWorkspaceId(ws.id)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "workspaces-popover__user" }, [
                      _c(
                        "div",
                        { staticClass: "workspaces-popover__user-meta" },
                        [
                          _c(
                            "h4",
                            { staticClass: "workspaces-popover__user-title" },
                            [
                              _vm._v(" " + _vm._s(ws.title) + " "),
                              ws.main
                                ? _c("span", [_vm._v("(YOUR)")])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "workspaces-popover__user-subtitle",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(ws.users.length) +
                                  " member" +
                                  _vm._s(ws.users.length > 1 ? "s" : "") +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "workspaces-popover__user-subtitle",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.wsUserInactive(ws)
                                      ? "Access denied, please contact workspace owner"
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm.currentWorkspaceId === ws.id
                      ? _c("i", {
                          staticClass:
                            "workspaces-popover__menu-item-check icon-check2",
                        })
                      : _vm._e(),
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "workspaces-popover__trigger",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _vm.currentWorkspace
                    ? _c("div", { staticClass: "workspaces-popover__user" }, [
                        _c(
                          "div",
                          { staticClass: "workspaces-popover__user-meta" },
                          [
                            _c(
                              "h4",
                              { staticClass: "workspaces-popover__user-title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.currentWorkspace.title) + " "
                                ),
                                _vm.currentWorkspace.main
                                  ? _c("span", [_vm._v("(YOUR)")])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "workspaces-popover__user-subtitle",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.currentWorkspace.users.length) +
                                    " member" +
                                    _vm._s(
                                      _vm.currentWorkspace.users.length > 1
                                        ? "s"
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("i", {
                    staticClass:
                      "workspaces-popover__trigger-chevron icon-arrow-down",
                    class: { "is-flipped": _vm.showWorkspacesDropdown },
                  }),
                ]
              ),
            ],
            2
          ),
          _vm.currentWorkspaceId === _vm.mainWorkspaceId
            ? _c(
                "UiBtn",
                {
                  attrs: {
                    type: "secondary",
                    "full-width": "",
                    to: { name: "account-workspaces" },
                  },
                },
                [_vm._v("\n    Settings\n  ")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }