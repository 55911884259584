var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("div", { staticClass: "loader__wrapper" }, [
        _c("div", { staticClass: "loader__container" }, [
          _c("img", {
            staticClass: "sv-img-reset",
            attrs: { src: require("./preloader.gif"), alt: "" },
          }),
          _vm.showProgress
            ? _c("div", { staticClass: "loader__progress" }, [
                _c("div", {
                  staticClass: "loader__progress-bar",
                  style: `width: ${_vm.progressValue}%; transition: width ${
                    _vm.progressSpeed / 1000
                  }s linear`,
                }),
              ])
            : _vm._e(),
          _vm.content
            ? _c("p", { staticClass: "loader__string sv-text-reset" }, [
                _vm._v(_vm._s(_vm.content)),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }