var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-video" }, [
    _c("div", { staticClass: "page-video__container" }, [
      _c(
        "video",
        {
          staticClass: "page-video__video",
          attrs: { autoplay: "", loop: "", muted: "", controls: "" },
          domProps: { muted: true },
        },
        [
          _c("source", {
            attrs: {
              src: `/video/helper-popup/${_vm.$route.query.show}.mp4`,
              type: "video/mp4",
            },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }