var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "team-dialog",
      class: { "is-countdown-view": _vm.type === "countdown" },
      attrs: {
        "show-close": _vm.type !== "countdown",
        visible: _vm.visible,
        "destroy-on-close": true,
      },
      on: {
        close: function ($event) {
          _vm.type !== "countdown" ? _vm.$emit("close") : null
        },
      },
    },
    [
      _c("transition", { attrs: { mode: "out-in", name: "fade" } }, [
        _vm.type === "countdown"
          ? _c(
              "div",
              { key: "1", staticClass: "team-dialog__container" },
              [
                _c("Countdown", {
                  staticClass: "team-dialog__timer",
                  attrs: {
                    time: _vm.takeoverRequestTimerLimit,
                    showProgressBar: true,
                  },
                  on: {
                    "time-end": function ($event) {
                      return _vm.$emit("countdown-end")
                    },
                  },
                }),
                _c("p", { staticClass: "team-dialog__string align-center" }, [
                  _vm._v("\n        Please wait for "),
                  _c("span", [_vm._v(_vm._s(_vm.currentEditor))]),
                  _vm._v(
                    " to confirm. If this user doesn’t respond, you will be automatically redirected to the website editor.\n      "
                  ),
                ]),
              ],
              1
            )
          : _vm.type === "warning"
          ? _c("div", { key: "2", staticClass: "team-dialog__container" }, [
              _c("h4", { staticClass: "team-dialog__title" }, [
                _vm._v(" Are you sure? "),
              ]),
              _c("div", { staticClass: "team-dialog__users-wrap" }, [
                _vm._v("\n        😱\n      "),
              ]),
              _c("p", { staticClass: "team-dialog__string" }, [
                _vm._v(
                  "\n        This content is currently locked. If you take over, "
                ),
                _c("span", [_vm._v(_vm._s(_vm.currentEditor))]),
                _vm._v(
                  " will be blocked from continuing to edit. Do you want to take over?\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "team-dialog__btns" },
                [
                  _c(
                    "UiBtn",
                    {
                      attrs: { type: "secondary", uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                  _c(
                    "UiBtn",
                    {
                      attrs: { uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("takeover-request")
                        },
                      },
                    },
                    [_vm._v("\n          Take over\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.type === "takeover"
          ? _c("div", { key: "3", staticClass: "team-dialog__container" }, [
              _c("h4", { staticClass: "team-dialog__title" }, [
                _vm._v(" Take over request "),
              ]),
              _c("div", { staticClass: "team-dialog__users-wrap" }, [
                _vm._v("\n        ✏️\n      "),
              ]),
              _c("p", { staticClass: "team-dialog__string" }, [
                _c("span", [_vm._v(_vm._s(_vm.currentEditor))]),
                _vm._v(" wants to take over the editing.\n        Click "),
                _c("b", [_vm._v("Decline")]),
                _vm._v(" to keep working on this website.\n        Click "),
                _c("b", [_vm._v("Accept")]),
                _vm._v(
                  " to finish editing the website without saving the changes you've made.\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "team-dialog__btns" },
                [
                  _c(
                    "UiBtn",
                    {
                      attrs: { type: "secondary", uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("reject-takeover")
                        },
                      },
                    },
                    [_vm._v("\n          Decline\n        ")]
                  ),
                  _c(
                    "UiBtn",
                    {
                      attrs: { uppercase: "", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("allow-takeover")
                        },
                      },
                    },
                    [
                      _vm._v("\n          Accept\n          ("),
                      _c("Countdown", {
                        attrs: { time: _vm.takeoverResponseTimerLimit },
                        on: {
                          "time-end": function ($event) {
                            return _vm.$emit("allow-takeover")
                          },
                        },
                      }),
                      _vm._v(")\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _vm.type === "takeover-decline"
          ? _c("div", { key: "4", staticClass: "team-dialog__container" }, [
              _c("h4", { staticClass: "team-dialog__title" }, [
                _vm._v(" Takeover request declined "),
              ]),
              _c("div", { staticClass: "team-dialog__users-wrap" }, [
                _vm._v("\n        🙅\n      "),
              ]),
              _c("p", { staticClass: "team-dialog__string" }, [
                _c("span", [_vm._v(_vm._s(_vm.currentEditor))]),
                _vm._v(
                  " declined your takeover request. You can try again later.\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "team-dialog__btns" },
                [
                  _c(
                    "UiBtn",
                    {
                      attrs: { uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("\n          OK\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.type === "takeover-fail"
          ? _c("div", { key: "5", staticClass: "team-dialog__container" }, [
              _c("h4", { staticClass: "team-dialog__title" }, [
                _vm._v(" Takeover request failed "),
              ]),
              _c("div", { staticClass: "team-dialog__users-wrap" }, [
                _vm._v("\n        🙅\n      "),
              ]),
              _c("p", { staticClass: "team-dialog__string" }, [
                _vm._v("\n        Please try again later.\n      "),
              ]),
              _c(
                "div",
                { staticClass: "team-dialog__btns" },
                [
                  _c(
                    "UiBtn",
                    {
                      attrs: { uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("\n          OK\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm.type === "takeover-success"
          ? _c("div", { key: "6", staticClass: "team-dialog__container" }, [
              _c("h4", { staticClass: "team-dialog__title" }, [
                _vm._v(" Takeover request accepted "),
              ]),
              _c("div", { staticClass: "team-dialog__users-wrap" }, [
                _vm._v("\n        🙌\n      "),
              ]),
              _c("p", { staticClass: "team-dialog__string" }, [
                _vm._v("\n        You can start editing\n      "),
              ]),
              _c(
                "div",
                { staticClass: "team-dialog__btns" },
                [
                  _c(
                    "UiBtn",
                    {
                      attrs: { uppercase: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("\n          OK\n        ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }