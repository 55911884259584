var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      directives: [
        {
          name: "scroll-class",
          rawName: "v-scroll-class:scrolled",
          value: 70,
          expression: "70",
          arg: "scrolled",
        },
      ],
      staticClass: "header for-manage",
    },
    [
      _vm.showFreeLimitsBanner
        ? _c(
            "div",
            { staticClass: "pl-20 pr-20 pt-12 header-msg-box" },
            [
              _c("UiMsgBox", { attrs: { color: "warning" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex align-items-center justify-content-between",
                  },
                  [
                    _c("span", [
                      _c("span", { staticClass: "text--weight-600" }, [
                        _vm._v("Important Update:"),
                      ]),
                      _vm._v(" Changes to custom row limits\n        "),
                    ]),
                    _c(
                      "UiBtn",
                      {
                        attrs: { size: "s", type: "warning" },
                        on: {
                          click: function ($event) {
                            _vm.showFreeLimitsModal = true
                          },
                        },
                      },
                      [_vm._v("Learn More")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("TrialNotificationBar", { attrs: { spreadViewId: null } }),
      _c("div", { staticClass: "header__container" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "header__right-side" },
          [_c("ProfileDropdown", { attrs: { userData: _vm.userData || {} } })],
          1
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            modalName: "Changes to custom row limits",
            hasCloseButton: true,
          },
          on: {
            close: function ($event) {
              _vm.showFreeLimitsModal = false
            },
          },
          model: {
            value: _vm.showFreeLimitsModal,
            callback: function ($$v) {
              _vm.showFreeLimitsModal = $$v
            },
            expression: "showFreeLimitsModal",
          },
        },
        [
          _c("div", { staticClass: "pl-40 pr-40 pt-20 pb-40 text--size-m" }, [
            _c(
              "p",
              [
                _vm._v("\n        Starting on "),
                _c("span", { staticClass: "text--weight-600" }, [
                  _vm._v("February 20th, 2025"),
                ]),
                _vm._v(
                  ", all websites created on the free plan before June 27th, 2024,\n        will be limited to 50 Google Sheet rows per website, in line with those created after that date.\n        If you need more Google sheet rows, please consider upgrading your plan.\n        Use promo code "
                ),
                _c("UiTag", {
                  staticClass: "text--weight-600",
                  attrs: {
                    label: "UPGRADE50OFF",
                    color: "primary",
                    size: "xs",
                  },
                }),
                _vm._v(" till "),
                _c("span", { staticClass: "text--weight-600" }, [
                  _vm._v("December 20th"),
                ]),
                _vm._v(
                  " to get 50% off your first payment.\n        You can check out our pricing and available features "
                ),
                _c(
                  "a",
                  {
                    staticClass: "is-link",
                    attrs: { href: "https://spreadsimple.com/pricing/" },
                  },
                  [_vm._v("here")]
                ),
                _vm._v(".\n      "),
              ],
              1
            ),
            _c("p", { staticClass: "mt-16" }, [
              _vm._v(
                "\n        We understand that changes can be challenging,\n        but please know that these adjustments are necessary for the future growth of our platform.\n        We`re committed to continuously improving our services and providing you with more and\n        more features. Thank you for your understanding.\n      "
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { staticClass: "header__logo", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "/img/logo-main.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }