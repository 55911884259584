var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "cta-constructor" },
      [
        _c("h6", { staticClass: "aside-field-label" }, [
          _vm._v(_vm._s(_vm.widgetLabel)),
        ]),
        _c(
          "div",
          {
            staticClass: "cta-constructor__trigger",
            class: _vm.buttonState.style,
            style: _vm.buttonStyleComputed,
            on: {
              click: function ($event) {
                _vm.dropdownOpened = !_vm.dropdownOpened
              },
            },
          },
          [_vm._v("\n    " + _vm._s(_vm.buttonTitle) + "\n  ")]
        ),
        _c("transition", { attrs: { name: "fade" } }, [
          _vm.dropdownOpened
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: () => (_vm.dropdownOpened = false),
                      expression: "() => dropdownOpened = false",
                    },
                  ],
                  staticClass: "cta-constructor__menu",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "content-form__switch-field",
                      staticStyle: { "margin-top": "10px" },
                      style: {
                        "margin-bottom": _vm.buttonState.enabled ? "20px" : "0",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "content-form__switcher-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "content-form__switcher-label" },
                            [_vm._v("Enable button")]
                          ),
                          _c(
                            "label",
                            {
                              staticClass: "switcher",
                              class: { selected: _vm.buttonState.enabled },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.buttonState.enabled,
                                    expression: "buttonState.enabled",
                                  },
                                ],
                                staticClass: "switcher__input",
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.buttonState.enabled
                                  )
                                    ? _vm._i(_vm.buttonState.enabled, null) > -1
                                    : _vm.buttonState.enabled,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.buttonState.enabled,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.buttonState,
                                              "enabled",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.buttonState,
                                              "enabled",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.buttonState,
                                          "enabled",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.$trackFeature(
                                        "content",
                                        `ctaButton:${_vm.buttonState.style}`,
                                        _vm.buttonState.enabled
                                      )
                                    },
                                  ],
                                },
                              }),
                              _c("span", {
                                staticClass: "switcher__indicator",
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.buttonState.enabled,
                          expression: "buttonState.enabled",
                        },
                      ],
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "cta-constructor__label-field" },
                        [
                          _c("h6", { staticClass: "aside-field-label" }, [
                            _vm._v("Label:"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "cta-constructor__input-wrap" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.buttonState.title,
                                    expression: "buttonState.title",
                                  },
                                ],
                                staticClass: "cta-constructor__input",
                                attrs: { placeholder: `${_vm.buttonTitle}` },
                                domProps: { value: _vm.buttonState.title },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.buttonState,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c("ColorPicker", {
                                staticClass: "cta-constructor__color-picker",
                                model: {
                                  value: _vm.buttonState.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.buttonState, "color", $$v)
                                  },
                                  expression: "buttonState.color",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "cta-constructor__radio-wrap" },
                        [
                          _c("h6", { staticClass: "aside-field-label" }, [
                            _vm._v("Button style"),
                          ]),
                          _vm._l(
                            _vm.buttonStyleOptions,
                            function (option, index) {
                              return _c(
                                "ProOptionWrapper",
                                {
                                  key: index,
                                  staticClass: "cta-pro",
                                  attrs: {
                                    featureName:
                                      option.value === "order" ||
                                      option.value === "details" ||
                                      option.value === "embed"
                                        ? "ctaButtons"
                                        : "byDefaultAllowedFeature",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "cta-constructor__radio",
                                      class: [
                                        {
                                          active:
                                            option.value === _vm.buttonStyle,
                                        },
                                        option.value,
                                      ],
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "cta-constructor__radio-input",
                                        attrs: {
                                          type: "radio",
                                          name: "button",
                                        },
                                        domProps: { value: option.value },
                                        on: {
                                          input: (e) =>
                                            _vm.updateButtonStyle(
                                              e.target.value
                                            ),
                                        },
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "cta-constructor__radio-indicator",
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "cta-constructor__radio-label",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(option.label) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "cta-constructor__radio-wrap" },
                        [
                          _c("h6", { staticClass: "aside-field-label" }, [
                            _vm._v("Button type"),
                          ]),
                          _vm._l(
                            _vm.buttonTypeOptions,
                            function (option, index) {
                              return _c(
                                "ProOptionWrapper",
                                {
                                  key: index,
                                  staticClass: "cta-pro",
                                  attrs: {
                                    featureName:
                                      option.value === "order" ||
                                      option.value === "details" ||
                                      option.value === "embed"
                                        ? "ctaButtons"
                                        : "byDefaultAllowedFeature",
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "cta-constructor__radio",
                                      class: [
                                        {
                                          active:
                                            option.value === _vm.buttonType,
                                        },
                                        option.value,
                                      ],
                                    },
                                    [
                                      _c("input", {
                                        staticClass:
                                          "cta-constructor__radio-input",
                                        attrs: {
                                          type: "radio",
                                          name: "button",
                                        },
                                        domProps: { value: option.value },
                                        on: {
                                          input: (e) =>
                                            _vm.updateButtonType(
                                              e.target.value
                                            ),
                                        },
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "cta-constructor__radio-indicator",
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "cta-constructor__radio-icon",
                                        class: `icon-${option.icon}`,
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "cta-constructor__radio-label",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(option.label) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "transition-group",
                        { attrs: { name: "fade3", mode: "out-in" } },
                        [
                          _vm.buttonType === "link" ||
                          _vm.buttonType === "embed"
                            ? _c(
                                "div",
                                {
                                  key: "select",
                                  staticClass:
                                    "cta-constructor__select-wrap aside-select paddingless",
                                },
                                [
                                  _vm.buttonType === "link"
                                    ? _c(
                                        "h6",
                                        { staticClass: "aside-field-label" },
                                        [_vm._v("Link for redirect")]
                                      )
                                    : _vm._e(),
                                  _vm.buttonType === "embed"
                                    ? _c(
                                        "h6",
                                        { staticClass: "aside-field-label" },
                                        [_vm._v("IFrame URL")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-select",
                                    {
                                      attrs: {
                                        attach: "",
                                        "menu-props": "offsetY",
                                        items: _vm.cols,
                                        "item-text": "label",
                                        "item-value": "id",
                                        clearable: "",
                                        "append-icon": "keyboard_arrow_down",
                                      },
                                      model: {
                                        value: _vm.$v.buttonState.id.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.buttonState.id,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.buttonState.id.$model",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "list-title",
                                          attrs: { slot: "prepend-item" },
                                          slot: "prepend-item",
                                        },
                                        [_vm._v("Select sheet column")]
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "aside-select__field-icon icon-arrow-down",
                                  }),
                                  !_vm.$v.buttonState.id.$model
                                    ? _c(
                                        "p",
                                        {
                                          key: "error-message",
                                          staticClass: "form-field__error",
                                        },
                                        [
                                          _vm._v(
                                            "\n                This field shouldn't be empty\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.buttonType === "order"
                            ? _c(
                                "div",
                                {
                                  key: "input",
                                  staticClass: "cta-constructor__email-field",
                                },
                                [
                                  _c(
                                    "h6",
                                    { staticClass: "aside-field-label" },
                                    [
                                      _vm._v(
                                        "To specify notifications options proceed to\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cta-constructor__email-field__link",
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit(
                                                "change-tab",
                                                "checkout-options"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v('"Checkout" tab')]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.buttonType === "details" &&
                          !_vm.enableSingleItemView
                            ? _c("div", { key: "error-message-details" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass: "cta-constructor__label-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Make sure that details page is enabled\n              "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }