var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "side-form" },
    [
      _vm.value.length
        ? _c(
            "div",
            { staticClass: "side-form__item" },
            [
              _c(
                "draggable",
                {
                  staticClass: "draggable",
                  attrs: {
                    value: _vm.value,
                    tag: "div",
                    handle: ".draggable__item-trigger",
                  },
                  on: { input: (val) => _vm.onDrag(val) },
                },
                _vm._l(_vm.value, function (field, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "draggable__item",
                      class: { hidden: !field.enabled },
                    },
                    [
                      _c("div", {
                        staticClass: "draggable__item-trigger icon-draggable",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "draggable__item-title-wrap",
                          on: {
                            click: function ($event) {
                              return _vm.onEdit(field, index)
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "draggable__item-title" }, [
                            _vm._v(_vm._s(field.label) + " "),
                            field.validations &&
                            field.validations.some(
                              (rule) => rule.name === "required"
                            )
                              ? _c("span", [_vm._v("*")])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "draggable__item-controls" },
                        [
                          _c(
                            "el-dropdown",
                            {
                              staticClass: "draggable__item-control",
                              attrs: { trigger: "click" },
                            },
                            [
                              _c("i", { staticClass: "icon-gear" }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "draggable__dropdown-menu",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.onEdit(field, index)
                                        },
                                      },
                                    },
                                    [_vm._v("Edit")]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.onChangeVisibility(index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            field.enabled ? "Disable" : "Enable"
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  !field.isBuiltIn
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.onDelete(index)
                                            },
                                          },
                                        },
                                        [_vm._v("Delete")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "side-form__item side-form__btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--transparent",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.formBuilderModalOpened = true
              },
            },
          },
          [_vm._v("+ Add custom field")]
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            closeOnClickOutside: false,
            modalName:
              _vm.currentEditingField !== null
                ? "Edit field"
                : "Add custom field",
            hasCloseButton: true,
          },
          model: {
            value: _vm.formBuilderModalOpened,
            callback: function ($$v) {
              _vm.formBuilderModalOpened = $$v
            },
            expression: "formBuilderModalOpened",
          },
        },
        [
          _c("FormFieldBuilder", {
            attrs: {
              value: _vm.currentEditingField,
              existingFormFields: _vm.value,
            },
            on: {
              "on-add": _vm.onAddField,
              "on-edit": _vm.onUpdateField,
              "cancel-submission": _vm.closeModalAndCleanData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }