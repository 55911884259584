var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-form__switch-field" }, [
    _c("div", { staticClass: "content-form__switcher-wrap" }, [
      _c(
        "div",
        { staticClass: "content-form__switcher-label" },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("pageManageSheet.settings.enableSearch")) +
              "\n        "
          ),
          _c("UiHelper", {
            staticClass: "va-bottom ml-2",
            attrs: {
              popoverFeature: "search",
              showCloseBtn: "",
              videoData: {
                src: "/video/helper-popup/search.mp4",
                label: "Learn how to set up search",
                btns: [
                  {
                    label: "Learn more",
                    to: "https://help.spreadsimple.com/en/article/how-to-set-up-search-1h1yu04",
                    externalResource: true,
                    color: "secondary-blue",
                    event: {
                      type: "goToHelp",
                    },
                  },
                  {
                    label: "Watch video",
                    to: { name: "help-video", query: { show: "search" } },
                    event: {
                      type: "goToVideo",
                    },
                  },
                ],
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "switcher", class: { selected: _vm.search.enabled } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search.enabled,
                expression: "search.enabled",
              },
            ],
            staticClass: "switcher__input",
            attrs: { type: "checkbox", value: "tiles" },
            domProps: {
              checked: Array.isArray(_vm.search.enabled)
                ? _vm._i(_vm.search.enabled, "tiles") > -1
                : _vm.search.enabled,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.search.enabled,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "tiles",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.search, "enabled", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.search,
                          "enabled",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.search, "enabled", $$c)
                  }
                },
                function ($event) {
                  return _vm.$trackFeature(
                    "content",
                    "search",
                    _vm.search.enabled
                  )
                },
              ],
            },
          }),
          _c("span", { staticClass: "switcher__indicator" }),
        ]
      ),
    ]),
    _vm.search.enabled
      ? _c(
          "div",
          {
            staticClass:
              "content-form__switch-hidden-field aside-select paddingless",
          },
          [
            _c(
              "div",
              { staticClass: "content-form__highlighted" },
              [
                _c(
                  "div",
                  { staticClass: "sorting-constructor__item" },
                  [
                    _c(
                      "FormField",
                      {
                        staticClass: "side-form__item aside-input",
                        attrs: { label: "Search placeholder" },
                      },
                      [
                        _c("v-text-field", {
                          attrs: { placeholder: _vm.msg.searchPlaceholder },
                          model: {
                            value: _vm.search.placeholder,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.search,
                                "placeholder",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "search.placeholder",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h6", { staticClass: "aside-field-label" }, [
                  _vm._v("Search by columns"),
                ]),
                _c("v-select", {
                  attrs: {
                    value: _vm.search.searchByColumns,
                    items: _vm.cols,
                    "item-text": "label",
                    "item-value": "id",
                    attach: "",
                    "menu-props": "offsetY",
                    chips: "",
                    "deletable-chips": "",
                    multiple: "",
                    placeholder: "Choose options",
                    "append-icon": "keyboard_arrow_down",
                  },
                  on: { change: _vm.updateSearchableColumns },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "DraggableChip",
                              {
                                attrs: {
                                  id: data.index,
                                  value: _vm.search.searchByColumns,
                                },
                                on: { input: _vm.updateSearchableColumns },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(data.item.label) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    799751894
                  ),
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }