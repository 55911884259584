<template>
  <div>
    <div class="cta-constructor">
      <!-- <FormField
        :label="widgetLabel"
        :helperVideoData="{
          src: '/video/helper-popup/buttons.mov',
          label: 'How to use buttons'
        }"
        helperTriggerAction="click"
      >
    </FormField> -->
    <h6 class="aside-field-label">{{widgetLabel}}</h6>
    <div
      class="cta-constructor__trigger"
      :style="buttonStyleComputed"
      :class="buttonState.style"
      @click="dropdownOpened = !dropdownOpened"
    >
      {{ buttonTitle }}
    </div>

      <transition name="fade">

        <div v-if="dropdownOpened" v-click-outside="() => dropdownOpened = false" class="cta-constructor__menu" >

        <div class="content-form__switch-field"
          style="margin-top: 10px"
          :style="{'margin-bottom': buttonState.enabled ? '20px':'0'}"
        >
          <div class="content-form__switcher-wrap">
            <div class="content-form__switcher-label">Enable button</div>
              <label
                :class="{ 'selected': buttonState.enabled }"
                class="switcher"
              >
                <input
                  v-model="buttonState.enabled"
                  class="switcher__input" type="checkbox"
                  @change="$trackFeature('content', `ctaButton:${buttonState.style}`, buttonState.enabled)"
                >
                <span class="switcher__indicator"></span>
              </label>
          </div>
        </div>

          <div v-show="buttonState.enabled">
            <div class="cta-constructor__label-field">
              <h6 class="aside-field-label">Label:</h6>
              <div class="cta-constructor__input-wrap">
                <input v-model="buttonState.title" class="cta-constructor__input" :placeholder=" `${buttonTitle}` "/>
                <ColorPicker v-model="buttonState.color" class="cta-constructor__color-picker"/>
              </div>
            </div>

            <div class="cta-constructor__radio-wrap">
              <h6 class="aside-field-label">Button style</h6>
              <ProOptionWrapper
                v-for="(option, index) in buttonStyleOptions"
                :key="index"
                :featureName="option.value === 'order' || option.value === 'details' || option.value === 'embed' ? 'ctaButtons' : 'byDefaultAllowedFeature'"
                class="cta-pro"
              >
                <label
                  :class="[
                    {'active': option.value === buttonStyle},
                    option.value,
                  ]"
                  class="cta-constructor__radio"
                >
                  <input
                    :value="option.value"
                    type="radio"
                    name="button"
                    class="cta-constructor__radio-input"
                    @input="e => updateButtonStyle(e.target.value)"
                  />
                  <div class="cta-constructor__radio-indicator"></div>
                  <p class="cta-constructor__radio-label">
                    {{ option.label }}
                  </p>
                </label>
              </ProOptionWrapper>
            </div>

            <div class="cta-constructor__radio-wrap">
              <h6 class="aside-field-label">Button type</h6>
              <ProOptionWrapper
                v-for="(option, index) in buttonTypeOptions"
                :key="index"
                :featureName="option.value === 'order' || option.value === 'details' || option.value === 'embed' ? 'ctaButtons' : 'byDefaultAllowedFeature'"
                class="cta-pro"
              >
                <label
                  :class="[
                    {'active': option.value === buttonType},
                    option.value,
                  ]"
                  class="cta-constructor__radio"
                >
                  <input
                    :value="option.value"
                    type="radio"
                    name="button"
                    class="cta-constructor__radio-input"
                    @input="e => updateButtonType(e.target.value)"
                  />
                  <div class="cta-constructor__radio-indicator"></div>
                  <i class="cta-constructor__radio-icon" :class="`icon-${option.icon}`" ></i>
                  <p class="cta-constructor__radio-label">
                    {{ option.label }}
                  </p>
                </label>
              </ProOptionWrapper>
            </div>

            <transition-group name="fade3" mode="out-in">
              <div
                v-if="buttonType === 'link' || buttonType === 'embed'"
                key="select"
                class="cta-constructor__select-wrap aside-select paddingless"
                >
                <h6 v-if="buttonType === 'link'" class="aside-field-label">Link for redirect</h6>
                <h6 v-if="buttonType === 'embed'" class="aside-field-label">IFrame URL</h6>
                <v-select
                  v-model="$v.buttonState.id.$model"
                  attach
                  menu-props="offsetY"
                  :items="cols"
                  item-text="label"
                  item-value="id"
                  clearable
                  append-icon="keyboard_arrow_down"
                >
                  <div slot="prepend-item" class="list-title">Select sheet column</div>
                </v-select>
                <i class="aside-select__field-icon icon-arrow-down"></i>
                <p
                  v-if="!$v.buttonState.id.$model"
                  key="error-message"
                  class="form-field__error"
                >
                  This field shouldn't be empty
                </p>
              </div>

              <div v-if="buttonType === 'order'" key="input" class="cta-constructor__email-field">
                <h6 class="aside-field-label">To specify notifications options proceed to
                  <span class="cta-constructor__email-field__link" @click="$emit('change-tab', 'checkout-options')">"Checkout" tab</span>
                </h6>

              </div>
              <div v-if="buttonType === 'details' && !enableSingleItemView" key="error-message-details">
                <p class="cta-constructor__label-error">
                  Make sure that details page is enabled
                </p>
              </div>

            </transition-group>

          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators'
import vClickOutside from 'v-click-outside'
// import FormField from '@/components/form-field'
import ColorPicker from '@/components/color-picker/'
import ProOptionWrapper from '@/components/pro-option-wrapper'
import Vuelidate from 'vuelidate'
import Vue from 'vue'
import { allowFeature } from '@/modules/features-checker'
import { mapState } from 'vuex'
Vue.use(Vuelidate)
export default {
  name: 'CtaButtonConstructor',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    ColorPicker,
    ProOptionWrapper
    // FormField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    cols: {
      type: Array,
      required: true
    },
    widgetLabel: {
      type: String,
      default: 'Add button'
    },
    msg: {
      type: Object,
      required: true
    },
    onDetailsPage: {
      type: Boolean,
      default: false
    },
    enableSingleItemView: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      buttonState: {},
      dropdownOpened: false,
      buttonMenu: false,
      buttonType: 'link',
      buttonStyle: 'is-primary',
      validationStatus: ''
    }
  },
  validations: {
    buttonState: {
      id: {
        required: requiredIf(function () {
          return this.buttonType === 'link' || this.buttonType === 'embed'
        })
      }
    }
  },
  computed: {
    ...mapState('app', [
      'userData',
      'currentSpreadViewData'
    ]),
    buttonTitle () {
      return this.value.title ? this.value.title : this.msg.buyButton
    },
    buttonStyleComputed () {
      return this.buttonState.style === 'is-primary' ? { backgroundColor: this.buttonState.color } : { color: this.buttonState.color, backgroundColor: 'transparent' }
    },
    buttonStyleOptions () {
      return [
        { label: 'Primary style', value: 'is-primary' },
        { label: 'Secondary style', value: 'is-secondary' }
      ]
    },
    buttonTypeOptions () {
      return this.onDetailsPage
        ? [
          { label: 'Link', icon: 'link-2', value: 'link' },
          { label: 'Order Form', icon: 'popup-order-form', value: 'order' },
          { label: 'Embedded link (popup)', icon: 'popup-order-form', value: 'embed' }
        ] : [
          { label: 'Link', icon: 'link2', value: 'link' },
          { label: 'Order Form', icon: 'popup-order-form', value: 'order' },
          { label: 'Details page', icon: 'popup-det-page', value: 'details' },
          { label: 'Embedded link (popup)', icon: 'popup-order-form', value: 'embed' }
        ]
    }
  },
  watch: {
    buttonState: {
      deep: true,
      handler: function () {
        this.submitCtaForm()
      }
    }
  },

  methods: {
    submitCtaForm () {
      if (this.buttonState.id === undefined) {
        this.buttonState.id = null
      }
      this.$emit('input', JSON.parse(JSON.stringify(this.buttonState)))
    },

    updateButtonType (val) {
      this.buttonType = val
      this.buttonState.type = val
    },

    updateButtonStyle (val) {
      this.buttonStyle = val
      this.buttonState.style = val
    }
  },

  beforeDestroy () {
    this.buttonState = {}
  },

  created () {
    this.buttonState = JSON.parse(JSON.stringify(this.value))
    const allowed = allowFeature('ctaButtons', {
      user: this.userData,
      website: this.currentSpreadViewData
    })
    if (!allowed && this.buttonState.type === 'order') {
      this.buttonType = 'link'
      this.buttonState.type = 'link'
      this.buttonState.id = null
      this.$emit('input', JSON.parse(JSON.stringify(this.buttonState)))
    } else if (this.buttonState.type !== '') {
      this.buttonType = this.buttonState.type
    } else {
      this.buttonState.type = this.buttonType
    }

    this.buttonStyle = this.buttonState.style
  }
}
</script>
