var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closePicker,
          expression: "closePicker",
        },
      ],
      staticClass: "color-picker",
      style: { backgroundColor: _vm.color.hex },
      on: {
        click: function ($event) {
          _vm.pickerVisible = true
        },
      },
    },
    [
      _c("chrome-picker", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pickerVisible,
            expression: "pickerVisible",
          },
        ],
        attrs: { value: _vm.color },
        on: { input: _vm.updateValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }