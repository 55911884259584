<template>
  <div class="main">
    <LayoutHeader v-if="$route.name !== 'help-video'" />
    <slot></slot> <!-- page content will be rendered instead of <slot></slot> -->
  </div>
</template>
<script>
import LayoutHeader from './header'
export default {
  name: 'LayoutEmpty',
  components: {
    LayoutHeader
  }
}
</script>
