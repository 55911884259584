var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        value: _vm.opened,
        modalName: _vm.unsplashEnabled
          ? "Search Unsplash for images"
          : "Upload image",
      },
      on: {
        input: function ($event) {
          return _vm.$emit("on-close")
        },
      },
    },
    [
      _vm.unsplashEnabled
        ? _c("div", { staticClass: "unsplash-modal__body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.getUnsplashImages.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "unsplash-modal__search" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchKey,
                        expression: "searchKey",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: "Search images by topics or colors",
                    },
                    domProps: { value: _vm.searchKey },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchKey = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "unspalsh-modal__button",
                      class: { loading: _vm.$async.unsplashSearch.$pending },
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Search")]
                  ),
                ]),
                _c(
                  "ul",
                  {
                    staticClass: "unsplash-chips",
                    class: { disabled: _vm.$async.unsplashSearch.$pending },
                  },
                  _vm._l(_vm.unsplashTips, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.searchByTip(item)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(item) + "\n        ")]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm.paginatedData
              ? _c(
                  "div",
                  { staticClass: "unsplash-modal__results" },
                  [
                    _vm._l(_vm.paginatedData.data, function (image, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "unsplash-modal__result-item",
                        },
                        [
                          _c("div", {
                            staticClass: "unsplash-modal__result-item-inner",
                            style: {
                              backgroundImage: `url('${image.urls.thumb}')`,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updateValue(image)
                              },
                            },
                          }),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${image.user.links.html}?${_vm.unsplashReferralLink}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(image.user.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    _vm.unsplashResults.length > _vm.itemsPerPage
                      ? _c("Paginator", {
                          attrs: { data: _vm.paginatedData },
                          on: {
                            "change-page": (val) =>
                              _vm.paginator(
                                _vm.unsplashResults,
                                val,
                                _vm.itemsPerPage
                              ),
                          },
                        })
                      : _vm._e(),
                    _vm.unsplashResults.length
                      ? _c("div", { staticClass: "unsplash__copyright" }, [
                          _vm._v("\n        Photos from "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `https://unsplash.com/?${_vm.unsplashReferralLink}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Unsplash")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "unsplash-modal__download-section" }, [
        _vm.unsplashEnabled
          ? _c(
              "div",
              { staticClass: "unsplash-modal__download-section-title" },
              [_vm._v("\n      Or upload file from your computer\n    ")]
            )
          : _vm._e(),
        _c(
          "form",
          {
            ref: "uploadForm",
            attrs: { enctype: "multipart/form-data" },
            on: {
              change: function ($event) {
                $event.preventDefault()
                return _vm.uploadFile.apply(null, arguments)
              },
            },
          },
          [
            _c("label", [
              _c("input", {
                ref: "image",
                staticStyle: { display: "none" },
                attrs: { type: "file" },
              }),
              _c(
                "div",
                { staticClass: "uploader__btn" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      !_vm.$async.uploadFiles.$pending
                        ? _c("div", { key: "btn" }, [
                            _vm._v("\n              Upload\n              "),
                            _c("i", {
                              staticClass: "uploader__btn-icon icon-upload2",
                            }),
                          ])
                        : _c("div", { key: "btn2" }, [
                            _vm._v("\n              Loading\n            "),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }