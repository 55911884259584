var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      staticClass: "profile-dropdown",
      attrs: { "hide-on-click": "", trigger: "click" },
      on: {
        command: _vm.handleCommand,
        "visible-change": (val) => (_vm.dropdownOpened = val),
      },
    },
    [
      _c("div", { staticClass: "profile-dropdown__trigger" }, [
        _c("div", { staticClass: "profile-dropdown__avatar" }, [
          _vm._v(_vm._s(_vm.userInitials) + " "),
        ]),
        _c("i", {
          staticClass: "profile-dropdown__trigger-icon icon-arrow-down",
          class: { "is-dropdown-opened": _vm.dropdownOpened },
        }),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "profile-dropdown__menu",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c("div", { staticClass: "profile-dropdown__menu-header" }, [
            _vm._v("\n      " + _vm._s(_vm.userData.email || "") + "\n    "),
          ]),
          _c(
            "div",
            { staticClass: "profile-dropdown__menu-nav" },
            [
              _vm._l(_vm.navItems, function (navItem) {
                return _c(
                  "el-dropdown-item",
                  {
                    key: navItem.routeName,
                    attrs: { command: navItem.routeName },
                  },
                  [_vm._v("\n        " + _vm._s(navItem.title) + "\n      ")]
                )
              }),
              _vm.userData.appsumo
                ? _c(
                    "el-dropdown-item",
                    { attrs: { command: "account-appsumo" } },
                    [_vm._v("\n        AppSumo\n      ")]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "profile-dropdown__menu-footer" }, [
            _c(
              "a",
              {
                staticClass:
                  "profile-dropdown__menu-footer-item el-dropdown-menu__item",
                attrs: { href: _vm.publicAppUrl },
              },
              [_vm._v("Home")]
            ),
            _c(
              "a",
              {
                staticClass:
                  "profile-dropdown__menu-footer-item el-dropdown-menu__item",
                attrs: {
                  href: "https://features.spreadsimple.com/spreadsimple",
                  target: "_blank",
                },
              },
              [_vm._v("Roadmap")]
            ),
            _c(
              "a",
              {
                staticClass:
                  "profile-dropdown__menu-footer-item el-dropdown-menu__item",
                attrs: {
                  href: "https://help.spreadsimple.com/en/",
                  target: "_blank",
                },
              },
              [_vm._v("Help")]
            ),
            _c(
              "div",
              {
                staticClass:
                  "profile-dropdown__menu-footer-item el-dropdown-menu__item is-logout",
                on: { click: _vm.logout },
              },
              [
                _vm._v("\n        Sign out\n        "),
                _c("i", { staticClass: "icon-exit" }),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }