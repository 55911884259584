<template>
  <div class="spreadviews">
    <div class="spreadviews__container">
      <div class="spreadviews__left">

        <WorkspaceDropdown class="spreadviews__left-workspaces-manage" @on-ws-change="updatePageContent" />

        <div class="spreadviews__search">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search sites..."
            class="spreadviews__search-input"
          >
          <button class="spreadviews__search-btn icon-search-fat"></button>
        </div>

        <AvailableWebsitesInfo
          v-if="!isLoading"
          :amountOfActiveSpreadViews="amountOfActiveSpreadViews"
          :amountOfArchivedSpreadViews="amountOfArchivedSpreadViews"
        />

        <div class="spreadviews__filters spreadviews-filters">
          <div class="spreadviews-filters__section">
            <div class="spreadviews-filters__section-head">
              <h5 class="spreadviews-filters__section-title">Websites</h5>
            </div>
            <div class="spreadviews-filters__list">
              <div
                v-for="item in websitesFilterOptions"
                :key="item.value"
                class="spreadviews-filters__item"
                :class="{'is-active': statusToFilter === item.value}"
                @click="statusToFilter = item.value"
              >
                <div class="spreadviews-filters__item-left">
                  <span class="spreadviews-filters__item-title">{{ item.label }}</span>
                  <span class="spreadviews-filters__item-counter">{{ websitesCounter[item.value] }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="spreadviews-filters__section">
            <div class="spreadviews-filters__section-head">
              <h5 class="spreadviews-filters__section-title">Tags</h5>
              <button
                class="spreadviews-filters__section-head-btn icon-plus"
                @click="showAddNewTagInput = true"
              ></button>
            </div>
            <form
              v-if="showAddNewTagInput"
              class="spreadviews-filters__add-form"
              @submit.prevent="createNewTag"
            >
              <div class="spreadviews-filters__add-form-input-wrap">
                <input
                  ref="newTagInput"
                  v-model.trim="$v.newTag.$model"
                  :readonly="$async.createTag.$pending"
                  type="text"
                  class="spreadviews-filters__add-form-input"
                  placeholder="Enter new tag name..."
                  @keydown.esc="closeNewTagForm()"
                  @blur="closeNewTagForm()"
                >
                <button
                  v-if="$async.createTag.$pending"
                  class="spreadviews-filters__add-form-btn icon-loader"
                  type="button"
                  disabled
                ></button>
                <button
                  v-else
                  class="spreadviews-filters__add-form-btn icon-circle-close"
                  type="button"
                  @click="closeNewTagForm()"
                ></button>
              </div>

              <p v-if="$v.newTag.$dirty && !$v.newTag.maxLength" class="spreadviews-filters__add-form-msg">Max length is 30 symbols</p>
              <p v-if="$v.newTag.$dirty && !$v.newTag.required" class="spreadviews-filters__add-form-msg">Label shouldn`t be empty</p>
              <p v-if="$v.newTag.$dirty && !$v.newTag.checkLabelUnique" class="spreadviews-filters__add-form-msg">Label should be unique</p>
            </form>
            <draggable
              v-model="allTags"
              tag="div"
              class="spreadviews-filters__list"
              handle=".spreadviews-filters__item-drag-trigger"
              draggable=".is-draggable"
              @change="onAllTagsOrderChange"
            >
              <div
                v-if="allTags.length"
                slot="header"
                class="spreadviews-filters__item"
                :class="{'is-active': tagToFilter === 'all'}"
                @click="tagToFilter = 'all'"
              >
                <div class="spreadviews-filters__item-left">
                  <span class="spreadviews-filters__item-title">All</span>
                  <span class="spreadviews-filters__item-counter">{{ viewsList.length }}</span>
                </div>
              </div>
              <div
                v-for="(item) in allTags"
                :key="item.id"
                class="spreadviews-filters__item is-draggable"
                :class="[
                  {'is-active': tagToFilter === item.id},
                  {'is-disabled': $async.deleteTag.$pending}
                ]"
              >
                <div
                  class="spreadviews-filters__item-left"
                  @click.prevent="tagToFilter = item.id"
                >
                  <form
                    v-if="editableTagId === item.id"
                    class="spreadviews-filters__item-edit-form"
                    @submit.prevent="onTagNameEdit(item)"
                  >
                    <input
                      :ref="`tagEdit${item.id}`"
                      v-model.trim="$v.updatedTagLabel.$model"
                      :placeholder="item.label"
                      type="text"
                      class="spreadviews-filters__item-edit-input"
                      @blur="editableTagId = undefined"
                      @keydown.esc="editableTagId = undefined"
                    >
                  <p v-if="$v.updatedTagLabel.$dirty && !$v.updatedTagLabel.maxLength" class="spreadviews-filters__item-edit-msg">Max length is 30 symbols</p>
                  <p v-if="$v.updatedTagLabel.$dirty && !$v.updatedTagLabel.required" class="spreadviews-filters__item-edit-msg">Label shouldn`t be empty</p>
                  <p v-if="$v.updatedTagLabel.$dirty && !$v.updatedTagLabel.checkLabelUnique" class="spreadviews-filters__item-edit-msg">Label should be unique</p>
                  </form>
                  <template v-else>
                    <span class="spreadviews-filters__item-drag-trigger icon-draggable"></span>
                    <span class="spreadviews-filters__item-title">{{ item.label }}</span>
                    <span class="spreadviews-filters__item-counter">{{ tagsCounter[item.id] ? tagsCounter[item.id].counter : 0 }}</span>
                  </template>
                </div>
                <el-dropdown
                  :showTimeout="100"
                  placement="right-start"
                  class="spreadviews-filters__item-dropdown"
                  @command="(payload) => handleDropdownCommand(payload)"
                >
                  <button class="spreadviews-filters__item-dropdown-trigger icon-dots"></button>
                  <el-dropdown-menu slot="dropdown" class="spreadviews-filters__item-dropdown-menu">
                    <el-dropdown-item class="spreadviews-filters__item-dropdown-item" :command="{method: 'onTagRename', tag: item}">
                      Rename
                    </el-dropdown-item>
                    <el-dropdown-item class="spreadviews-filters__item-dropdown-item is-danger" :command="{method: 'onTagDelete', tag: item}">
                      Delete
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div
                v-if="allTags.length"
                slot="footer"
                class="spreadviews-filters__item"
                :class="{'is-active': tagToFilter === 'no-tags'}"
                @click="tagToFilter = 'no-tags'"
              >
                <div class="spreadviews-filters__item-left">
                  <span class="spreadviews-filters__item-title">No tags</span>
                  <span class="spreadviews-filters__item-counter">{{ viewsList.filter(i => i.tags.length === 0).length }}</span>
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div class="spreadviews__right">
        <div class="spreadviews__list-head">
          <h1 class="spreadviews__title">{{currentWorkspaceTitle}} websites</h1>
          <el-select
            v-model="websitesOrder"
            placeholder=""
            class="spreadviews__select"
            popper-class="spreadviews__select-menu"
          >
            <el-option
              v-for="option in orderOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"/>
          </el-select>
        </div>
        <div
          v-if="statusToFilter !== 'archived'"
          class="spreadviews__list"
        >
          <div class="spreadviews__list-item">
            <div
              class="spreadviews__add"
              @click="goToAddViewPage"
              @mouseenter="addButtonHovered = true"
              @mouseleave="addButtonHovered = false"
            >
              <svg
                class="spreadviews__add-bg"
                width='100%'
                height='100%'
                xmlns='http://www.w3.org/2000/svg'
              >
                <rect
                  width='100%'
                  height='100%'
                  stroke="#A8B7CF"
                  fill="none"
                  stroke-width="2"
                  stroke-dasharray="5 13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg>
              <div class="spreadviews__add-circle"></div>
              <i class="spreadviews__add-icon icon-plus"></i>
              <transition name="spreadviews-add-title-animation" mode="out-in">
                <p v-if="addButtonHovered" key="1" class="spreadviews__add-title">Add new website</p>
                <p v-else key="2" class="spreadviews__add-title">New website</p>
              </transition>
            </div>
            <div class="spreadviews__try-ai-btn-wrap">
              <router-link
                :to="{name: 'ai-creator'}"
                class="try-ai-btn"
              >
                TRY AI ✨
              </router-link>
            </div>
          </div>
          <SingleSpreadView
            v-for="(item) in activeViews"
            :key="item.id"
            class="spreadviews__list-item"
            :item="item"
            :allTags="allTags"
            :activeEditor="activeEditors.find(i => i.spreadViewId === item.id)"
            @title-updated="name => updateSpreadViewName(item, name)"
            @duplicate="duplicateSpreadView(item)"
            @delete="deleteCurrentView(item)"
            @add-new-tag="payload => addNewTagToWebsite(payload)"
            @update-website-tags="payload => updateWebsiteTags(payload)"
            @change-view-published-status="changeViewPublishedStatus($event)"
            @change-view-archived-status="changeViewArchivedStatus($event)"
          />
        </div>
        <div v-if="archivedViews.length && (statusToFilter === 'all' || statusToFilter === 'archived')" class="spreadviews__list-archive">
          <div class="spreadviews__list-head">
            <h2 class="spreadviews__title">Archived websites</h2>
          </div>
          <div class="spreadviews__list">
            <SingleSpreadView
              v-for="(item) in archivedViews"
              :key="item.id"
              class="spreadviews__list-item"
              :item="item"
              :allTags="allTags"
              :activeEditor="activeEditors.find(i => i.spreadViewId === item.id)"
              @title-updated="name => updateSpreadViewName(item, name)"
              @duplicate="duplicateSpreadView(item)"
              @delete="deleteCurrentView(item)"
              @add-new-tag="payload => addNewTagToWebsite(payload)"
              @update-website-tags="payload => updateWebsiteTags(payload)"
              @change-view-published-status="changeViewPublishedStatus($event)"
              @change-view-archived-status="changeViewArchivedStatus($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="spreadviews__bg"></div>
    <Preloader :loading="isLoading"/>

    <Modal
      v-model="modalOverLimitSpreadViews"
      :modalName="'You reached your website quantity limit'"
      closeOnPressEscBtn
      class="confirmation-popup"
      alignTitleLeft
      @input="closeModalOverLimitSpreadViews"
    >
      <div class="confirmation-popup__content">
        <p>You can’t add more websites to your workspace.
          <a href="https://help.spreadsimple.com/en/article/website-quantity-limitation-iul9ct/" target="_blank" rel="nofollow" class="over-limit-modal__link">
              Learn more about the website quantity limit.
          </a>
        </p>
        <p>To create a new website, do one of the following steps: </p>
        <ul>
          <li>delete one of your existing websites</li>
          <li>update your subscription plan in the Billing Settings to increase the number of licenses</li>
        </ul>
      </div>
      <div class="confirmation-popup__btns">
        <UiBtn
          type="secondary"
          @click="closeModalOverLimitSpreadViews">OK</UiBtn>
        <UiBtn
          type="primary"
          @click="$router.push({ name: 'account-billing' })">UPDATE SUBSCRIPTION</UiBtn>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import api from '@/services/api/'
import draggable from 'vuedraggable'
import urlifyLib from 'urlify'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import SingleSpreadView from './SingleSpreadView'
import WorkspaceDropdown from '@/components/workspace-dropdown'
import { initSocket } from '@/modules/sockets.js'
import Modal from '@/components/modal'
import UiBtn from '@/components/ui-btn'
import AvailableWebsitesInfo from '@/components/available-websites-info'
import { setOnboardingWizardStepAsCompleted } from '@/modules/wizards/onboarding'
import { createEncodedWriteOptions } from 'google-sheets-data'
Vue.use(Vuelidate)

const urlify = urlifyLib.create({
  addEToUmlauts: true,
  szToSs: true,
  spaces: '-',
  nonPrintable: 'non-supported-symbol',
  toLower: true,
  trim: true
})

export default {
  name: 'SpreadViews',
  metaInfo: {
    title: 'Websites List'
  },
  components: {
    SingleSpreadView,
    draggable,
    WorkspaceDropdown,
    Modal,
    UiBtn,
    AvailableWebsitesInfo
  },
  data () {
    return {
      addButtonHovered: false,
      viewsList: [],
      searchOpened: false,
      searchQuery: '',
      newTag: '',
      updatedTagLabel: '',
      allTags: [],
      showAddNewTagInput: false,
      editableTagId: undefined,
      websitesOrder: 'recent',
      statusToFilter: 'all',
      tagToFilter: 'all',
      orderOptions: [
        { value: 'recent', label: 'Recent' },
        { value: 'lastUpdated', label: 'Last Updated' },
        { value: 'alphabetical', label: 'Alphabetical' }
      ],
      websitesFilterOptions: [
        { value: 'all', label: 'All' },
        { value: 'free', label: 'Free websites' },
        { value: 'paid', label: 'Paid websites' },
        { value: 'archived', label: 'Archived websites' }
      ],
      socket: null,
      activeEditors: [],
      modalOverLimitSpreadViews: false,
      loading: false
    }
  },

  validations: {
    newTag: {
      required,
      maxLength: maxLength(30),
      checkLabelUnique: function (value) {
        return this.isLabelUnique(value)
      }
    },
    updatedTagLabel: {
      required,
      maxLength: maxLength(30),
      checkLabelUnique: function (value) {
        return this.isLabelUnique(value)
      }
    }
  },

  computed: {
    ...mapState('app', [
      'userData',
      'needToUpdateViews',
      'currentWorkspaceId',
      'workspaces'
    ]),
    ...mapGetters('app', [
      'startWebsocketConnection',
      'amountOfAvailableActiveSpreadViews',
      'onboardingWizardUncompletedSteps',
      'canSubscribeToPaidPlan'
    ]),

    currentWorkspaceTitle () {
      const currentWorkspace = this.workspaces ? this.workspaces.find(ws => ws.id === this.currentWorkspaceId) : null
      return currentWorkspace ? currentWorkspace.title : 'My'
    },

    viewsListSorted () {
      let sortedList = this.viewsList
      if (this.websitesOrder === 'recent') {
        sortedList = sortedList.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
      }
      if (this.websitesOrder === 'lastUpdated') {
        sortedList = sortedList.sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt)
        })
      }
      if (this.websitesOrder === 'alphabetical') {
        sortedList = sortedList.sort((a, b) => {
          const aName = a.options.spreadView.name.toLowerCase()
          const bName = b.options.spreadView.name.toLowerCase()
          if (aName > bName) return 1
          if (aName < bName) return -1
          return 0
        })
      }
      return sortedList
    },

    viewsFilteredByStatus () {
      return this.viewsListSorted.filter(view => {
        if (this.statusToFilter === 'paid') {
          return view.isPaid
        } else if (this.statusToFilter === 'free') {
          return !view.isPaid
        } else {
          return view
        }
      })
    },

    viewsFilteredByTag () {
      if (this.tagToFilter === 'all') return this.viewsFilteredByStatus
      if (this.tagToFilter === 'no-tags') return this.viewsFilteredByStatus.filter(i => i.tags.length === 0)
      return this.viewsFilteredByStatus.filter(view => {
        return view.tags.some(tag => tag.id === this.tagToFilter)
      })
    },

    viewsFiltered () {
      const filterViewsByQueryAndStatus = (views = [], query = '') => {
        if (!query) return views
        return views.filter(view => view.options.spreadView.name
          .toLowerCase()
          .includes(query.toLowerCase())
        )
      }
      return filterViewsByQueryAndStatus(this.viewsFilteredByTag, this.searchQuery)
    },

    activeViews () { return this.viewsFiltered.filter(i => !i.isArchived) },
    archivedViews () { return this.viewsFiltered.filter(i => i.isArchived) },

    websitesCounter () {
      return this.viewsList.reduce((acc, item) => {
        if (item.isPaid) {
          acc['paid'] += 1
        } else if (item.isArchived) {
          acc['archived'] += 1
        } else {
          acc['free'] += 1
        }
        acc['all'] += 1
        return acc
      }, { all: 0, paid: 0, free: 0, archived: 0
      })
    },

    tagsCounter () {
      return this.viewsList.reduce((acc, item) => {
        if (item.tags.length === 0) return acc
        item.tags.forEach(tag => {
          if (acc[tag.id]) {
            acc[tag.id].counter += 1
          } else {
            acc[tag.id] = {
              counter: 1
            }
          }
        })
        return acc
      }, {})
    },
    isOverLimitSpreadViews () {
      return this.amountOfActiveSpreadViews >= this.amountOfAvailableActiveSpreadViews
    },
    amountOfActiveSpreadViews () {
      return this.viewsList.filter(view => !view.isArchived).length
    },
    amountOfArchivedSpreadViews () {
      return this.viewsList.filter(view => view.isArchived).length
    },
    isLoading () {
      return this.$async.getSpreadViewsList.$pending || this.$async.getTags.$pending || this.$async.deleteSpreadView.$pending || this.loading
    }
  },
  watch: {
    'needToUpdateViews' (val) {
      if (val) {
        this.$async.getSpreadViewsList.$perform()
          .then(response => {
            this.viewsList = this.updateWebsitesFlags(response)
          })
      }
    },
    'showAddNewTagInput' (val) {
      if (val) {
        this.$nextTick(() => this.$refs.newTagInput.focus())
      }
    },
    'editableTagId' (val) {
      if (!val) {
        this.$v.updatedTagLabel.$reset()
      }
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchUserData',
      'fetchWorkspaces',
      'clearWorkspacesData',
      'updateOnboardingWizardState',
      'toggleModal'
    ]),

    updateWebsitesFlags (viewsList) {
      return viewsList.map(view => {
        // PRICING_UPDATE
        view.isPaid = !view.dealType.toLowerCase().includes('free')
        return view
      })
    },

    goToAddViewPage () {
      if (this.isOverLimitSpreadViews) {
        this.modalOverLimitSpreadViews = true
        return
      }
      this.$trackEvent({
        'event': 'addWebsite'
      })
      this.$router.push({ name: 'add-view-page' })
    },

    isLabelUnique (value) {
      let tagsList = this.allTags
      if (this.editableTagId) {
        tagsList = this.allTags.filter(t => t.id !== this.editableTagId)
      }
      const labelExists = tagsList.some(t => t.label === value)
      return !labelExists
    },

    async duplicateSpreadView (item) {
      if (this.isOverLimitSpreadViews) {
        this.modalOverLimitSpreadViews = true
        return
      }
      this.loading = true
      try {
        const response = await this.$async.createSpreadView.$perform({
          spreadsheetUrl: item.spreadsheetUrl,
          options: item.options,
          integrations: item.integrations,
          encodedOptions: createEncodedWriteOptions(item.options)
        })
        // if item has accessRules -> create access rules for new website
        if (item.accessRules) {
          await api.createWebsiteAccessRules(response.id, item.accessRules)
        }
        this.$trackEvent({
          'event': 'createWebsite',
          'creationType': 'duplicate',
          'domain': response.domain
        })
        this.loading = false
        this.$router.push({ name: 'manage-sheet-page', params: { id: response.id } })
      } catch (e) {
        this.loading = false
      }
    },

    async deleteCurrentView (item) {
      this.$async.deleteSpreadView.$perform(item.id)
        .then(() => {
          this.$trackEvent({
            'event': 'deleteWebsite',
            'websiteId': item.id
          })
          return this.$async.getSpreadViewsList.$perform()
        })
        .then(response => {
          this.fetchUserData()
          this.fetchWorkspaces()
          this.viewsList = this.updateWebsitesFlags(response)
        })
        .catch(err => console.warn(err.message))
    },

    updateSpreadViewName (item, name) {
      const oldName = item.options.spreadView.name
      item.options.spreadView.name = name
      this.$async.updateViewField.$perform(item.id, {
        options: {
          spreadView: { name }
        }
      })
        .then(() => {
          item.options.spreadView.name = name
          this.$toasted.show('SpreadView title was changed successfully', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'success',
            duration: 1500
          })
        })
        .catch(() => {
          item.options.spreadView.name = oldName
          this.$toasted.show('Error, try again', {
            theme: 'toasted-primary',
            position: 'top-center',
            type: 'error',
            duration: 1500
          })
        })
    },

    createNewTag () {
      this.$v.newTag.$touch()
      if (!this.$v.newTag.$invalid) {
        this.$async.createTag.$perform({
          label: this.newTag,
          value: urlify(this.newTag),
          sortOrder: (this.allTags.length + 1) * 10000
        })
          .then(response => {
            this.allTags.push(response)
            this.closeNewTagForm()
          })
      }
    },

    closeNewTagForm () {
      this.newTag = ''
      this.$v.newTag.$reset()
      this.showAddNewTagInput = false
    },

    onAllTagsOrderChange (val) {
      const newIndex = val.moved.newIndex
      const thisTag = val.moved.element
      if (newIndex === 0) {
        thisTag.sortOrder = this.allTags[1].sortOrder / 2
      } else if (newIndex === (this.allTags.length - 1)) {
        thisTag.sortOrder = this.allTags[newIndex - 1].sortOrder + 10000
      } else {
        thisTag.sortOrder = (this.allTags[newIndex + 1].sortOrder + this.allTags[newIndex - 1].sortOrder) / 2
      }
      this.$async.updateTag.$perform(thisTag.id, thisTag)
        .then(response => {
          this.allTags.find(t => t.id === response.id).sortOrder = response.sortOrder
        })
    },

    handleDropdownCommand (payload) {
      this[`${payload.method}`](payload.tag)
    },

    onTagDelete (payload) {
      this.$async.deleteTag.$perform(payload.id)
        .then(() => {
          if (this.tagToFilter === payload.id) this.tagToFilter = 'all'
          const index = this.allTags.findIndex(t => t.id === payload.id)
          this.allTags.splice(index, 1)
          this.viewsList.forEach(site => {
            if (site.tags.some(t => t.id === payload.id)) {
              const indexInSiteTags = site.tags.findIndex(t => t.id === payload.id)
              site.tags.splice(indexInSiteTags, 1)
            }
          })
        })
    },

    onTagRename (payload) {
      this.editableTagId = payload.id
      this.updatedTagLabel = payload.label
      const currentRef = `tagEdit${payload.id}`
      this.$nextTick(() => this.$refs[`${currentRef}`][0].focus())
    },

    onTagNameEdit (tag) {
      if (this.updatedTagLabel === '') {
        this.editableTagId = undefined
        return
      }

      const currentTag = this.allTags.find(t => t.id === tag.id)
      if (currentTag.label === this.updatedTagLabel) {
        this.editableTagId = undefined
        this.updatedTagLabel = ''
        return
      }
      currentTag.label = this.updatedTagLabel
      this.editableTagId = undefined
      this.$async.updateTag.$perform(tag.id, {
        label: this.updatedTagLabel,
        value: urlify(this.updatedTagLabel)
      }).then(() => {
        this.updatedTagLabel = ''
        this.$v.updatedTagLabel.$reset()
      })
    },

    addNewTagToWebsite (payload) {
      this.$async.createTag.$perform({
        label: payload.tagLabel,
        value: urlify(payload.tagLabel),
        sortOrder: (this.allTags.length + 1) * 10000
      })
        .then(newTag => {
          this.allTags.push(newTag)
          let websiteTagsUpdated = payload.websiteTags.map(t => t.id)
          websiteTagsUpdated.push(newTag.id)
          this.$async.updateSpreadViewTags.$perform(
            payload.websiteId,
            websiteTagsUpdated
          ).then(() => {
            let currentView = this.viewsList.find(v => v.id === payload.websiteId)
            currentView.tags.push(newTag)
          })
        })
    },

    updateWebsiteTags (payload) {
      let currentView = this.viewsList.find(v => v.id === payload.websiteId)
      currentView.tags = payload.tags.map(id => this.allTags.find(t => t.id === id))
      this.$async.updateSpreadViewTags.$perform(
        payload.websiteId,
        payload.tags
      )
    },
    updatePageContent () {
      this.statusToFilter = 'all'
      this.tagToFilter = 'all'
      this.$async.getSpreadViewsList.$perform()
        .then(response => {
          this.viewsList = this.updateWebsitesFlags(response)
          if (this.startWebsocketConnection) {
            this.initSocketChannel()
          }
        })
        .catch(({ response }) => {
          if (response.data.status === 403) {
            this.clearWorkspacesData()
            this.$nextTick(() => {
              location.reload()
            })
          }
        })
      this.$async.getTags.$perform()
        .then(response => {
          this.allTags = response.sort((a, b) => a.sortOrder - b.sortOrder)
        })
        .catch(({ response }) => {
          if (response.data.status === 403) {
            this.clearWorkspacesData()
            this.$nextTick(() => {
              location.reload()
            })
          }
        })
    },

    updateActiveEditors (response) {
      if (response.status === 204) {
        this.activeEditors = []
      } else {
        this.activeEditors = response.payload.filter(i => i.id !== this.userData.id)
      }
    },

    initSocketChannel () {
      // INIT SOCKETS
      this.socket = initSocket({
        path: '/',
        onConnect: () => {}
      })
      this.socket.emit(`spreadview:${this.currentWorkspaceId}:checkLock`, response => {
        this.updateActiveEditors(response)
      })
      this.socket.on(`spreadview:${this.currentWorkspaceId}:updateLock`, response => {
        this.updateActiveEditors(response)
      })
      // END INIT SOCKETS
    },
    async changeViewPublishedStatus (data) {
      try {
        this.loading = true
        await this.$async.updateViewField.$perform(
          data.id,
          {
            options: {
              spreadView: {
                isPublished: data.published
              }
            }
          }
        )
        if (
          data.published &&
          this.onboardingWizardUncompletedSteps.some(s => s.name === 'publishWebsite')
        ) {
          const wizards = await setOnboardingWizardStepAsCompleted('publishWebsite')
          this.updateOnboardingWizardState(wizards)
        }
        this.updatePageContent()
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    async changeViewArchivedStatus (data) {
      if (!data.archived && this.isOverLimitSpreadViews) {
        this.modalOverLimitSpreadViews = true
        return
      }
      try {
        this.loading = true
        await this.$async.updateViewField.$perform(
          data.id,
          {
            isArchived: data.archived
          }
        )
        this.updatePageContent()
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    closeModalOverLimitSpreadViews () {
      this.modalOverLimitSpreadViews = false
      if (this.$route.query.isOverlimit) {
        this.$router.replace({ name: 'spread-views', query: {} })
      }
    }
  },
  asyncOperations: {
    createSpreadView (url) {
      return api.createView(url)
    },

    deleteSpreadView (id) {
      return api.deleteView(id)
    },

    saveSpreadView (id, item) {
      return api.updateView(id, item)
    },

    getSpreadViewsList () {
      return api.getViewsList()
    },

    getTags () {
      return api.getTagsList()
    },

    createTag (data) {
      return api.createTag(data)
    },

    updateTag (id, data) {
      return api.updateTag(id, data)
    },

    deleteTag (id) {
      return api.deleteTag(id)
    },

    updateSpreadViewTags (id, tags) {
      return api.updateSpreadViewTags(id, tags)
    },

    updateViewField (id, val) {
      return api.updateView(id, val)
    },
    getViewOptions (id) {
      return api.getView(id)
    }
  },
  created () {
    this.updatePageContent()
  },
  mounted () {
    // temporary do not show initial quiz
    // if (localStorage.getItem('ssShowInitialQuiz') === 'true') {
    // this.$router.push({
    //   name: 'quiz',
    //   query: this.$route.query.redirect !== undefined ? { redirect: `${this.$route.query.redirect}` } : null
    // })
    // } else
    if (this.$route.query.redirect !== undefined) {
      this.$router.push({ path: `${this.$route.query.redirect}` })
    } else if (this.$route.query.isOverlimit) {
      this.modalOverLimitSpreadViews = true
    } else if (this.$route.query.startTrialFromPricing === 'true') {
      if (this.canSubscribeToPaidPlan) {
        this.toggleModal({ name: 'pricingModal', value: true })
      } else {
        this.$router.push({ name: 'account-billing' })
      }
    } else {
      this.$router.push({ name: 'spread-views' })
    }
  },
  beforeDestroy () {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
</script>
