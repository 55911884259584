var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mainWorkspace
    ? _c(
        "div",
        { staticClass: "workspaces-manage" },
        [
          _c(
            "div",
            { staticClass: "workspaces-manage__head" },
            [
              _c("TitleEditor", {
                staticClass: "workspaces-manage__title",
                attrs: { value: _vm.mainWorkspace.title },
                on: { input: _vm.updateWorkspaceTitle },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "workspaces-manage__body" }, [
            _c(
              "form",
              {
                staticClass: "workspaces-manage__search",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.inviteEditor.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "FormField",
                  {
                    staticClass: "workspaces-manage__search-input",
                    attrs: { messages: _vm.emailValidationMessages },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        placeholder: "Enter e-mail to invite editor",
                      },
                      model: {
                        value: _vm.inviteEmail,
                        callback: function ($$v) {
                          _vm.inviteEmail = $$v
                        },
                        expression: "inviteEmail",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "UiBtn",
                  {
                    staticClass: "workspaces-manage__search-btn",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      uppercase: "",
                      attrType: "submit",
                    },
                  },
                  [_vm._v("Invite members")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "workspaces-manage__counter" }, [
              _vm._v("\n      Your workspace has "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.mainWorkspaceEditors) +
                    "/" +
                    _vm._s(_vm.workspaceUserLimit)
                ),
              ]),
              _vm._v(" editors\n    "),
            ]),
            _c(
              "div",
              { staticClass: "workspaces-manage__members" },
              _vm._l(_vm.mainWorkspace.users, function (user) {
                return _c(
                  "div",
                  { key: user.email, staticClass: "workspaces-manage__member" },
                  [
                    _c(
                      "div",
                      { staticClass: "workspaces-manage__member-details" },
                      [
                        _c("UserAvatar", {
                          staticClass: "workspaces-manage__member-avatar",
                          attrs: { letter: user.email.charAt(0) },
                        }),
                        _c(
                          "div",
                          { staticClass: "workspaces-manage__member-meta" },
                          [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "workspaces-manage__member-meta-title",
                              },
                              [_vm._v(_vm._s(user.email))]
                            ),
                            user.role !== "OWNER"
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "workspaces-manage__member-meta-subtitle",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(user.status) +
                                        "\n                "
                                    ),
                                    user.status === "INACTIVE"
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              disabled: _vm.isLoading,
                                              content:
                                                "Update editor status to “Active”",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c("button", {
                                              staticClass:
                                                "icon-refresh-arrow workspaces-manage__member-meta-subtitle-icon",
                                              attrs: {
                                                disabled: _vm.isLoading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateEditorStatus(
                                                    user
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "workspaces-manage__member-status" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.userRoleText(user.role)) +
                            "\n        "
                        ),
                      ]
                    ),
                    user.role !== "OWNER"
                      ? _c("button", {
                          staticClass:
                            "workspaces-manage__member-delete-btn icon-delete",
                          attrs: { disabled: _vm.isLoading },
                          on: {
                            click: function ($event) {
                              return _vm.openDeleteDialog(user.email)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "el-dialog",
            {
              staticClass: "msg-dialog",
              attrs: { visible: _vm.deleteDialog },
              on: {
                "update:visible": function ($event) {
                  _vm.deleteDialog = $event
                },
                close: _vm.closeDeleteDialog,
              },
            },
            [
              _c("div", { staticClass: "msg-dialog__body" }, [
                _c("h4", { staticClass: "msg-dialog__title" }, [
                  _vm._v("Are you sure?"),
                ]),
                _c("p", { staticClass: "msg-dialog__string" }, [
                  _vm._v(
                    "This user won't be able to edit your websites anymore"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "msg-dialog__btns" },
                  [
                    _c(
                      "UiBtn",
                      {
                        attrs: {
                          disabled: _vm.isLoading,
                          type: "secondary",
                          uppercase: "",
                        },
                        on: { click: _vm.closeDeleteDialog },
                      },
                      [_vm._v("\n          No\n        ")]
                    ),
                    _c(
                      "UiBtn",
                      {
                        attrs: { disabled: _vm.isLoading, uppercase: "" },
                        on: { click: _vm.deleteEditor },
                      },
                      [_vm._v("\n          Yes\n        ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }