var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-switcher",
      class: _vm.label ? "ui-switcher--full-width" : "ui-switcher--inline",
    },
    [
      _vm.label
        ? _c("div", { staticClass: "ui-switcher__label" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
          ])
        : _vm._e(),
      _c(
        "label",
        { staticClass: "ui-switcher__trigger", class: { selected: _vm.value } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.internalValue,
                expression: "internalValue",
              },
            ],
            staticClass: "ui-switcher__trigger-input",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.internalValue)
                ? _vm._i(_vm.internalValue, null) > -1
                : _vm.internalValue,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.internalValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.internalValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.internalValue = $$c
                }
              },
            },
          }),
          _c("span", { staticClass: "ui-switcher__trigger-indicator" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }