var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field" }, [
    _c("span", { staticClass: "form-field__label" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("pageManageSheet.settings.theme")) + "\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "form-field__control align-end" },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "label",
          {
            key: index,
            staticClass: "theme-picker",
            class: [{ active: option.value === _vm.value.value }, option.class],
          },
          [
            _c("input", {
              staticClass: "theme-picker__input",
              attrs: { type: "radio", name: "theme" },
              domProps: { value: option.value },
              on: { input: (e) => _vm.emitValue(e) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }