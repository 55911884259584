import { render, staticRenderFns } from "./IntegrationsManager.vue?vue&type=template&id=4a65156e"
import script from "./IntegrationsManager.vue?vue&type=script&lang=js"
export * from "./IntegrationsManager.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a65156e')) {
      api.createRecord('4a65156e', component.options)
    } else {
      api.reload('4a65156e', component.options)
    }
    module.hot.accept("./IntegrationsManager.vue?vue&type=template&id=4a65156e", function () {
      api.rerender('4a65156e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/integrations-manager/IntegrationsManager.vue"
export default component.exports