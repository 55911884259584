var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: { value: _vm.opened, modalName: "Import from Notion" },
      on: {
        input: function ($event) {
          return _vm.$emit("on-close")
        },
      },
    },
    [
      _c("div", { staticClass: "unsplash-modal__download-section" }, [
        _c("div", { staticClass: "unsplash-modal__download-section-title" }, [
          _c("p", [_vm._v("1. Export a Notion page as markdown archive")]),
          _c("p", [_vm._v("2. Upload this file to import the page content")]),
          _c("br"),
          _c("p", [
            _c(
              "a",
              {
                staticClass: "sorting-constructor__link",
                attrs: {
                  href: "https://help.spreadsimple.com/en/article/import-from-notion-1lvfjr7/",
                  target: "_blank",
                },
              },
              [_vm._v("Step-by-step guide →")]
            ),
          ]),
        ]),
        _c(
          "form",
          {
            ref: "uploadForm",
            attrs: { enctype: "multipart/form-data" },
            on: {
              change: function ($event) {
                $event.preventDefault()
                return _vm.uploadFile.apply(null, arguments)
              },
            },
          },
          [
            _c("label", [
              _c("input", {
                ref: "file",
                staticStyle: { display: "none" },
                attrs: {
                  type: "file",
                  disabled: _vm.$async.uploadNotionExport.$pending,
                },
              }),
              _c(
                "div",
                { staticClass: "uploader__btn" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      !_vm.$async.uploadNotionExport.$pending
                        ? _c("div", { key: "btn" }, [
                            _vm._v(
                              "\n              Upload file\n              "
                            ),
                            _c("i", {
                              staticClass: "uploader__btn-icon icon-upload2",
                            }),
                          ])
                        : _c("div", { key: "btn2" }, [
                            _vm._v("\n              Loading\n            "),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }