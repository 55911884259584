var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-quiz" },
    [
      _c("div", { staticClass: "page-quiz__container" }, [
        _c("h1", { staticClass: "page-quiz__title" }, [
          _vm._v("Welcome! Let us personalize your experience"),
        ]),
        _c(
          "form",
          {
            staticClass: "page-quiz__form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "page-quiz__form-section" }, [
              _c("div", { staticClass: "page-quiz__form-item" }, [
                _c("h5", { staticClass: "page-quiz__form-item-title" }, [
                  _vm._v("1. What kind of website are you going to build? "),
                ]),
                _c(
                  "div",
                  { staticClass: "page-quiz__form-item-control is-grid-view" },
                  _vm._l(_vm.questionOptions.websiteType, function (item) {
                    return _c(
                      "label",
                      {
                        key: item.value,
                        staticClass: "page-quiz__radio",
                        class: {
                          "is-checked": _vm.answers.websiteType === item.value,
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.answers.websiteType,
                              expression: "answers.websiteType",
                            },
                          ],
                          attrs: { type: "radio", name: "website-type" },
                          domProps: {
                            value: item.value,
                            checked: _vm._q(
                              _vm.answers.websiteType,
                              item.value
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.answers,
                                "websiteType",
                                item.value
                              )
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "page-quiz__radio-indicator",
                        }),
                        _c("span", { staticClass: "page-quiz__radio-label" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$v.answers.websiteType.$dirty &&
                        _vm.$v.answers.websiteType.$invalid
                        ? "Field is required"
                        : ""
                    )
                  ),
                ]),
                _vm.answers.websiteType === "Other"
                  ? _c(
                      "div",
                      { staticClass: "page-quiz__form-item-control" },
                      [
                        _c(
                          "FormField",
                          {
                            attrs: {
                              messages: [
                                _vm.$v.answers.websiteTypeOther.$dirty &&
                                _vm.$v.answers.websiteTypeOther.$invalid
                                  ? "Field is required"
                                  : "",
                              ],
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "Text, please" },
                              model: {
                                value: _vm.answers.websiteTypeOther,
                                callback: function ($$v) {
                                  _vm.$set(_vm.answers, "websiteTypeOther", $$v)
                                },
                                expression: "answers.websiteTypeOther",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "page-quiz__form-item" }, [
                _c("h5", { staticClass: "page-quiz__form-item-title" }, [
                  _vm._v(
                    "2. Do you have any experience with website building?"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "page-quiz__form-item-control is-list-view" },
                  _vm._l(_vm.questionOptions.experience, function (item) {
                    return _c(
                      "label",
                      {
                        key: item.value,
                        staticClass: "page-quiz__radio",
                        class: {
                          "is-checked": _vm.answers.experience === item.value,
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.answers.experience,
                              expression: "answers.experience",
                            },
                          ],
                          attrs: { type: "radio", name: "website-type" },
                          domProps: {
                            value: item.value,
                            checked: _vm._q(_vm.answers.experience, item.value),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.answers,
                                "experience",
                                item.value
                              )
                            },
                          },
                        }),
                        _c("span", {
                          staticClass: "page-quiz__radio-indicator",
                        }),
                        _c("span", { staticClass: "page-quiz__radio-label" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$v.answers.experience.$dirty &&
                        _vm.$v.answers.experience.$invalid
                        ? "Field is required"
                        : ""
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "page-quiz__form-item" }, [
                _c("h5", { staticClass: "page-quiz__form-item-title" }, [
                  _vm._v("3. How well are you familiar with Google Sheets?"),
                ]),
                _c(
                  "div",
                  { staticClass: "page-quiz__form-item-control is-list-view" },
                  _vm._l(
                    _vm.questionOptions.googleSheetFamiliar,
                    function (item) {
                      return _c(
                        "label",
                        {
                          key: item.value,
                          staticClass: "page-quiz__radio",
                          class: {
                            "is-checked":
                              _vm.answers.googleSheetFamiliar === item.value,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.answers.googleSheetFamiliar,
                                expression: "answers.googleSheetFamiliar",
                              },
                            ],
                            attrs: { type: "radio", name: "website-type" },
                            domProps: {
                              value: item.value,
                              checked: _vm._q(
                                _vm.answers.googleSheetFamiliar,
                                item.value
                              ),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(
                                  _vm.answers,
                                  "googleSheetFamiliar",
                                  item.value
                                )
                              },
                            },
                          }),
                          _c("span", {
                            staticClass: "page-quiz__radio-indicator",
                          }),
                          _c(
                            "span",
                            { staticClass: "page-quiz__radio-label" },
                            [_vm._v(_vm._s(item.title))]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$v.answers.googleSheetFamiliar.$dirty &&
                        _vm.$v.answers.googleSheetFamiliar.$invalid
                        ? "Field is required"
                        : ""
                    )
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "page-quiz__form-btns" },
              [
                _c("UiBtn", {
                  attrs: {
                    label: "SKIP",
                    attrType: "button",
                    type: "secondary-blue",
                    wide: "",
                  },
                  on: { click: _vm.skipPoll },
                }),
                _c("UiBtn", {
                  attrs: { label: "SUBMIT", attrType: "submit", wide: "" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("Preloader", { attrs: { loading: _vm.isLoading } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }