var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-form__switch-field" }, [
    _c("div", { staticClass: "content-form__switcher-wrap" }, [
      _c(
        "div",
        { staticClass: "content-form__switcher-label" },
        [
          _vm._v("\n      Enable stock limitation\n      "),
          _c("UiHelper", {
            staticClass: "va-bottom ml-2",
            attrs: {
              popoverFeature: "stock-limitation",
              showCloseBtn: "",
              videoData: {
                src: "/video/helper-popup/stock-limitation.mp4",
                label: "Learn how to use stock limitation",
                btns: [
                  {
                    label: "Learn more",
                    to: "https://help.spreadsimple.com/en/article/how-to-set-stock-limitation-1d97k2f/",
                    externalResource: true,
                    color: "secondary-blue",
                    event: {
                      type: "goToHelp",
                    },
                  },
                  {
                    label: "Watch video",
                    to: {
                      name: "help-video",
                      query: { show: "stock-limitation" },
                    },
                    event: {
                      type: "goToVideo",
                    },
                  },
                ],
              },
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "switcher", class: { selected: _vm.stock.enabled } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.stock.enabled,
                expression: "stock.enabled",
              },
            ],
            staticClass: "switcher__input",
            attrs: { type: "checkbox", value: "tiles" },
            domProps: {
              checked: Array.isArray(_vm.stock.enabled)
                ? _vm._i(_vm.stock.enabled, "tiles") > -1
                : _vm.stock.enabled,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.stock.enabled,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "tiles",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.stock, "enabled", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.stock,
                          "enabled",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.stock, "enabled", $$c)
                  }
                },
                function ($event) {
                  return _vm.$trackFeature(
                    "content",
                    "stock",
                    _vm.stock.enabled
                  )
                },
              ],
            },
          }),
          _c("span", { staticClass: "switcher__indicator" }),
        ]
      ),
    ]),
    _vm.stock.enabled
      ? _c(
          "div",
          {
            staticClass:
              "content-form__switch-hidden-field aside-select paddingless",
          },
          [
            _c("div", { staticClass: "sorting-constructor__highlighted" }, [
              _c("div", { staticClass: "sorting-constructor__item" }, [
                !_vm.orderActionEnabled
                  ? _c("div", { staticClass: "page-form__hint error" }, [
                      _vm._v(
                        "This option will work only if the action button type is set to 'Order Form'"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "sorting-constructor__select aside-select paddingless",
                  },
                  [
                    _c(
                      "h6",
                      { staticClass: "sorting-constructor__item-label" },
                      [_vm._v("Stock column")]
                    ),
                    _c("v-select", {
                      attrs: {
                        attach: "",
                        clearable: "",
                        "menu-props": "offsetY",
                        items: _vm.mappedCols,
                        "item-text": "label",
                        "item-value": "value",
                        "append-icon": "keyboard_arrow_down",
                      },
                      model: {
                        value: _vm.stock.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.stock, "id", $$v)
                        },
                        expression: "stock.id",
                      },
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "sorting-constructor__link",
                        attrs: {
                          target: "_blank",
                          href: "https://help.spreadsimple.com/en/article/how-to-set-stock-limitation-1d97k2f/",
                        },
                      },
                      [_vm._v("Help: How to use stock limitation?")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "sorting-constructor__item" },
                [
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Out of stock button label" },
                    },
                    [
                      _c("div", { staticClass: "page-form__hint" }, [
                        _vm._v(
                          "Changes the action button label if the stock column has zero value."
                        ),
                      ]),
                      _c("v-text-field", {
                        attrs: { placeholder: "Out of stock" },
                        model: {
                          value: _vm.stock.outOfStockLabel,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stock,
                              "outOfStockLabel",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "stock.outOfStockLabel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "sorting-constructor__item" },
                [
                  _c(
                    "FormField",
                    {
                      staticClass: "side-form__item aside-input",
                      attrs: { label: "Out of stock link" },
                    },
                    [
                      _c("div", { staticClass: "page-form__hint" }, [
                        _vm._v(
                          "If the stock column has zero value the action button will open this link. Leave it empty to make the button disabled for zero stock."
                        ),
                      ]),
                      _c("v-text-field", {
                        attrs: { placeholder: "" },
                        on: {
                          blur: function ($event) {
                            return _vm.trimInternalLink()
                          },
                        },
                        model: {
                          value: _vm.stock.outOfStockLink,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.stock,
                              "outOfStockLink",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "stock.outOfStockLink",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }