var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.baseTag,
    {
      tag: "component",
      staticClass: "title-editor",
      class: {
        "is-editing": _vm.isTitleEditing,
        "is-disabled": _vm.disabled,
      },
    },
    [
      _c(
        "form",
        {
          staticClass: "title-editor__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateTitle.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isTitleEditing,
                  expression: "!isTitleEditing",
                },
              ],
              staticClass: "title-editor__value-saved",
            },
            [
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.title))]
              }),
            ],
            2
          ),
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTitleEditing,
                expression: "isTitleEditing",
              },
              {
                name: "model",
                rawName: "v-model.trim.lazy",
                value: _vm.$v.title.$model,
                expression: "$v.title.$model",
                modifiers: { trim: true, lazy: true },
              },
            ],
            ref: "titleInput",
            staticClass: "title-editor__input",
            class: { "title-editor__input_error": !_vm.$v.title.required },
            domProps: { value: _vm.$v.title.$model },
            on: {
              change: function ($event) {
                _vm.$set(_vm.$v.title, "$model", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          !_vm.isTitleEditing
            ? _c("button", {
                staticClass: "title-editor__btn-edit icon-pencil2",
                attrs: { type: "button", disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.isTitleEditing = true
                  },
                },
              })
            : _c("button", {
                staticClass: "title-editor__btn-edit icon-save",
                attrs: { type: "submit" },
              }),
        ]
      ),
      !_vm.$v.title.required
        ? _c("div", { staticClass: "title-editor__error" }, [
            _vm._v("\n    Title is required\n  "),
          ])
        : _vm._e(),
      !_vm.$v.title.minLength
        ? _c("div", { staticClass: "title-editor__error" }, [
            _vm._v(
              "\n    Title must have at least " +
                _vm._s(_vm.$v.title.$params.minLength.min) +
                " letters.\n  "
            ),
          ])
        : _vm._e(),
      !_vm.$v.title.maxLength
        ? _c("div", { staticClass: "title-editor__error" }, [
            _vm._v(
              "\n    Title can`t be longer than " +
                _vm._s(_vm.$v.title.$params.maxLength.max) +
                " letters.\n  "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }