var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.uploadExist
        ? _c(
            "div",
            {
              staticClass: "uploader__btn",
              on: {
                click: function ($event) {
                  _vm.unsplashModalOpened = true
                },
              },
            },
            [
              _c("div", [
                _vm._v("\n        " + _vm._s(_vm.buttonText) + "\n        "),
                _c("i", { staticClass: "uploader__btn-icon icon-upload2" }),
              ]),
            ]
          )
        : _c("div", { staticClass: "uploader" }, [
            _c("div", { staticClass: "uploader__preview-wrap" }, [
              _c("div", {
                staticClass: "uploader__preview",
                style: { backgroundImage: `url('${_vm.currentImage}')` },
              }),
            ]),
            _c("div", { staticClass: "uploader__controls-wrap" }, [
              _c("div", { staticClass: "uploader__file-name" }, [
                _vm._v(_vm._s(_vm.currentImage)),
              ]),
              _c("div", { staticClass: "uploader__controls" }, [
                _c(
                  "div",
                  {
                    staticClass: "uploader__change is-highlighted",
                    on: {
                      click: function ($event) {
                        _vm.unsplashModalOpened = true
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon-upload2" })]
                ),
                _c(
                  "div",
                  {
                    staticClass: "uploader__delete",
                    on: { click: _vm.deleteImageFromOptions },
                  },
                  [_c("i", { staticClass: "icon-delete-bin" })]
                ),
              ]),
            ]),
          ]),
      _c("UploadImageModal", {
        attrs: {
          opened: _vm.unsplashModalOpened,
          unsplashEnabled: _vm.unsplashEnabled,
        },
        on: {
          input: (val) => (_vm.currentImage = val),
          "on-close": function ($event) {
            _vm.unsplashModalOpened = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }