var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-cta" }, [
    _c(
      "div",
      { staticClass: "page-cta__container" },
      [
        _vm.invitedUser !== _vm.userData.email
          ? _c("p", { staticClass: "page-cta__string" }, [
              _vm._v(
                "\n      Please check the account you’re signed in to. Looks like the invitation was sent to another email address.\n    "
              ),
            ])
          : [
              _c("h2", { staticClass: "page-cta__title" }, [
                _vm._v(_vm._s(_vm.wsOwner)),
              ]),
              _c("h3", { staticClass: "page-cta__subtitle" }, [
                _vm._v("invites you to join "),
              ]),
              _c("h2", { staticClass: "page-cta__title" }, [
                _vm._v(_vm._s(_vm.wsName) + " workspace"),
              ]),
              _c(
                "UiBtn",
                {
                  staticClass: "page-cta__btn",
                  attrs: { loading: _vm.isLoading, uppercase: "", wide: "" },
                  on: { click: _vm.acceptInvitation },
                },
                [_vm._v("Join")]
              ),
              _vm.error
                ? _c("p", { staticClass: "page-cta__error-msg" }, [
                    _vm._v(_vm._s(_vm.error)),
                  ])
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }