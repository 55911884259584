var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "side-form" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "side-form__item" },
        [
          _c(
            "draggable",
            {
              staticClass: "draggable",
              attrs: {
                value: _vm.value,
                tag: "div",
                handle: ".draggable__item-trigger",
              },
              on: { input: (val) => _vm.onDrag(val) },
            },
            _vm._l(_vm.value, function (route, index) {
              return _c("div", { key: index, staticClass: "draggable__item" }, [
                _c("div", {
                  staticClass: "draggable__item-trigger icon-draggable",
                }),
                _c(
                  "div",
                  {
                    staticClass: "draggable__item-title-wrap",
                    class: { "is-inactive": index >= _vm.allowedNumberOfPages },
                    on: {
                      click: function ($event) {
                        return _vm.onItemClick(index)
                      },
                    },
                  },
                  [
                    route.type === "page"
                      ? _c("i", {
                          staticClass:
                            "icon-new-page draggable__item-title-icon",
                        })
                      : _c("i", {
                          staticClass: "icon-link2 draggable__item-title-icon",
                        }),
                    _c("span", { staticClass: "draggable__item-title" }, [
                      _vm._v(_vm._s(route.navTitle)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "draggable__item-controls" },
                  [
                    _c(
                      "ProOptionWrapper",
                      { attrs: { allowed: index < _vm.allowedNumberOfPages } },
                      [
                        route.navHide
                          ? _c("div", {
                              staticClass:
                                "draggable__item-control icon-eye-hide",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("update", index, {
                                    navHide: false,
                                  })
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "draggable__item-control",
                        attrs: { trigger: "click" },
                      },
                      [
                        _c("i", { staticClass: "icon-gear" }),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "draggable__dropdown-menu",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _c(
                              "ProOptionWrapper",
                              {
                                attrs: {
                                  allowed: index < _vm.allowedNumberOfPages,
                                },
                              },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.$emit("set-current", index)
                                      },
                                    },
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.$emit("update", index, {
                                          navHide: !route.navHide,
                                        })
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          route.navHide
                                            ? "Show on menu"
                                            : "Hide from menu"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "ProOptionWrapper",
                              { attrs: { allowed: _vm.canEditPages } },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.$emit("duplicate", index)
                                      },
                                    },
                                  },
                                  [_vm._v("Duplicate")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.$emit("delete", index)
                                  },
                                },
                              },
                              [_vm._v("Delete")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "side-form__item side-form__btns" },
        [
          _c("ProOptionWrapper", { attrs: { allowed: _vm.canEditPages } }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn--transparent btn--prefix-icon btn--icon-new-page",
                on: {
                  click: function ($event) {
                    return _vm.$emit("create", "page")
                  },
                },
              },
              [_vm._v("Add page")]
            ),
          ]),
          _c("ProOptionWrapper", { attrs: { allowed: _vm.canEditPages } }, [
            _c(
              "button",
              {
                staticClass:
                  "btn btn--transparent btn--prefix-icon btn--icon-link2",
                on: {
                  click: function ($event) {
                    return _vm.$emit("create", "link")
                  },
                },
              },
              [_vm._v("Add link")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "UiBtn",
        {
          staticClass: "aside-save",
          attrs: {
            size: "l",
            "attr-type": "submit",
            loading: _vm.loading,
            disabled: _vm.loading || _vm.spreadViewerOptionsInvalid,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("submit")
            },
          },
        },
        [_vm._v("\n    SAVE\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "side-form__item" }, [
      _c("p", { staticClass: "side-form__footnote" }, [
        _vm._v("Create internal pages to tell more about your product."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }