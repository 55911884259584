var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sorting-constructor" }, [
    _c("div", { staticClass: "sorting-constructor__item" }, [
      _c(
        "div",
        { staticClass: "sorting-constructor__select aside-select" },
        [
          _c("h6", { staticClass: "sorting-constructor__item-label" }, [
            _vm._v("Sort by columns"),
          ]),
          _c("v-select", {
            attrs: {
              items: _vm.defaultCols,
              "item-text": "label",
              "item-value": "value",
              attach: "",
              "menu-props": "offsetY",
              chips: "",
              "deletable-chips": "",
              multiple: "",
              "append-icon": "keyboard_arrow_down",
              placeholder: "Choose options",
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function (data) {
                  return [
                    _c(
                      "DraggableChip",
                      {
                        attrs: { id: data.index },
                        model: {
                          value: _vm.value.ids,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "ids", $$v)
                          },
                          expression: "value.ids",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(data.item.label) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.value.ids,
              callback: function ($$v) {
                _vm.$set(_vm.value, "ids", $$v)
              },
              expression: "value.ids",
            },
          }),
        ],
        1
      ),
      _vm.v.ids.$dirty && !_vm.v.ids.required
        ? _c("p", { staticClass: "form-field__error" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("global.fieldIsRequired")) + "\n    "
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "sorting-constructor__item" }, [
      _c(
        "div",
        { staticClass: "sorting-constructor__select aside-select" },
        [
          _c("h6", { staticClass: "sorting-constructor__item-label" }, [
            _vm._v("Default sort column"),
          ]),
          _c("v-select", {
            attrs: {
              attach: "",
              "menu-props": "offsetY",
              items: _vm.sortableOptions,
              "item-text": "label",
              "item-value": "value",
              "append-icon": "keyboard_arrow_down",
            },
            model: {
              value: _vm.value.default,
              callback: function ($$v) {
                _vm.$set(_vm.value, "default", $$v)
              },
              expression: "value.default",
            },
          }),
        ],
        1
      ),
      !_vm.v.default.id.required
        ? _c("p", { staticClass: "form-field__error" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("global.fieldIsRequired")) + "\n    "
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "content-form__switch-field" }, [
      _c("div", { staticClass: "content-form__switcher-wrap" }, [
        _c("div", { staticClass: "content-form__switcher-label" }, [
          _vm._v("Reverse default order"),
        ]),
        _c(
          "label",
          {
            staticClass: "switcher",
            class: { selected: _vm.value.reverseDefaultOrder },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value.reverseDefaultOrder,
                  expression: "value.reverseDefaultOrder",
                },
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox", value: "tiles" },
              domProps: {
                checked: Array.isArray(_vm.value.reverseDefaultOrder)
                  ? _vm._i(_vm.value.reverseDefaultOrder, "tiles") > -1
                  : _vm.value.reverseDefaultOrder,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.value.reverseDefaultOrder,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "tiles",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.value,
                          "reverseDefaultOrder",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.value,
                          "reverseDefaultOrder",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.value, "reverseDefaultOrder", $$c)
                  }
                },
              },
            }),
            _c("span", { staticClass: "switcher__indicator" }),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "content-form__switch-field" }, [
      _c("div", { staticClass: "content-form__switcher-wrap" }, [
        _c("div", { staticClass: "content-form__switcher-label" }, [
          _vm._v("Shuffle items by default"),
        ]),
        _c(
          "label",
          {
            staticClass: "switcher",
            class: { selected: _vm.value.shuffleRows },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value.shuffleRows,
                  expression: "value.shuffleRows",
                },
              ],
              staticClass: "switcher__input",
              attrs: { type: "checkbox", value: "tiles" },
              domProps: {
                checked: Array.isArray(_vm.value.shuffleRows)
                  ? _vm._i(_vm.value.shuffleRows, "tiles") > -1
                  : _vm.value.shuffleRows,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.value.shuffleRows,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "tiles",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.value, "shuffleRows", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.value,
                          "shuffleRows",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.value, "shuffleRows", $$c)
                  }
                },
              },
            }),
            _c("span", { staticClass: "switcher__indicator" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }