<template>
  <span
    class="tag"
    :class="[
      `tag--color-${color}`,
      `tag--size-${size}`,
      { 'tag--clickable': clickable }
    ]"
  >
    <span class="tag__title">{{ label }}</span>
  </span>
</template>
<script>
export default {
  name: 'UiTag',
  props: {
    label: {
      type: [String, Number],
      default: null
    },
    color: {
      type: String,
      default: 'primary' // success, secondary, danger
    },
    size: {
      type: String,
      default: 'm' // s, xs, xxs
    },
    clickable: {
      type: Boolean,
      default: false
    }
  }
}
</script>
