var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "source-options" },
      [
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          !_vm.visible
            ? _c("div", { key: "btns" }, [
                _c("div", { staticClass: "source-options__row" }, [
                  _c(
                    "a",
                    {
                      staticClass: "source-options__link",
                      attrs: { href: _vm.googleUrl, target: "_blank" },
                      on: { click: _vm.trackEditGSAction },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "source-options__link-icon icon-edit-google-sheet",
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("global.editSheet")) +
                          "\n          "
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "source-options__button",
                      attrs: {
                        disabled: _vm.loading,
                        title: "Refresh Google Sheet data",
                      },
                      on: { click: _vm.onRefresh },
                    },
                    [
                      _vm.loading && _vm.refreshing
                        ? _c("div", { staticClass: "icon-loader" })
                        : _c("i", { staticClass: "icon-refresh-arrow" }),
                    ]
                  ),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "source-options__change-btn",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit("toggle-visibility", true)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass:
                        "source-options__change-btn-icon icon-pencil-new",
                    }),
                    _vm._v("\n          Change source sheet\n        "),
                  ]
                ),
              ])
            : _c("div", { key: "form", staticClass: "source-options__form" }, [
                _c(
                  "button",
                  {
                    staticClass: "source-options__form-back",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toggle-visibility", false)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "source-options__form-back-icon icon-arrow",
                    }),
                    _vm._v("\n          Back\n        "),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "source-options__form-wrapper" },
                  [
                    _c("SpreadForm", {
                      attrs: {
                        loading: _vm.loading,
                        initialVal: _vm.googleUrl,
                      },
                      on: {
                        submit: (val) => _vm.$emit("google-url-change", val),
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }