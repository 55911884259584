<template>
  <iframe
    :src="viewerBaseUrl+`?_preview_mode=1&_preview_generation_id=${generationId}`"
    frameborder="0"
    data-openreplay-capture
    allow="clipboard-write"
  ></iframe>
</template>
<script>
import postRobot from 'post-robot'
export default {
  name: 'AiCreatorPreview',
  props: {
    previewInitOptions: {
      type: Object,
      required: true
    },
    generationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      viewerBaseUrl: process.env.VUE_APP_VIEWER_APP_BASE_URL
    }
  },
  computed: {
    websiteOptions () {
      const websiteObject = {
        'pwdProtection': {
          'enabled': false,
          'passwords': [],
          'passwordsHashed': [],
          'cardsOnly': false,
          'message': ''
        },
        'ssl': 'NOT_ISSUED',
        'seoIndexing': true,
        'customDealLimits': null,
        'dealType': 'connect',
        'sid': 'fake_sid',
        'sheetHash': null,
        'SCPTableLatestUpdateTimestamp': 0,
        'spreadsheetId': null,
        'sheetId': null,
        'sheetRange': null,
        'isArchived': false,
        'tags': [],
        'accessRules': null,
        'integrations': [],
        'spreadsheetUrl': null,
        'options': null,
        'owner': null,
        'workspace': null,
        'id': 'fake_id',
        'domain': 'fake_domain.spread.sh',
        'createdAt': null,
        'updatedAt': null,
        'customDomain': '',
        'coverImage': null,
        'isPublished': true
      }
      websiteObject.options = this.previewInitOptions

      const data = {
        spreadViewData: {
          ...websiteObject,
          isVisibleSingleItem: false
        }
      }
      return data
    }
  },
  methods: {
    previewInit ({ data }) {
      this.$options.updatePreview = data.updatePreview
      this.$options.updatePreview(this.websiteOptions)
      return this.websiteOptions
    }
  },
  async mounted () {
    if (this.$options.ssPreviewInitListener) {
      this.$options.ssPreviewInitListener.cancel()
    }
    this.$options.ssPreviewInitListener = await postRobot.on('ssPreviewInit', this.previewInit)
  },
  beforeDestroy () {
    if (this.$options.ssPreviewInitListener) {
      this.$options.ssPreviewInitListener.cancel()
    }
  }
}
</script>
