var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "billing" }, [
      _c("h4", { staticClass: "billing__title" }, [_vm._v("Billing settings")]),
      _c("div", { staticClass: "billing__body" }, [
        _c("div", { staticClass: "billing__grid-wrap" }, [
          _vm.userActiveDealsByType
            ? _c(
                "div",
                { staticClass: "billing-grid" },
                [
                  _vm._m(0),
                  _vm._l(
                    Object.entries(_vm.userDealsExcludeAppsumo),
                    function (type, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "billing-grid__body" },
                        [
                          _vm._l(type[1], function (deal, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "billing-grid__row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "billing-grid__cell is-bold" },
                                  [
                                    _c("span", [
                                      _c(
                                        "span",
                                        {
                                          class: _vm.dealCancelled(deal)
                                            ? "text-color-secondary"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(deal.label)
                                          ),
                                        ]
                                      ),
                                      deal.quantity > 1
                                        ? _c(
                                            "span",
                                            {
                                              class: _vm.dealCancelled(deal)
                                                ? "text-color-secondary"
                                                : "text-color-primary",
                                            },
                                            [
                                              _vm._v(
                                                "\n                x" +
                                                  _vm._s(deal.quantity)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "billing-grid__cell is-bold" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(deal.licensesQuantity) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "billing-grid__cell" },
                                  [
                                    _vm.dealIsActive(deal)
                                      ? _c("p", { staticClass: "size-s" }, [
                                          _vm._v(
                                            "\n                Next payment " +
                                              _vm._s(
                                                _vm.dealExpirationDate(
                                                  deal.nextBillDate
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.dealCancelled(deal)
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "size-s text-color-danger",
                                          },
                                          [
                                            _vm._v(
                                              "\n                Subscription will work until " +
                                                _vm._s(
                                                  _vm.dealExpirationDate(
                                                    deal.nextBillDate
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.dealOnTrial(deal)
                                      ? _c("p", { staticClass: "size-s" }, [
                                          _vm._v(
                                            "\n                Trial will last until " +
                                              _vm._s(
                                                _vm.dealExpirationDate(
                                                  deal.nextBillDate
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "billing-grid__cell align-end gap-x",
                                  },
                                  [
                                    _vm.dealIsActive(deal)
                                      ? _c("UiBtn", {
                                          attrs: {
                                            label: "Change plan",
                                            size: "xs",
                                            uppercase: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSwitchPlan(deal)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.dealOnTrial(deal)
                                      ? _c("UiTag", {
                                          attrs: { label: "Trial", size: "xs" },
                                        })
                                      : _vm._e(),
                                    _vm.dealCancelled(deal)
                                      ? _c("UiTag", {
                                          attrs: {
                                            label: "Cancelled",
                                            color: "secondary",
                                            size: "xs",
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.dealCancelled(deal) &&
                                    _vm.showDealControls(deal)
                                      ? _c(
                                          "el-dropdown",
                                          {
                                            attrs: {
                                              trigger: "click",
                                              "arrow-visible": false,
                                            },
                                            on: {
                                              "visible-change": (val) =>
                                                (_vm.tableItemDropdownOpened =
                                                  val),
                                            },
                                          },
                                          [
                                            _c(
                                              "el-dropdown-menu",
                                              {
                                                staticClass:
                                                  "billing-grid__row-menu",
                                                attrs: { slot: "dropdown" },
                                                slot: "dropdown",
                                              },
                                              [
                                                !_vm.dealOnTrial(deal)
                                                  ? _c("el-dropdown-item", [
                                                      _c(
                                                        "button",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onUpdateSeats(
                                                                deal
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Update Quantity"
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                deal.period === "month" &&
                                                !_vm.dealOnTrial(deal)
                                                  ? _c("el-dropdown-item", [
                                                      _c(
                                                        "button",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onUpdatePlanToAnnual(
                                                                deal
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      Switch to Annual Plan "
                                                          ),
                                                          _vm.hasAnnualDealDiscount
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "with 25% discount"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _c("el-dropdown-item", [
                                                  _c(
                                                    "button",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onEditPaymentDetails(
                                                            deal
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Edit payment details"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "el-dropdown-item",
                                                  { staticClass: "is-danger" },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onClickUnsubscribe(
                                                              deal
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Cancel subscription"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("UiBtn", {
                                              attrs: {
                                                "icon-only": "im-gear",
                                                size: "xs",
                                                type: "secondary",
                                                highlighted:
                                                  _vm.tableItemDropdownOpened,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            { staticClass: "billing-grid__row is-divided" },
                            [
                              _vm._m(1, true),
                              _c(
                                "div",
                                { staticClass: "billing-grid__cell is-bold" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          type[0] === "free"
                                            ? _vm.userData.dealOptions
                                                .calculatedWebsites.free.applied
                                            : _vm.userData.dealOptions
                                                .calculatedWebsites
                                                .paidLicenses[type[0]].applied
                                        ) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-color-secondary" },
                                      [
                                        _vm._v(
                                          "\n                  of " +
                                            _vm._s(
                                              type[0] === "free"
                                                ? _vm.userData.dealOptions
                                                    .calculatedWebsites.free
                                                    .total
                                                : _vm.userData.dealOptions
                                                    .calculatedWebsites
                                                    .paidLicenses[type[0]].total
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]),
        _vm.legacyTrialSubscription || _vm.canSubscribeToPaidPlan
          ? _c(
              "div",
              { staticClass: "billing__btns" },
              [
                _c("UiBtn", {
                  attrs: { label: "Subscribe" },
                  on: { click: _vm.onTryToSubscribe },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._m(2),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billing-grid__head" }, [
      _c("div", { staticClass: "billing-grid__cell is-heading" }, [
        _vm._v("Plan"),
      ]),
      _c("div", { staticClass: "billing-grid__cell is-heading" }, [
        _vm._v("Licenses"),
      ]),
      _c("div", { staticClass: "billing-grid__cell is-heading" }),
      _c("div", { staticClass: "billing-grid__cell is-heading" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "billing-grid__cell is-bold" }, [
      _c("span", [_vm._v("Applied: ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "billing__footnote" }, [
      _c("img", {
        staticClass: "billing__footnote-icon",
        attrs: { src: "/img/info-icon.svg" },
      }),
      _c("span", [
        _vm._v(
          "\n          One license applies to one website. Learn more about\n          "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://help.spreadsimple.com/en/article/subscription-plans-11wipl5/",
              target: "_blank",
            },
          },
          [_vm._v("the subscription plans")]
        ),
        _vm._v(".\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }