var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ui-msg-box",
      class: [`ui-msg-box--color-${_vm.color}`, `ui-msg-box--size-${_vm.size}`],
    },
    [
      _vm.icon
        ? _c("UiIcon", {
            staticClass: "ui-msg-box__icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "ui-msg-box__title" },
        [
          _vm._t("default", function () {
            return [_vm._v(_vm._s(_vm.title))]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }