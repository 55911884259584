var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-ai-creator" },
    [
      _c("div", { staticClass: "page-ai-creator__left" }, [
        _c("div", { staticClass: "page-ai-creator__left-container" }, [
          _c("h1", { staticClass: "page-ai-creator__title" }, [
            _vm._v("✨ AI Creator"),
          ]),
          _c("div", { staticClass: "page-ai-creator__form-wrap" }, [
            _vm.activeStep !== 3
              ? _c(
                  "form",
                  {
                    staticClass: "ai-creator-form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitPrompt.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._m(0),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.prompt,
                          expression: "prompt",
                        },
                      ],
                      staticClass: "ai-creator-form__textarea",
                      attrs: {
                        placeholder: "Start here...",
                        disabled: _vm.isLoading,
                      },
                      domProps: { value: _vm.prompt },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.prompt = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "ai-creator-form__tags" },
                      _vm._l(_vm.examplePrompts, function (example, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "ai-creator-form__tag",
                            class: {
                              "is-disabled": _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.updatePrompt(example)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(example.title) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "ai-creator-form__btns" },
                      [
                        _c("UiBtn", {
                          attrs: {
                            label: _vm.isLoading
                              ? "Generating..."
                              : "GENERATE ✨",
                            type: "primary",
                            attrType: "submit",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _c("div", { staticClass: "ai-creator-form" }, [
                  _c("p", { staticClass: "ai-creator-form__hint" }, [
                    _vm._v(
                      "Watch the results and continue editing in SpreadSimple App"
                    ),
                  ]),
                  _c("p", { staticClass: "ai-creator-form__prompt" }, [
                    _c("b", [_vm._v("Prompt:")]),
                    _vm._v(" " + _vm._s(_vm.prompt)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ai-creator-form__btns" },
                    [
                      _c("UiBtn", {
                        attrs: {
                          label: "Continue to editor",
                          type: "green",
                          appendImg: "/img/icon-logo.svg",
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                        },
                        on: { click: _vm.checkGoogleDrivePermissions },
                      }),
                      _c("UiBtn", {
                        attrs: {
                          label: "Try another prompt",
                          type: "ghost",
                          attrType: "button",
                          prependIcon: "im-edit-containered",
                        },
                        on: { click: _vm.tryAnotherPrompt },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "page-ai-creator__right" }, [
        _vm.activeStep === 1
          ? _c("div", { staticClass: "page-ai-creator__hero" }, [
              _c("img", {
                staticClass: "page-ai-creator__hero-img",
                attrs: { src: "/img/ai-creator-main-3.png" },
              }),
            ])
          : _vm.activeStep === 2
          ? _c("div", { staticClass: "page-ai-creator__flow-wrap" }, [
              _c(
                "div",
                { staticClass: "ai-creator-flow" },
                [
                  _c(
                    "transition-group",
                    {
                      staticClass: "ai-creator-flow__msgs",
                      attrs: { tag: "div", name: "ai-creator-msgs" },
                    },
                    _vm._l(_vm.visibleReplicas, function (item, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "ai-creator-flow__msg" },
                        [
                          _c(
                            "div",
                            { staticClass: "ai-creator-flow__msg-icon" },
                            [_vm._v("✨")]
                          ),
                          _c(
                            "div",
                            { staticClass: "ai-creator-flow__msg-body" },
                            [
                              _c(
                                "h5",
                                { staticClass: "ai-creator-flow__msg-title" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text--color-primary" },
                                    [_vm._v(_vm._s(item.title) + " ")]
                                  ),
                                ]
                              ),
                              item.options.content
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "ai-creator-flow__msg-string",
                                    },
                                    [_vm._v(_vm._s(item.options.content))]
                                  )
                                : _vm._e(),
                              item.options.img
                                ? _c("div", {
                                    staticClass: "ai-creator-flow__msg-img",
                                    style: `background-image: url(${item.options.img});`,
                                  })
                                : _vm._e(),
                              item.options.logo
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ai-creator-flow__msg-img",
                                      staticStyle: { width: "60px" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: item.options.logo,
                                          alt: "logo",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              item.options.color
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ai-creator-flow__msg-color",
                                    },
                                    [
                                      _c("div", {
                                        staticClass:
                                          "ai-creator-flow__msg-color-preview",
                                        style: `background-color: ${item.options.color};`,
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "ai-creator-flow__msg-color-title",
                                        },
                                        [_vm._v(_vm._s(item.options.color))]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ])
          : _c("div", { staticClass: "page-ai-creator__result-wrap" }, [
              _c("div", { staticClass: "ai-creator-result" }, [
                _c(
                  "div",
                  { staticClass: "ai-creator-result__radio-wrap" },
                  _vm._l(_vm.resultPreviewTypes, function (item, i) {
                    return _c(
                      "label",
                      {
                        key: i,
                        staticClass: "ai-creator-result__radio",
                        class: {
                          "is-active": _vm.resultPreviewType === item.value,
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.resultPreviewType,
                              expression: "resultPreviewType",
                            },
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: item.value,
                            checked: _vm._q(_vm.resultPreviewType, item.value),
                          },
                          on: {
                            change: function ($event) {
                              _vm.resultPreviewType = item.value
                            },
                          },
                        }),
                        _c(
                          "span",
                          { staticClass: "ai-creator-result__radio-label" },
                          [_vm._v(_vm._s(item.label))]
                        ),
                        _c("UiIcon", {
                          staticClass: "ai-creator-result__radio-icon",
                          attrs: { icon: item.icon },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _vm.resultPreviewType === "website"
                  ? _c(
                      "div",
                      {
                        staticClass: "ai-creator-result__website web-preview",
                        class:
                          _vm.previewType === "desktop"
                            ? "is-desktop"
                            : "is-mobile",
                      },
                      [
                        _c("div", { staticClass: "web-preview__topbar" }, [
                          _vm._m(1),
                          _vm._m(2),
                          _c(
                            "div",
                            {
                              staticClass: "web-preview__topbar-switcher-wrap",
                            },
                            _vm._l(_vm.previewTypes, function (item) {
                              return _c(
                                "label",
                                {
                                  key: item.value,
                                  staticClass: "web-preview__topbar-switcher",
                                  class: {
                                    "is-active": _vm.previewType === item.value,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.previewType,
                                        expression: "previewType",
                                      },
                                    ],
                                    attrs: { type: "radio" },
                                    domProps: {
                                      value: item.value,
                                      checked: _vm._q(
                                        _vm.previewType,
                                        item.value
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.previewType = item.value
                                      },
                                    },
                                  }),
                                  _c("i", { class: item.icon }),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "web-preview__body",
                            class:
                              _vm.previewType === "desktop" ? "is-zoomed" : "",
                          },
                          [
                            _vm.websitePreviewOptions
                              ? _c("AiCreatorPreview", {
                                  attrs: {
                                    previewInitOptions:
                                      _vm.websitePreviewOptions,
                                    generationId: _vm.generationId,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "ai-creator-result__sheet-wrap" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ai-creator-result__sheet-scroll-container",
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "ai-creator-result__sheet" },
                              [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _vm._m(3),
                                      _vm._l(
                                        _vm.tableContent.header,
                                        function (cell, i) {
                                          return _c("th", { key: i }, [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ai-creator-result__sheet-head-decor",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "abcdefghijklmnopqrstuvwxyz"
                                                        .slice(i, i + 1)
                                                        .toUpperCase()
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ai-creator-result__sheet-head-cell",
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(_vm._s(cell)),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.tableContent.rows,
                                    function (row, i) {
                                      return _c(
                                        "tr",
                                        { key: i },
                                        [
                                          _c("td", [_vm._v(_vm._s(i + 2))]),
                                          _vm._l(row, function (cell, j) {
                                            return _c("td", { key: j }, [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(cell) +
                                                  "\n                  "
                                              ),
                                            ])
                                          }),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
              ]),
            ]),
      ]),
      _c(
        "Modal",
        {
          attrs: { modalName: "Generation Error", hasCloseButton: true },
          model: {
            value: _vm.openErrorModal,
            callback: function ($$v) {
              _vm.openErrorModal = $$v
            },
            expression: "openErrorModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pb-32" },
            [
              _c("div", { staticClass: "text--align-center mt-16" }, [
                _vm._v("\n        " + _vm._s(_vm.generationError) + "\n      "),
              ]),
              _c(
                "UiBtn",
                {
                  staticClass: "ml-auto mr-auto mt-24",
                  attrs: { wide: "" },
                  on: {
                    click: function ($event) {
                      _vm.openErrorModal = false
                    },
                  },
                },
                [_vm._v("\n        Ok\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "Modal",
        {
          staticStyle: { "--modalPaddingTop": "0" },
          attrs: { hasCloseButton: false, "max-width": "700px", top: "auto" },
          on: {
            input: function ($event) {
              _vm.googleDrivePermissionsModal = false
            },
          },
          model: {
            value: _vm.googleDrivePermissionsModal,
            callback: function ($$v) {
              _vm.googleDrivePermissionsModal = $$v
            },
            expression: "googleDrivePermissionsModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "ui-flex ui-flex--items-start gap-20 p-around-28" },
            [
              _c("img", {
                staticClass: "ui-flex--none",
                attrs: { src: "/img/lock-2.svg", alt: "lock icon" },
              }),
              _c("div", [
                _c("h4", { staticClass: "heading--size-s text--weight-700" }, [
                  _vm._v(
                    "Allow SpreadSimple to create and read the created Google Sheets\n          "
                  ),
                ]),
                _c("p", { staticClass: "mt-12 text--weight-500" }, [
                  _vm._v(
                    "The app will not be able to access or read any other files stored on your\n            Google Drive. Your other data will remain safe."
                  ),
                ]),
              ]),
              _c(
                "div",
                [
                  _c("UiBtn", {
                    attrs: {
                      label: "Allow...",
                      disabled: _vm.isLoading,
                      loading: _vm.isLoading,
                      uppercase: "",
                      "full-width": "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.createGoogleSheet(true)
                      },
                    },
                  }),
                  _c("UiBtn", {
                    staticClass: "mt-12",
                    attrs: {
                      label: "Dismiss",
                      disabled: _vm.isLoading,
                      loading: _vm.isLoading,
                      uppercase: "",
                      "full-width": "",
                      type: "secondary-text-blue",
                    },
                    on: {
                      click: function ($event) {
                        _vm.googleDrivePermissionsModal = false
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "Modal",
        {
          staticClass: "confirmation-popup",
          attrs: {
            modalName: "You reached your website quantity limit",
            closeOnPressEscBtn: "",
            alignTitleLeft: "",
          },
          on: {
            input: function ($event) {
              _vm.modalOverLimitSpreadViews = false
            },
          },
          model: {
            value: _vm.modalOverLimitSpreadViews,
            callback: function ($$v) {
              _vm.modalOverLimitSpreadViews = $$v
            },
            expression: "modalOverLimitSpreadViews",
          },
        },
        [
          _c("div", { staticClass: "confirmation-popup__content" }, [
            _c("p", [
              _vm._v(
                "You can’t add more websites to your workspace.\n        "
              ),
              _c(
                "a",
                {
                  staticClass: "over-limit-modal__link",
                  attrs: {
                    href: "https://help.spreadsimple.com/en/article/website-quantity-limitation-iul9ct/",
                    target: "_blank",
                    rel: "nofollow",
                  },
                },
                [
                  _vm._v(
                    "\n            Learn more about the website quantity limit.\n        "
                  ),
                ]
              ),
            ]),
            _c("p", [
              _vm._v(
                "To create a new website, do one of the following steps: "
              ),
            ]),
            _c("ul", [
              _c("li", [_vm._v("delete one of your existing websites")]),
              _c("li", [
                _vm._v(
                  "update your subscription plan in the Billing Settings to increase the number of licenses"
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "confirmation-popup__btns" },
            [
              _c(
                "UiBtn",
                {
                  attrs: { type: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.modalOverLimitSpreadViews = false
                    },
                  },
                },
                [_vm._v("OK")]
              ),
              _c(
                "UiBtn",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "account-billing" })
                    },
                  },
                },
                [_vm._v("UPDATE SUBSCRIPTION")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("Preloader", {
        attrs: {
          loading: _vm.creatingWebsite,
          content: _vm.contentMsg,
          showProgress: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ai-creator-form__hint" }, [
      _vm._v(
        "Describe your site or select one of the prompts below and click on "
      ),
      _c("b", [_vm._v("Generate")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "web-preview__topbar-decor" }, [
      _c("div", { staticClass: "web-preview__topbar-decor-item" }),
      _c("div", { staticClass: "web-preview__topbar-decor-item" }),
      _c("div", { staticClass: "web-preview__topbar-decor-item" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "web-preview__topbar-field" }, [
      _c("span", { staticClass: "web-preview__topbar-field-prepend" }, [
        _vm._v("https://"),
      ]),
      _c("div", { staticClass: "web-preview__topbar-field-value is-hidden" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _c("div", [
        _c("div", { staticClass: "ai-creator-result__sheet-head-decor" }),
        _c(
          "div",
          { staticClass: "ai-creator-result__sheet-head-cell is-decor" },
          [_vm._v("1")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }