var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "side-form" },
    [
      _c(
        "div",
        { staticClass: "side-form__item side-form__draggable-wrap" },
        _vm._l(_vm.codes, function (field, index) {
          return _c("div", { key: index, staticClass: "draggable__item" }, [
            _c(
              "div",
              {
                staticClass: "draggable__item-title-wrap no-padding is-column",
                on: {
                  click: function ($event) {
                    return _vm.onEditStart(field)
                  },
                },
              },
              [
                _c("span", { staticClass: "draggable__item-title" }, [
                  _vm._v(_vm._s(field.code) + " "),
                ]),
                _c("span", { staticClass: "draggable__item-subtitle" }, [
                  _vm._v(
                    " used: " +
                      _vm._s(field.used) +
                      " of " +
                      _vm._s(field.limit) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "draggable__item-controls" },
              [
                _c(
                  "el-dropdown",
                  {
                    staticClass: "draggable__item-control",
                    attrs: { trigger: "click" },
                  },
                  [
                    _c("i", { staticClass: "icon-gear" }),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "draggable__dropdown-menu",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onEditStart(field)
                              },
                            },
                          },
                          [_vm._v("Edit")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onDelete(field)
                              },
                            },
                          },
                          [_vm._v("Delete")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "side-form__btns" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--transparent",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                _vm.builderModalOpened = true
              },
            },
          },
          [_vm._v("+ Add promo code")]
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            closeOnClickOutside: false,
            modalName: "New promo code",
            hasCloseButton: true,
          },
          model: {
            value: _vm.builderModalOpened,
            callback: function ($$v) {
              _vm.builderModalOpened = $$v
            },
            expression: "builderModalOpened",
          },
        },
        [
          _c("OptionItemBuilder", {
            attrs: {
              value: _vm.currentEditingField,
              schema: "promocodeSchema",
              schemaArray: _vm.promocodeSchemaUpdated,
            },
            on: {
              input: _vm.onAddField,
              "on-edit": _vm.onEditEnd,
              "cancel-submission": _vm.onCancel,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }