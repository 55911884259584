var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "errorPage" }, [
      _c(
        "div",
        {
          staticClass: "errorPage__container",
          staticStyle: { "max-width": "800px" },
        },
        [
          _c("h2", { staticClass: "errorPage__title sv-text-reset" }, [
            _vm._v("🛠 System Maintenance is in Progress"),
          ]),
          _c("p", { staticClass: "errorPage__paragraph sv-text-reset" }, [
            _vm._v(
              "\n      Please be advised that there is scheduled maintenance in progress!\n      During the maintenance, some of our users might experience some problems with their Dashboard. But this should not affect the functioning of your websites. They are working as usual.\n      We apologize for any inconvenience!\n      If you have any questions, please contact us via our support chat.\n    "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }