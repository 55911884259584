<template>
  <div class="login-page">
    <div class="login-page__container">
      <h1 class="login-page__title">
        Sign in
      </h1>
      <p class="login-page__terms">
        By continuing, Google will share your name, email address, language preference, and profile picture with SpreadSimple.
      </p>
      <div class="login-page__button-wrap">
        <div ref="googleSignInBtn"></div>
        <p v-if="loginError" class="login-page__terms" style="color: red;">
          Failed to sign in.
          Please, make sure that cookies are enabled in your browser settings.
        </p>
        <p v-if="loginError" class="login-page__terms" style="color: red;">
          <a href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/" target="_blank" rel="nofollow" class="login-page__terms-link">
            How to enable cookies?
          </a>
        </p>
      </div>
      <p class="login-page__terms">
        By signing up, you agree to our
        <a href="https://spreadsimple.com/policy/" class="login-page__terms-link" target="_blank">
          Privacy Policy
        </a>
        and
        <a href="https://spreadsimple.com/terms/" class="login-page__terms-link" target="_blank">
          Terms of Service
        </a>
      </p>
    </div>
    <Preloader :loading="isLoading" />
  </div>
</template>
<script>

import { mapActions } from 'vuex'
import { initGoogleSignInScript } from '@/modules/google-utils'
export default {
  name: 'LoginPage',
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | SpreadSimple'
  },
  data () {
    return {
      loginError: false,
      googleScriptInjected: false,
      isLoading: false
    }
  },
  methods: {
    ...mapActions('app', [
      'setUserDataWhenLoaded'
    ]),
    onSignInSuccess () {
      this.setUserDataWhenLoaded()
      // temporary do not show initial quiz
      // if (localStorage.getItem('ssShowInitialQuiz') === 'true') {
      // this.$router.push({
      //   name: 'quiz',
      //   query: this.$route.query.redirect !== undefined ? { redirect: `${this.$route.query.redirect}` } : null
      // })
      // instead do
      //   this.$router.push({ name: 'spread-views' })
      // } else
      if (this.$route.query.redirect !== undefined) {
        this.$router.push({ path: `${this.$route.query.redirect}` })
      } else {
        this.$router.push({ name: 'spread-views' })
      }
    }
  },
  async mounted () {
    const idTokenCookie = document.cookie.split('; ').find(row => row.startsWith('idToken='))
    if (idTokenCookie) {
      const idToken = idTokenCookie.split('=')[1]
      this.isLoading = true
      this.$auth.login({ idToken })
        .then(() => {
          this.$router.push({ name: 'spread-views' })
        })
        .catch(_ => {
          this.loginError = true
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      const google = await initGoogleSignInScript()
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          this.isLoading = true
          this.$trackEvent({
            'event': 'googleLoginAttempt'
          })

          this.$auth.login({ idToken: response.credential })
            .then(() => {
              this.onSignInSuccess()
            })
            .catch(_ => {
              this.loginError = true
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
      google.accounts.id.renderButton(
        this.$refs.googleSignInBtn,
        { theme: 'filled_blue', size: 'large', text: 'continue_with' }
      )
    }
  }
}
</script>
