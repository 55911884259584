import { render, staticRenderFns } from "./SocialNetworksForm.vue?vue&type=template&id=389c4bf9"
import script from "./SocialNetworksForm.vue?vue&type=script&lang=js"
export * from "./SocialNetworksForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('389c4bf9')) {
      api.createRecord('389c4bf9', component.options)
    } else {
      api.reload('389c4bf9', component.options)
    }
    module.hot.accept("./SocialNetworksForm.vue?vue&type=template&id=389c4bf9", function () {
      api.rerender('389c4bf9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/social-networks-form/SocialNetworksForm.vue"
export default component.exports