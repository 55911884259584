var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "errorPage" }, [
    _c(
      "div",
      { staticClass: "errorPage__container" },
      [
        _vm.options.link
          ? _c(
              "a",
              {
                staticClass: "errorPage__logo sv-link-reset",
                attrs: { href: _vm.options.link },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "viewer__footer-logo-img",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "40",
                      height: "30",
                      viewBox: "0 0 40 30",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        fill: "#A2A9B4",
                        "fill-rule": "nonzero",
                        d: "M19.748.42l20.143 10.55-8.837 4.175 1.895 6.584-13.216 7.868L.136 18.763l8.524-4.299L5.582 6.19l-.015-.013-.006-.004-.016-.014-.02-.024a.263.263 0 0 1-.052-.113l-.003-.015-.001-.015a.255.255 0 0 1 .001-.06l.005-.024-.084-.232L19.748.42zM8.865 14.965l-7.573 3.82 18.431 10.19 12.235-7.286-23.035-6.7-.058-.024zm-.329-7.681L32.182 21.03l-1.634-5.675L8.536 7.284zM6.32 6.62l2.942 7.906 21.33 6.203L6.32 6.62zM19.71 1.009L6.339 5.903l24.215 8.88 8.126-3.839L19.71 1.01z",
                      },
                    }),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "errorPage__number" }, [
          _vm._v(_vm._s(_vm.options.error)),
        ]),
        _c("h2", { staticClass: "errorPage__title sv-text-reset" }, [
          _vm._v(_vm._s(_vm.options.title)),
        ]),
        _c("p", { staticClass: "errorPage__paragraph sv-text-reset" }, [
          _vm._v(_vm._s(_vm.options.content)),
        ]),
        _vm._t("default"),
        _vm.options.link && _vm.options.linkText
          ? _c(
              "a",
              {
                staticClass: "errorPage__home-link sv-link-reset",
                attrs: { href: _vm.options.link },
              },
              [_vm._v(_vm._s(_vm.options.linkText))]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }