var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "unsubscribe-modal" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "unsubscribe-modal__form" },
      _vm._l(_vm.reasonsList, function (reason) {
        return _c(
          "div",
          { key: reason.key, staticClass: "unsubscribe-modal__form-item" },
          [
            _c(
              "label",
              { staticClass: "unsubscribe-modal__checkbox checkbox" },
              [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { value: reason.checked },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("reason-checked", {
                        reason: reason.key,
                        value: $event.target.checked,
                      })
                    },
                  },
                }),
                _c("div", { staticClass: "checkbox__indicator" }),
                _c("span", { staticClass: "checkbox__title" }, [
                  _vm._v(" " + _vm._s(reason.label) + " "),
                ]),
              ]
            ),
            reason.details && reason.checked
              ? _c("textarea", {
                  staticClass: "unsubscribe-modal__form-item-textarera",
                  attrs: {
                    tabindex: "1",
                    placeholder: reason.details.description,
                  },
                  domProps: { value: reason.details.value },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("reason-detailed", {
                        description: $event.target.value,
                        reason: reason.key,
                      })
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "unsubscribe-modal__btns" },
      [
        _c(
          "UiBtn",
          {
            attrs: { "full-width": "", type: "secondary" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "UiBtn",
          {
            attrs: {
              "full-width": "",
              disabled: _vm.pending || !_vm.isNextBtnActive,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("submit-quiz")
              },
            },
          },
          [_vm._v("Next")]
        ),
      ],
      1
    ),
    _vm.quizError
      ? _c("p", { staticClass: "unsubscribe-modal__error-msg" }, [
          _vm._v(_vm._s(_vm.quizError)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "unsubscribe-modal__content-wrap" }, [
      _c("p", [
        _vm._v(
          "We’d really like to know what went wrong. Please share your honest feedback with us so we can make the right decisions and improve our product and service."
        ),
      ]),
      _c("p", [_vm._v("Please select one or several reasons below:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }