import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=15c3c160"
import script from "./LayoutHeader.vue?vue&type=script&lang=js"
export * from "./LayoutHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/devstark/spreadsimple/spreadsimple-app/main-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15c3c160')) {
      api.createRecord('15c3c160', component.options)
    } else {
      api.reload('15c3c160', component.options)
    }
    module.hot.accept("./LayoutHeader.vue?vue&type=template&id=15c3c160", function () {
      api.rerender('15c3c160', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/_layouts/layout-footerless/header/LayoutHeader.vue"
export default component.exports