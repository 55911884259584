import axios from 'axios'

const aiClient = axios.create({
  baseURL: process.env.VUE_APP_AI_CREATOR_API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true
  }
})

aiClient.interceptors.request.use(config => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    config.headers['x-user-google-id-token'] = accessToken
  }
  return config
}, error => {
  return Promise.reject(error)
})

const request = async (method, url, data) => {
  return aiClient[method](url, data)
    .then(response => {
      return response.data
    })
}

const aiApi = {
  generationStatus(id) {
    return request('get', `api/generation/status/${id}`)
  },
  generateWebsite(data) {
    return request('post', '/api/generation', data)
  },
  generateSheet(id) {
    return request('post', `/api/generation/sheet/${id}`)
  }
}

export default aiApi
