<template>
  <form
    class="page-form side-form"
    @submit.prevent="submitForm"
  >

    <div class="side-form__section">
      <form @submit.prevent="generate">
        <FormField label="Prompt" class="side-form__item aside-input">
          <v-textarea
            v-model.trim="prompt"
            spellcheck="false"
            @keydown.meta.13="generate"
          />
        </FormField>
        <div class="side-form__btns side-form__item aside-input">
          <button class="btn btn--transparent" type="submit" :disabled="isGenerating || prompt.trim() === ''">{{ isGenerating ? '✨ generating...' : 'Generate' }}</button>
        </div>
      </form>

    </div>

    <CollapsableBlock :expanded="false" class="side-form__section">
      <template slot="header">
        <h6 class="side-form__section-title">Options</h6>
      </template>
      <textarea
        :value="JSON.stringify(value, null, 2)"
        rows="50"
        data-gramm="false"
        style="font-size: 10px;width: 100%;overflow-x: scroll;line-height: 1;color: lightslategray;"
        @blur="updateOptions($event.target.value)">
      </textarea>
    </CollapsableBlock>
    <UiBtn
      size="big"
      attr-type="submit"
      :loading="loading"
      :disabled="loading"
      class="aside-save"
    >
      SAVE
    </UiBtn>
  </form>
</template>
<script>
import FormField from '@/components/form-field/'
import UiBtn from '@/components/ui-btn'
import CollapsableBlock from '@/components/collapsable-block'
import axios from 'axios'

export default {
  name: 'SpreadViewerAiBenchOptions',
  components: {
    CollapsableBlock,
    FormField,
    UiBtn
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      prompt: '',
      isGenerating: false,
      options: null
    }
  },
  methods: {
    generate () {
      this.isGenerating = true
      localStorage.setItem('lastPrompt', this.prompt)
      axios.post(`http://0.0.0.0:8000/generate`, {
        prompt: this.prompt,
        options: this.options
      })
        .then(response => {
          this.options = response.data.options
          console.log('options', this.options)
          this.$emit('input', this.options)
          setTimeout(() => {
            this.$emit('save', this.options)
          }, 500)
          setTimeout(() => {
            this.$emit('google-url-change', response.data.spreadsheet_url)
          }, 1000)
        })
        .catch(error => {
          console.error('Error generating', error)
        })
        .finally(() => {
          this.isGenerating = false
        })
    },
    updateOptions (stringifiedOptions) {
      try {
        this.options = JSON.parse(stringifiedOptions)
        this.$emit('input', this.options)
      } catch (e) {
        console.error('Error parsing options', e)
      }
    },
    submitForm () {
      this.$emit('save', this.options)
    }
  },
  created () {
    this.prompt = localStorage.getItem('lastPrompt') || ''
    this.options = this.value
  }
}
</script>
