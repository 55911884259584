<template>
  <div
    class="n-form-field"
    :class="{'without-label': !label && !$slots.label }"
  >
    <div
      v-if="$slots.label || label || helperContent || helperVideoData"
      class="n-form-field__top"
    >
      <span
        v-if="$slots.label || label"
        class="n-form-field__label"
        :class="{
          'required-field': required,
        }"
      >
        <slot name="label">{{ label }}</slot>
      </span>
      <UiHelper
        class="n-form-field__helper"
        v-if="helperContent || helperVideoData"
        :popoverContent="helperContent"
        :videoData="helperVideoData"
        :triggerAction="helperTriggerAction"
      />
    </div>

    <div
      class="n-form-field__control"
      :class="{ 'error': messages.length }"
    >
      <slot/>
    </div>

    <p v-if="messages.length" class="n-form-field__error-message"> {{ messages.join(', ') }} </p>

    <p v-if="hint" class="n-form-field__hint" v-html="hint"></p>

  </div>
</template>
<script>
import UiHelper from '@/components/ui-helper'
export default {
  name: 'FormField',
  components: {
    UiHelper
  },
  props: {
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    },
    helperContent: {
      type: String,
      default: null
    },
    helperVideoData: {
      type: Object,
      default: null
    },
    helperTriggerAction: {
      type: String,
      default: 'hover',
      validator (action) {
        return ['click', 'hover'].includes(action)
      }
    }
  }
}
</script>
