var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "fields-builder",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "fields-builder__item is-flex-row is-multiline" },
        _vm._l(_vm.currentSchema, function (field) {
          return _c(
            "FormField",
            {
              key: field.name,
              staticClass: "fields-builder__item-control",
              class: [_vm.setFieldWidthClass(field.width)],
              attrs: {
                label: field.label,
                messages:
                  _vm.$v.optionData[field.name].$dirty &&
                  _vm.$v.optionData[field.name].$invalid
                    ? ["Field is invalid"]
                    : [],
              },
            },
            [
              ["text", "number"].includes(field.type)
                ? _c("v-text-field", {
                    attrs: {
                      type: field.type,
                      step: 0.01,
                      placeholder: field.placeholder ? field.placeholder : "",
                      suffix:
                        field.name === "value" &&
                        _vm.optionData.type &&
                        (_vm.optionData.type === "multiplication" ||
                          _vm.optionData.type === "percentage")
                          ? "%"
                          : "",
                    },
                    model: {
                      value: _vm.$v.optionData[field.name].$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.optionData[field.name],
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.optionData[field.name].$model",
                    },
                  })
                : _vm._e(),
              ["select"].includes(field.type)
                ? _c("v-select", {
                    attrs: {
                      items: field.options,
                      "item-text": "label",
                      "item-value": "value",
                      "menu-props": "offsetY",
                      "append-icon": "keyboard_arrow_down",
                    },
                    model: {
                      value: _vm.$v.optionData[field.name].$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.optionData[field.name], "$model", $$v)
                      },
                      expression: "$v.optionData[field.name].$model",
                    },
                  })
                : _vm._e(),
              ["date"].includes(field.type)
                ? _c(
                    "div",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          "default-time": "12:00:00",
                          pickerOptions: {
                            disabledDate(time) {
                              return _vm.checkIfDateIsPast(time)
                            },
                          },
                          placeholder: "Select date and time",
                        },
                        model: {
                          value: _vm.$v.optionData[field.name].$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.optionData[field.name],
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.optionData[field.name].$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
      _c("div", { staticClass: "fields-builder__footer" }, [
        _c("div", { staticClass: "fields-builder__footer-btns" }, [
          _c(
            "button",
            {
              staticClass: "fields-builder__footer-btn btn btn--text-only",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel-submission")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "fields-builder__footer-btn btn",
              class: { "is-loading": _vm.loading },
              attrs: { type: "submit" },
            },
            [_vm._v(_vm._s(_vm.value ? "SAVE" : "ADD"))]
          ),
        ]),
        _vm.validationStatus.length
          ? _c("p", { staticClass: "fields-builder__footer-msg" }, [
              _c("i", {
                staticClass:
                  "icon-circle-close fields-builder__footer-msg-icon",
              }),
              _vm._v("\n      " + _vm._s(_vm.validationStatus) + "\n    "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }